import { Datagrid, DateField, TextField, useRecordContext } from "react-admin";
import { formatMoney, normalizedDate, normalizedMonth } from "../utils/helper";

const FormatMoney = (props) => {
    const record = useRecordContext(props);
    const { withCurrency } = props;
    return <>{formatMoney(record[props.source], false)}</>
};

const FormatMonth = (props) => {
    const record = useRecordContext(props);
    return <>{normalizedMonth(record.month)}</>
};

const FormatDate = (props) => {
    const record = useRecordContext(props);
    return <>{normalizedDate(record[props.source])}</>
};

const ReportTransactionListPerName = (isMatured) => (
    <Datagrid>
        <TextField source="id" label="No" />
        <TextField source="customer_name" label="Nama Nasabah" />
        <TextField source="kode_nasabah" textAlign='left' label="Kode Nasabah" />
        <TextField source="sre" textAlign='left' label="SRE Number" />
        <TextField source="sid" textAlign='left' label="SID Number" />
        <FormatMoney source="jumlah" textAlign='left' label="Lembar Saham" />
        <FormatMoney source="nominal_beli" label="Nominal beli" withCurrency />
        
        {isMatured == 1 ?
            <FormatMoney source="nilai_bersih_redemption" label="Nilai Bersih Redemption" withCurrency />
            : null
        }
        {isMatured == 1 ?
            <FormatMoney source="nilai_bersih_rollover_principal" label="Nilai Bersih Rollover Principal" withCurrency />
            : null
        }
    </Datagrid>
);
const ReportTransactionListPerMonth = (isMatured) => (
    <Datagrid>
        <TextField source="id" textAlign='left' label="No" />
        <FormatMonth source="month" textAlign='left' label="Bulan" />
        <TextField source="year" label="Tahun" />
        <FormatMoney source="jumlah" textAlign='left' label="Lembar Saham" />
        <FormatMoney source="nominal_pembelian" textAlign='left' withCurrency label="Nominal Pembelian" />
        <FormatMoney source="nilai_investasi_bersih" textAlign='left' withCurrency />
        <FormatMoney source="nilai_investasi" textAlign='left' withCurrency />
        <FormatMoney source="nilai_bersih_redemption" textAlign='left' withCurrency />
        <FormatMoney source="nilai_bersih_rollover_principal" textAlign='left' withCurrency />
    </Datagrid>
);
const ReportTransactionListPerDate = (isMatured) => (
    <Datagrid>
        <TextField source="id" textAlign='left' label="No" />
        {isMatured == 0 ?
            <DateField options={{ year: 'numeric', month: 'short', day: 'numeric' }} source="tanggal_efektif" textAlign='left' label="Tanggal Efektif" />
            : (isMatured == 1) ?
                <DateField options={{ year: 'numeric', month: 'short', day: 'numeric' }} source="tanggal_efektif" textAlign='left' label="Tanggal Efektif" />
                : null}

        <DateField options={{ year: 'numeric', month: 'short', day: 'numeric' }} source="tanggal_jatuh_tempo" textAlign='left' label="Maturity Date" />
        <FormatMoney source="jumlah" textAlign='left' label="Lembar Saham" />
        <FormatMoney withCurrency source="nominal_pembelian" textAlign='left' label="Nominal Pembelian" />
        <FormatMoney source="nilai_investasi_bersih" textAlign='left' withCurrency />
        <FormatMoney source="nilai_investasi" textAlign='left' withCurrency />
        <FormatMoney source="nilai_bersih_redemption" textAlign='left' withCurrency />
        <FormatMoney source="nilai_bersih_rollover_principal" textAlign='left' withCurrency />
    </Datagrid>
);
const ReportTransactionListPerTx = (isMatured) => (
    <Datagrid>
        <TextField source="id" textAlign='left' label="No" />
        <TextField source="kode_nasabah" textAlign='left' label="Kode Nasabah" />
        <TextField source="customer_name" textAlign='left' label="Nama Nasabah" />
        <TextField source="sre" textAlign='left' label="SRE Number" />
        <TextField source="sid" textAlign='left' label="SID Number" />
        <TextField source="contract_number" textAlign='left' label="Contract number" />
        <TextField source="tenor" textAlign='left' label="Tenor" />
        <DateField options={{ year: 'numeric', month: 'short', day: 'numeric' }} source="tanggal_efektif" textAlign='left' label="Tanggal Efektif" />
        <DateField options={{ year: 'numeric', month: 'short', day: 'numeric' }} source="tanggal_jatuh_tempo" textAlign='left' label="Tanggal Jatuh Tempo" />
        <FormatMoney withCurrency={false} source="nominal_beli" textAlign='left' label="Nominal Beli" />
        <FormatMoney source="jumlah" textAlign='left' label="Lembar Saham" />
        <FormatMoney withCurrency={true} source="nominal_pembelian" textAlign='left' label="Nominal Pembelian" />

        {/* {(isMatured == 1) ?
            <TextField source="opsi_maturity" textAlign='left' label="Opsi Maturity" />
            : null} */}
        <FormatMoney source="harga_buyback" textAlign='left' withCurrency />
        <FormatMoney source="nilai_investasi_bersih" textAlign='left' withCurrency />
        <TextField source="tipe_transaksi" textAlign='left' label="Tipe Transaksi" />
        <TextField source="opsi_maturity" textAlign='left' label="Opsi Maturity" />
        <FormatMoney source="nilai_bersih_redemption" textAlign='left' withCurrency />
        <FormatMoney source="nilai_bersih_rollover_principal" textAlign='left' withCurrency />
        <FormatMoney source="jumlah_saham_dijual" textAlign='left' withCurrency />
        <FormatMoney source="jumlah_lembar_saham_jaminan_yang_disesuaikan" textAlign='left' withCurrency />
        <FormatMoney source="harga_penjualan_kembali" textAlign='left' withCurrency />
        <FormatMoney source="hargaFOP" textAlign='left' withCurrency label="Harga FOP"/>
        <FormatMoney source="nilai_saham_jaminan" textAlign='left' withCurrency />
        <TextField source="tanggal_jatuh_tempo_selanjutnya" textAlign='left'  />
        <TextField source="pricing_rate_net" textAlign='left'  />
        <FormatMoney source="jumlah_saham_perpanjang" textAlign='left' withCurrency />
        <FormatMoney source="harga_penjualan_kembali_perpanjang" textAlign='left' withCurrency />
        <TextField  source="no_kontrak_sebelumnya" textAlign='left' />
    </Datagrid>
);

export {
    ReportTransactionListPerTx,
    ReportTransactionListPerDate,
    ReportTransactionListPerMonth,
    ReportTransactionListPerName
};