
import React, { useState, useEffect, useContext } from 'react';
import { Toolbar, SaveButton, SimpleForm, SelectInput, useTranslate, Show } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Box, Typography } from '@material-ui/core'
import md5 from 'md5'
import NumberFormat from 'react-number-format';

export const RolloverView = (props) => {

    const dataProvider = useContext(DataProviderContext);
    const [curId, setCurrentId] = useState();
    const [sales, setSales] = useState();
    const [name, setName] = useState();
    const [nama_saham, setNamaSaham] = useState('SMMA');
    const [order, setOrder] = useState(0);
    const [subRekening, setRekening] = useState('');
    const [bank, setBank] = useState('');
    const [rdn, setRDN] = useState('');
    const [harga, setHarga] = useState(0);
    const [bunga6, setBunga6] = useState(0);
    const [bunga12, setBunga12] = useState(0);
    const [nominalRepo, setRepo] = useState(0);
    const [holidays, setHoliday] = useState([]);
    const [threshold, setThreshold] = useState();
    const [tenor_lama, setTenor] = useState();
    const [no_surat_konfirmasi, setNoSuratKonfirmasi] = useState();
    const [tingkat_bunga, setTingkatBunga] = useState();
    const [harga_beli, setHargaBeli] = useState();
    const [jumlah, setJumlah] = useState();
    const [nominal_beli, setNominalBeli] = useState();
    const [total_beli, setTotalBeli] = useState();
    const [harga_penjualan_kembali, setHargaPenjualanKembali] = useState();
    const [harga_buyback, setHargaBuyback] = useState();
    const [nilai_investasi_bersih, setNilaiInvestasiBersih] = useState();
    const [pricing_rate_gross, setPricingRateGross] = useState();
    const [pricing_rate_net, setPricingRateNet] = useState();
    const [tanggal_jatuh_tempo, setTanggalJatuhTempo] = useState();
    const [kode_nasabah, setKodeNasabah] = useState();
    const [nominal_repo, setNominalRepo] = useState();
    const [tanggal_efektif, setTanggalEfektif] = useState();
    const [kode_saham, setKodeSaham] = useState('SMMA');
    const [kode_seller, setKodeSeller] = useState();
    const [net_ac, setNetAC] = useState();
    const [cabang, setCabang] = useState();
    const [jumlahSahamDiblokir, setJumlahsahamDiblokir] = useState();
    const [tipe_transaksinew, setTipeTransaksiNew] = useState();
    const [tenornew, setTenorNew] = useState();
    //const [threshold,setThreshold]= useState();
    const [max_rasio, setMaxRasio] = useState();
    const [min_rasio, setMinRasio] = useState();
    const [fetchfinish, setFetchFinish] = useState(0);
    // setNoSuratKonfirmasi(response.data.no_surat_konfirmasi)
    // setTingkatBunga(response.data.tingkat_bunga);
    // setHargaBeli(response.data.harga_beli)
    // setJumlah(response.data.jumlah)
    // setNominalBeli(response.data.nominal_beli)
    // setTotalBeli(response.data.total_beli)
    // setHargaPenjualanKembali(response.data.harga_penjualan_kembali)
    // setHargaBuyback(response.data.harga_buyback)
    // setNilaiInvestasiBersih(response.data.nilai_investasi_bersih)
    // setPricingRateGross(response.data.pricing_rate_gross)
    // setPricingRateNet(response.data.pricing_rate_net)
    // setTanggalJatuhTempo(response.data.tanggal_jatuh_tempo)
    const [id, setId] = useState()

    const UserEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton disabled />
        </Toolbar>
    );
    const SectionTitle = ({ label }) => {
        const translate = useTranslate();
        return (
            <Typography variant="h5" gutterBottom>
                {translate(label)}
            </Typography>
        );
    };
    const SectionTitle2 = ({ label }) => {
        const translate = useTranslate();

        return (
            <Typography variant="h6" gutterBottom>
                {translate(label)}
            </Typography>
        );
    };

    const rollover = [
        { id: "Rollover Principal", value: 2 },
        { id: "Rollover Principal + Interest", value: 3 },

    ]

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    Date.prototype.addMonth = function (month) {
        var date = new Date(this.valueOf());
        date.setMonth(date.getMonth() + month);
        return date;
    }


    const style = { width: 255, color: '#848482', backgroundColor: '#F3F2F1', height: 45, fontSize: 14, paddingTop: 2.5, paddingLeft: 10, marginTop: 30, marginBottom: 20, verticalAlign: 'center' }
    const style2 = { width: 400, height: 8, display: 'flex', fontSize: 14, paddingTop: 2.5, paddingLeft: 10, marginTop: 30, verticalAlign: 'center' }
    const label = { fontSize: 12, color: '#A9A9A9' }
    const label2 = { fontSize: 13, color: '#000000', flexGrow: 8, flexWrap: 'wrap' }
    const labelv = { fontSize: 13, color: '#000000' }
    function formatDate(date) {
        try {
            var d = date,
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            //console.log([year, month, day].join('-'));
            return [year, month, day].join('-');
        } catch (e) {
            return ""
        }
    }

    function formatDate2(date) {
        try {
            var monat = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var d = date,
                month = monat[d.getMonth()],
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (day.length < 2)
                day = '0' + day;
            //console.log([year, month, day].join('-'));
            return [day, month, year].join('-');
        } catch (e) { return "" }
    }

    let daysBetween = (d0, d1) => {

        var startDate = d0;
        var endDate = d1;

        // Validate input
        if (endDate <= startDate) {
            return 0;
        }
        // Calculate days between dates
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        startDate.setHours(0, 0, 0, 1);  // Start just after midnight
        endDate.setHours(23, 59, 59, 999);  // End just before midnight
        var diff = endDate - startDate;  // Milliseconds between datetime objects    
        var days = Math.floor(diff / millisecondsPerDay);
        return days
    }

    let workingDaysBetweenDates = (d0, d1, holiday) => {
        /* Two working days and an sunday (not working day) */
        var hd = holiday;
        //  console.log("COUNTING");

        var startDate = parseDate(d0);
        var endDate = parseDate(d1);

        // Validate input
        if (endDate <= startDate) {
            return 0;
        }

        // Calculate days between dates
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        startDate.setHours(0, 0, 0, 1);  // Start just after midnight
        endDate.setHours(23, 59, 59, 999);  // End just before midnight
        var diff = endDate - startDate;  // Milliseconds between datetime objects    
        var days = Math.ceil(diff / millisecondsPerDay);

        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days -= weeks * 2;

        // Handle special cases
        var startDay = startDate.getDay();
        var endDay = endDate.getDay();

        // Remove weekend not previously removed.   
        if (startDay - endDay > 1) {
            days -= 2;
        }
        // Remove start day if span starts on Sunday but ends before Saturday
        if (startDay == 0 && endDay != 6) {
            days--;
        }
        // Remove end day if span ends on Saturday but starts after Sunday
        if (endDay == 6 && startDay != 0) {
            days--;
        }
        /* Here is the code */
        hd.forEach(day => {
            if ((day >= d0) && (day <= d1)) {
                /* If it is not saturday (6) or sunday (0), substract it */
                if ((parseDate(day).getDay() % 6) != 0) {
                    days--;
                }
            }
        });
        return days;
    }

    function parseDate(input) {
        // Transform date from text to date
        var parts = input.match(/(\d+)/g);
        // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }


    const countDocument = () => {
        var today = new Date();
        var todaymonth = today.getMonth() + 1;
        var todayyear = today.getFullYear();
        dataProvider.getOne('gmra-trans-number?month=' + todaymonth + '&year=' + todayyear, { id: 1 }).then(response => {
            setOrder(response.data[0].total)
        })
    }

    const DataNasabah = props => {
        const { values } = useFormState();
        countDocument();
        var net
        dataProvider.getList('gmra-client?cid=' + kode_nasabah, { limit: 1 }).then(({ data }) => {
            try {
                net = data[0].Cash + data[0].Sell - data[0].Buy;
                setName(data[0].ClientName);
                setBank(data[0].BankName);
                setRekening(data[0].KSEISubAccountNo);
                setRDN(data[0].SavingsID);
                setNetAC(net)
            }
            catch (e) {
                console.log(e)
            }
        });


        return (
            <div>
                <div style={style2}><div style={label2}>Nama Nasabah:</div><div style={labelv}>{name}</div></div>
                <div style={style2}><div style={label2}>Sub Rekening Nasabah:</div><div style={labelv}>{subRekening}</div></div>
                <div style={style2}><div style={label2}>Bank:</div><div style={labelv}>{bank}</div></div>
                <div style={style2}><div style={label2}>RDN:</div><div style={labelv}>{rdn}</div></div>
            </div>
        );
    };

    useEffect(() => {

        dataProvider.getOne('gmra-maturity', { id: id }).then(response => {

            setNoSuratKonfirmasi(response.data.no_surat_konfirmasi)
            setKodeNasabah(response.data.kode_nasabah)
            setTingkatBunga(response.data.tingkat_bunga);
            setHargaBeli(response.data.harga_beli)
            setJumlah(response.data.jumlah)
            //  console.log("NOMREPO",response.data.nominal_repo)
            //setRepo(response.data.nominal_repo)
            setNominalBeli(response.data.nominal_beli)
            setTotalBeli(response.data.total_beli)
            setHargaPenjualanKembali(response.data.harga_penjualan_kembali)
            setHargaBuyback(response.data.harga_buyback)
            setNilaiInvestasiBersih(response.data.nilai_investasi_bersih)
            setPricingRateGross(response.data.pricing_rate_gross)
            setPricingRateNet(response.data.pricing_rate_net)
            setTanggalJatuhTempo(response.data.tanggal_jatuh_tempo)
            setTenor(response.data.tenor)
            setNominalRepo(response.data.nominal_repo)
            setTanggalEfektif(response.data.tanggal_efektif)
            setKodeSaham(response.data.kode_saham)
            setHargaBeli(response.data.harga_beli);
            setKodeSeller(response.data.kode_buyer)
            setCabang(response.data.cabang)
            setSales(response.data.sales)

        }).then(() => {
            setFetchFinish(1)

        });
        dataProvider.getOne('gmra-maturity', { id: curId }).then(response => {

            setTipeTransaksiNew(response.data.tipe_transaksi)
            setTenorNew(response.data.tenor)
            setRepo(response.data.nominal_repo)
        });

        var from = new Date()
        var d = (from.getDate() < 10 ? '0' : '') + from.getDate();
        var m = ((from.getMonth() + 1) < 10 ? '0' : '') + (from.getMonth() + 1);
        var y = from.getFullYear() + 2;
        var yf = from.getFullYear() - 1;
        var fromtext = [yf, m, d].join('-');
        var todate = new Date(y, m, d);
        var to = [y, m, d].join('-');

        dataProvider.getList('holiday?from=' + fromtext + '&to=' + to + '', { limit: 1 })
            .then(({ data }) => {
                var date = [];
                var holiday = data[0].Date;
                for (var x = 0; x < holiday.length; x++) {
                    try {
                        date.push(holiday[x].Date.split("T")[0]);
                    }
                    catch (e) {

                    }
                }

                setHoliday(date);
            })
    }, [id])

    const ValuesSetter = props => {
        const { values } = useFormState();
        values.order_number = order + 1;
        values.kode_nasabah = kode_nasabah;
        values.kode_saham = kode_saham;
        values.sales = sales;
        values.cabang = cabang;
        values.penyelesaian_transaksi = "T+1";
        values.kode_buyer = kode_seller
        values.active = 1
        values.tipe_nasabah = 1
        values.free_of_payment = "-"
        return "";
    }

    const DataSaham = props => {
        const { values } = useFormState();
        setCurrentId(values.id)
        setId(values.rollover_id)
        // dataProvider.getList('gmra-saham?kode_saham=' + values.kode_saham, { limit: 1 }).then(({ data }) => {
        dataProvider.getList('gmra-saham?kode_saham=' + kode_saham, { limit: 1 }).then(({ data }) => {
            try {
                setNamaSaham(data[0].nama);
                values.nama_saham = data[0].nama
            }
            catch (e) {
                console.log(e)
            }
        });

        dataProvider.getList('gmra-threshold?tanggal_efektif[$lte]=' + values.tanggal_efektif + '&active=1', { limit: 1 }).then(({ data }) => {
            try {
                //console.log("DATA", data[0])
                setThreshold(data[0].threshold)
                setMaxRasio(data[0].max_rasio / 100)
                setMinRasio(data[0].min_rasio / 100);
                values.threshold = data[0].threshold

            }
            catch (e) {
                console.log(e)
            }
        });
        return (<div>

            <div style={style2}>
                <div style={label2}>Nama Saham:</div><div style={labelv}>{nama_saham}</div>
            </div>
        </div>);
    }
    const DataTransaksi = props => {
        const { values } = useFormState();
        return (
            <div>

                <div>
                    <NumberFormat
                        value={harga_beli}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Harga Beli</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={jumlah}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Jumlah</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={nominal_beli}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Nominal Beli</div><div style={labelv}>{value}</div></div>} />

                </div>
                <div>
                    <NumberFormat
                        value={total_beli}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Nominal Beli + Fee</div><div style={labelv}>{value}</div></div>} />

                </div>


            </div>
        );
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const NominalRepo = props => {
        const { values } = useFormState();

        return (<NumberFormat
            value={values.nominal_repo}
            className="foo"
            displayType={'text'}
            thousandSeparator={true}
            renderText={(value, props) => <div {...props}>Value: {value}</div>}
        />)


    }

    function checkHoliday(date) {//melakukan pengecekan tanggal apakah hari libur atau bukan.
        const day = `${date.getDate()}`.padStart(2, '0');
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const year = date.getFullYear();
        var datum = year + "-" + month + "-" + day;
        return holidays.includes(datum)
    }

    const NetAC = props => {
        const { values } = useFormState();
        return (
            <div>
                <NumberFormat
                    value={net_ac}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Net AC:</div><div style={labelv}>{value}</div></div>} />
            </div>
        )
    }

    const TingkatBunga = props => {
        const { values } = useFormState();
        return (
            <div>
                <NumberFormat
                    value={values.tingkat_bunga}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style}{...props}><div style={label}>Tingkat Bunga:</div><div>{(value * 100).toFixed(2)} %</div></div>} />

            </div>
        )
    }
    const DataTransaksi3 = props => {
        const { values } = useFormState();
        return (
            <div>
                <div>
                    <NumberFormat
                        value={harga_penjualan_kembali}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Harga Penjualan Kembali</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={harga_buyback}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Harga Buyback</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={nilai_investasi_bersih}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Nilai Investasi Bersih</div><div style={labelv}>{value}  </div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={(pricing_rate_gross * 100).toFixed(2)}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Price Rate (Gross)</div><div style={labelv}>{value}  %</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={(pricing_rate_net * 100).toFixed(2)}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Price Rate (Net)</div><div style={labelv}>{value} %</div></div>} />
                </div>
            </div>
        )
    }

    const DataTransaksiDT = props => {
        const { values } = useFormState();
        var imbal_hasil = nilai_investasi_bersih - nominal_beli;
        var jumlah_shm_dijual = values.tipe_transaksi == 2 ? Math.ceil((imbal_hasil / (1 - (0.2 / 100)) / harga_buyback)) : 0
        var hpk2 = jumlah_shm_dijual * harga_buyback;
        var fees = Math.round(hpk2 * 0.2 / 100);
        var nilaiinv = hpk2 - fees;
        var tanggal_settlement_jual = new Date(tanggal_jatuh_tempo);

        //----------------Settlement H-2 hari kerja------------------
        var tanggal_transasksi_jual = tanggal_settlement_jual.addDays(-1);

        while (checkHoliday(tanggal_transasksi_jual) || tanggal_transasksi_jual.getDay() == 6 || tanggal_transasksi_jual.getDay() == 0) {
            tanggal_transasksi_jual = tanggal_transasksi_jual.addDays(-1);
        }
        tanggal_transasksi_jual = tanggal_transasksi_jual.addDays(-1);
        while (checkHoliday(tanggal_transasksi_jual) || tanggal_transasksi_jual.getDay() == 6 || tanggal_transasksi_jual.getDay() == 0) {
            tanggal_transasksi_jual = tanggal_transasksi_jual.addDays(-1);
        }
        //-----------------------------------------------------------

        return (
            <div>
                <div>
                    <NumberFormat
                        value={jumlah_shm_dijual}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Jumlah Saham yang Dijual: </div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={hpk2}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Harga Penjualan Kembali</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={fees}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Fee Buyback</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={nilaiinv}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Nilai Investasi Bersih</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div style={style2}><div style={label2}>Tanggal Transaksi Jual</div><div style={labelv}>{values.tipe_transaksi == 2 ? formatDate2(tanggal_transasksi_jual) : "-"}</div></div>
                <div style={style2}><div style={label2}>Penyelesaian Transaksi Jual</div><div style={labelv}>T+2</div></div>
                <div style={style2}><div style={label2}>Tanggal Settlement Jual</div><div style={labelv}>{values.tipe_transaksi == 2 ? formatDate2(tanggal_settlement_jual) : "-"}</div></div>
                <div style={style2}><div style={label2}>Tipe Penyelesaian Transaksi</div><div style={labelv}>{values.tipe_transaksi == 2 ? "Delivery Versus Payment" : "-"}</div></div>
                <div style={style2}></div>
            </div >
        )
    }

    const PenyesuaianLembarSahamJaminan = props => {
        const { values } = useFormState();
        var imbal_hasil;
        var jumlah_shm_dijual;
        var lmbr_saham_jaminan;
        var hbb2;
        var nominal_saham_jaminan;
        var harga_pasar;
        var nilai_pasar_saham_jaminan;
        var rasio;
        var harga_pasar_after_margin;
        var rasio_marjin
        var sesuai = 0;
        var rasio_marjin_minimum
        var topup
        var penyesuaian_transaksi
        var penyesuaian_transaksi_int
        var jumlah_lembar_saham_jaminan_yang_disesuaikan
        var harga_fop
        var libur2
        var nilai_transaksi_fop
        var tanggal_transaksi_fop
        var yang_diblokir
        var tanggal_settlement_fop
        var libur
        var tanggal_efektif_blokir_saham_perpanjang
        var tanggal_jatuh_tempo_selanjutnya



        // console.log(values)
        values.rollover = 1;
        imbal_hasil = nilai_investasi_bersih - nominal_beli;
        jumlah_shm_dijual = values.tipe_transaksi == 2 ? Math.ceil((imbal_hasil / (1 - (0.2 / 100)) / harga_buyback)) : 0
        lmbr_saham_jaminan = jumlah - jumlah_shm_dijual;
        hbb2 = Math.ceil(harga_penjualan_kembali / jumlah) * jumlah;
        nominal_saham_jaminan = values.tipe_transaksi == 2 ? nominal_beli : (hbb2 * ((100 - 0.2) / 100)).toFixed(0);
        values.interest = values.tipe_transaksi == 2 ? 0 : 1;
        harga_pasar = values.harga_beli
        values.harga_beli = harga_pasar;
        nilai_pasar_saham_jaminan = lmbr_saham_jaminan * harga_pasar
        rasio = values.threshold / 100;
        harga_pasar_after_margin = Math.ceil(harga_pasar / rasio);
        rasio_marjin = values.tipe_transaksi == 2 ? (nilai_pasar_saham_jaminan / nominalRepo) : (nilai_pasar_saham_jaminan / nominal_saham_jaminan)
        //   var rasio_marjin = 1 + ((nilai_pasar_saham_jaminan - nominal_saham_jaminan) / nominal_saham_jaminan)
        console.log("" + nilai_pasar_saham_jaminan + "/" + nominalRepo + "=" + rasio_marjin)

        if ((rasio_marjin * 100) > values.max_rasio * 100) {
            sesuai = 2;
        }
        if ((rasio_marjin * 100) < values.min_rasio * 100) {
            sesuai = 1
        }
        values.penyesuaian = sesuai
        rasio_marjin_minimum = min_rasio;
        topup = 0;
        if (rasio_marjin < rasio_marjin_minimum) {
            topup = 1
        }
        console.log(rasio_marjin)
        penyesuaian_transaksi = rasio_marjin < values.min_rasio ? "Top Up Collateral Shares" : rasio_marjin > values.max_rasio ? "Deduct Collateral Shares" : "No"
        penyesuaian_transaksi_int = rasio_marjin < values.min_rasio ? 1 : rasio_marjin > values.max_rasio ? 2 : 0
        jumlah_lembar_saham_jaminan_yang_disesuaikan = penyesuaian_transaksi_int == 1 ? Math.floor(nominal_saham_jaminan / harga_pasar_after_margin) - lmbr_saham_jaminan : penyesuaian_transaksi_int == 2 ? lmbr_saham_jaminan - Math.floor(nominal_saham_jaminan / harga_pasar_after_margin) : 0
        harga_fop = jumlah_lembar_saham_jaminan_yang_disesuaikan == 0 ? 0 : harga_pasar
        nilai_transaksi_fop = jumlah_lembar_saham_jaminan_yang_disesuaikan * harga_fop;
        tanggal_transaksi_fop = new Date(tanggal_jatuh_tempo);


        tanggal_transaksi_fop = tanggal_transaksi_fop.addDays(-1);
        while (checkHoliday(tanggal_transaksi_fop) || tanggal_transaksi_fop.getDay() == 6 || tanggal_transaksi_fop.getDay() == 0) {
            tanggal_transaksi_fop = tanggal_transaksi_fop.addDays(-1);
        }
        tanggal_transaksi_fop = tanggal_transaksi_fop.addDays(-1);
        while (checkHoliday(tanggal_transaksi_fop) || tanggal_transaksi_fop.getDay() == 6 || tanggal_transaksi_fop.getDay() == 0) {
            tanggal_transaksi_fop = tanggal_transaksi_fop.addDays(-1);
        }
        tanggal_transaksi_fop = tanggal_transaksi_fop.addDays(-1);
        while (checkHoliday(tanggal_transaksi_fop) || tanggal_transaksi_fop.getDay() == 6 || tanggal_transaksi_fop.getDay() == 0) {
            tanggal_transaksi_fop = tanggal_transaksi_fop.addDays(-1);
        }

        tanggal_settlement_fop = tanggal_transaksi_fop.addDays(1);

        libur = true;
        while (libur) {
            libur = false;
            if (tanggal_settlement_fop.getDay() == 6 || tanggal_settlement_fop.getDay() == 0) {
                libur = true;
            }
            libur2 = checkHoliday(tanggal_settlement_fop);
            if (libur == true || libur2 == true) {
                libur = true;
                tanggal_settlement_fop = tanggal_settlement_fop.addDays(1);
            }
        }

        tanggal_efektif_blokir_saham_perpanjang = new Date(tanggal_jatuh_tempo);
        tanggal_efektif_blokir_saham_perpanjang = tanggal_efektif_blokir_saham_perpanjang.addDays(1)
        libur = true;
        while (libur) {
            libur = false;
            if (tanggal_efektif_blokir_saham_perpanjang.getDay() == 6 || tanggal_efektif_blokir_saham_perpanjang.getDay() == 0) {
                libur = true;
            }
            var libur2 = checkHoliday(tanggal_efektif_blokir_saham_perpanjang);
            if (libur == true || libur2 == true) {
                libur = true;
                tanggal_efektif_blokir_saham_perpanjang = tanggal_efektif_blokir_saham_perpanjang.addDays(1);
            }
        }

        values.tanggal_efektif = tanggal_efektif_blokir_saham_perpanjang
        values.tanggal_settlement = tanggal_efektif_blokir_saham_perpanjang
        values.net_ac = 0
        values.tanggal_pembuatan = new Date();
        values.CreatedBy = localStorage.getItem('username');
        values.CreatedDate = new Date();

        tanggal_jatuh_tempo_selanjutnya = values.tenor == 6 ? tanggal_efektif_blokir_saham_perpanjang.addMonth(6).addDays(-1) : tanggal_efektif_blokir_saham_perpanjang.addDays(365).addDays(-1)

        var tanggal_jatuh_tempo_selanjutnya_month = tanggal_jatuh_tempo_selanjutnya.getMonth()
        var tanggal_jatuh_tempo_selanjutnya_date = tanggal_jatuh_tempo_selanjutnya.getDate()
        var tanggal_jatuh_tempo_selanjutnya_year = tanggal_jatuh_tempo_selanjutnya.getFullYear()
        if (tanggal_jatuh_tempo_selanjutnya_year % 4 == 0) {
            if (tanggal_jatuh_tempo_selanjutnya_month > 1) {
                tanggal_jatuh_tempo_selanjutnya = tanggal_jatuh_tempo_selanjutnya.addDays(1)
            }
        }
        libur = true;
        while (libur) {
            libur = false;
            if (tanggal_jatuh_tempo_selanjutnya.getDay() == 6 || tanggal_jatuh_tempo_selanjutnya.getDay() == 0) {
                libur = true;
            }
            var libur2 = checkHoliday(tanggal_jatuh_tempo_selanjutnya);
            if (libur == true || libur2 == true) {
                libur = true;
                tanggal_jatuh_tempo_selanjutnya = tanggal_jatuh_tempo_selanjutnya.addDays(1);
            }
        }
        yang_diblokir = sesuai == 1 ? jumlah_lembar_saham_jaminan_yang_disesuaikan + lmbr_saham_jaminan : lmbr_saham_jaminan - jumlah_lembar_saham_jaminan_yang_disesuaikan
        setJumlahsahamDiblokir(values.jumlah)

        return (
            <div>

                <div>
                    <NumberFormat
                        value={lmbr_saham_jaminan}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Lembar Saham Jaminan</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={nominal_saham_jaminan}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Nominal saham Jaminan</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={harga_pasar}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Harga Pasar</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={nilai_pasar_saham_jaminan}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Nilai Pasar Saham Jaminan</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={(rasio_marjin * 100).toFixed(2)}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}{...props}><div style={label2}>Rasio Margin</div><div style={labelv}>{value}%</div></div>} />
                </div>
                <div style={style2}><div style={label2}>Tipe Penyesuaian Saham Jaminan</div><div style={labelv}>{penyesuaian_transaksi}</div></div>
                <div>
                    <NumberFormat
                        value={Math.abs(jumlah_lembar_saham_jaminan_yang_disesuaikan)}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}><div style={label2}>Jumlah Lembar Saham Jaminan Yang Disesuaikan</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={harga_fop}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}><div style={label2}>Harga FOP</div><div style={labelv}>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={Math.abs(nilai_transaksi_fop)}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}><div style={label2}>Nilai Transaksi FOP : </div><div style={labelv}>{value}</div></div>} />
                </div>
                <div style={style2}><div style={label2}>Tanggal Transaksi FOP  </div><div style={labelv}>{formatDate2(tanggal_transaksi_fop)}</div></div>
                <div style={style2}><div style={label2}>Tanggal Settlement FOP  </div><div style={labelv}>{formatDate2(tanggal_settlement_fop)}</div></div>
                <div style={style2}><div style={label2}>Tipe Penyelesaian Transaksi </div><div style={labelv}>Free of Payment</div></div>
                <div style={style2}><div style={label2}>Kode Seller </div><div style={labelv}>{kode_seller}</div></div>
                <div style={style2}><div style={label2}>Nama Broker</div><div style={labelv}>PT.Sinarmas Sekuritas</div></div>
                <div style={style2}></div>
                <SectionTitle2 label="Perpanjangan Blokir Saham Jaminan"></SectionTitle2>
                <div>
                    <NumberFormat
                        value={sesuai == 1 ? jumlah_lembar_saham_jaminan_yang_disesuaikan + lmbr_saham_jaminan : lmbr_saham_jaminan - jumlah_lembar_saham_jaminan_yang_disesuaikan}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style2}><div style={label2}>Jumlah Saham yang diblokir perpanjang : </div><div style={labelv}>{value}</div></div>} />
                </div>
                <div style={style2}><div style={label2}>Tanggal Efektif Blokir Perpanjang </div><div style={labelv}>{formatDate2(tanggal_efektif_blokir_saham_perpanjang)}</div></div>
                <div style={style2}><div style={label2}>Tanggal Jatuh Tempo Selanjutnya  </div><div style={labelv}>{formatDate2(values.tanggal_jatuh_tempo)}</div></div>
            </div >
        )
    }
    const InitialData = props => {
        const { values } = useFormState();
        return (
            <div>
                <div style={style2}><div style={label2}>No Surat Konfirmasi</div><div style={labelv}>{no_surat_konfirmasi}</div></div>
                <div style={style2}><div style={label2}>Kode Nasabah</div><div style={labelv}>{kode_nasabah}</div></div>
                <DataNasabah></DataNasabah>
                <NetAC></NetAC>
                <NumberFormat
                    value={tenor_lama}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Tenor:</div><div style={labelv}>{value}</div></div>} />
                <NumberFormat
                    value={nominal_repo}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Nominal Repo:</div><div style={labelv}>{value}</div></div>} />
                <div style={style2}><div style={label2}>Tanggal Efektif</div><div style={labelv}>{formatDate2(new Date(tanggal_efektif))}</div></div>
                <div style={style2}><div style={label2}>Kode Saham</div><div style={labelv}>{kode_saham}</div></div>
                <DataSaham></DataSaham>
                <DataTransaksi></DataTransaksi>
                <div style={style2}><div style={label2}>Tanggal Jatuh Tempo</div><div style={labelv}>{formatDate2(new Date(tanggal_jatuh_tempo))}</div></div>
                <DataTransaksi3></DataTransaksi3>
                <div style={style2}></div>
            </div>

        )

    }
    const RolloverInformation = props => {
        const { values } = useFormState();
        var bungax;
        var harga_buyback_perlembar
        var hbb2 = Math.ceil(harga_penjualan_kembali / jumlah) * jumlah;
        var nilai_saham_jaminan = values.tipe_transaksi == 2 ? nominal_beli : (hbb2 * ((100 - 0.2) / 100)).toFixed(0);
        values.tenor = tenornew;
        values.tipe_transaksi = tipe_transaksinew;
        values.nominal_repo = nilai_saham_jaminan;
        values.nominal_beli = nilai_saham_jaminan;
        values.total_beli = nilai_saham_jaminan;
        var tanggal_efektif = values.tanggal_efektif;

        var jumlah_hari_bunga = daysBetween(new Date(tanggal_jatuh_tempo), new Date(values.tanggal_jatuh_tempo))
        values.jumlah_hari_bunga = jumlah_hari_bunga

        dataProvider.getList('gmra-harga?tanggal_efektif[$lte]=' + formatDate(tanggal_efektif) + '&active=1&kode_saham=' + kode_saham, {
            limit: 1
        }).then(({ data }) => {
            bungax = values.tenor == 12 ? data[data.length - 1].tingkat_bunga12 : data[data.length - 1].tingkat_bunga6
            setHarga(data[data.length - 1].harga_beli);
            setBunga6(data[data.length - 1].tingkat_bunga6);
            setBunga12(data[data.length - 1].tingkat_bunga12);

        }).catch(error => {
            console.log(error)
        })

        bungax = values.tenor == 12 ? bunga12 : bunga6
        values.tingkat_bunga = bungax;
        harga_buyback_perlembar = values.harga_buyback
        //values.jumlah = jumlahSahamDiblokir;
        values.fee_beli = 0

        var harga_penjualan_kembali_perpanjang = jumlahSahamDiblokir * harga_buyback_perlembar;
        values.harga_penjualan_kembali = harga_penjualan_kembali_perpanjang;

        var fee_buyback_sales_tax = Math.ceil(harga_penjualan_kembali_perpanjang * 0.002)
        values.fee_buyback = fee_buyback_sales_tax;

        var nilai_investasi_bersih_diterima = harga_penjualan_kembali_perpanjang - fee_buyback_sales_tax;
        nilai_investasi_bersih_diterima = values.nilai_investasi_bersih;

        var pricing_rate_grosss = ((harga_penjualan_kembali_perpanjang / nilai_saham_jaminan - 1) * (365 / jumlah_hari_bunga)).toFixed(4)
        values.pricing_rate_gross = pricing_rate_grosss;

        var pricing_rate_net = ((nilai_investasi_bersih_diterima / nilai_saham_jaminan - 1) * (365 / jumlah_hari_bunga)).toFixed(4)
        values.pricing_rate_net = pricing_rate_net;


        var no_surat_konfirmasi_baru = "";
        if (no_surat_konfirmasi != null) {
            var no_surat_konfirmasi_lama = no_surat_konfirmasi.split("/");

            var no_surat_konfirmasi_1 = no_surat_konfirmasi_lama[0].includes("-") ? no_surat_konfirmasi_lama[0] + "I" : no_surat_konfirmasi_lama[0] + "-I";
            no_surat_konfirmasi_baru = no_surat_konfirmasi_1 + "/" + no_surat_konfirmasi_lama[1] + "/" + no_surat_konfirmasi_lama[2] + "/" + no_surat_konfirmasi_lama[3];
            values.no_surat_konfirmasi = no_surat_konfirmasi_baru;
        }
        var hash = md5(new Date().toString() + no_surat_konfirmasi_baru);
        values.security_hash = hash;
        values.tanggal_jatuh_tempo = new Date(values.tanggal_jatuh_tempo);
        return (
            <div>
                <NumberFormat
                    value={nilai_saham_jaminan}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Nilai Saham Jaminan:</div><div style={labelv}>{value}</div></div>} />
                <NumberFormat
                    value={jumlahSahamDiblokir}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Jumlah Saham Perpanjang:</div><div style={labelv}>{value}</div></div>} />
                <div style={style2}><div style={label2}>Tanggal Efektif  </div><div style={labelv}>{formatDate2(new Date(tanggal_jatuh_tempo))}</div></div>
                <div style={style2}><div style={label2}>Tenor </div><div style={labelv}>{values.tenor}</div></div>
                <div style={style2}><div style={label2}>Tanggal Jatuh Tempo Selanjutnya  </div><div style={labelv}>{formatDate2(values.tanggal_jatuh_tempo)}</div></div>
                <div style={style2}><div style={label2}>Tingkat bunga - net(p.a) </div><div style={labelv}>{values.tenor == 12 ? (bunga12 * 100).toFixed(2) : (bunga6 * 100).toFixed(2)} %</div></div>
                <div style={style2}><div style={label2}>Jumlah hari bunga Net Dividend  </div><div style={labelv}>{jumlah_hari_bunga}</div></div>
                <NumberFormat
                    value={harga_buyback_perlembar}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Harga Buyback per Lembar</div><div style={labelv}>{value}</div></div>} />

                <NumberFormat
                    value={harga_penjualan_kembali_perpanjang}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Harga penjualan kembali - perpanjang</div><div style={labelv}>{value}</div></div>} />
                <NumberFormat
                    value={fee_buyback_sales_tax.toFixed(0)}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Fee Buyback + Sales Tax</div><div style={labelv}>{value}</div></div>} />

                <NumberFormat
                    value={nilai_investasi_bersih_diterima}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style2}><div style={label2}>Nilai Investasi Bersih Diterima Pembeli - Perpanjang </div><div style={labelv}>{value}</div></div>} />


                <div style={style2}><div style={label2}>Pricing Rate - Gross (p.a)</div><div style={labelv}>{(pricing_rate_grosss * 100).toFixed(2)}%</div></div>
                <div style={style2}><div style={label2}>Pricing Rate - Net (p.a)</div><div style={labelv}>{(pricing_rate_net * 100).toFixed(2)}%</div></div>
                <div style={style2}><div style={label2}>No Surat Konfirmasi </div><div style={labelv}>{no_surat_konfirmasi_baru}</div></div>

            </div>

        )

    }
    const TenorInfo = props => {
        const { values } = useFormState();
        return (<div style={style2}><div style={label2}>Tenor Rollover</div><div style={labelv}>{values.tenor} bulan</div></div>)
    }
    return (
        <Show {...props} actions={null} >
            {/* <SimpleForm validate={validateUserCreation}> */}
            <SimpleForm toolbar={<UserEditToolbar />}>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} sx={{ m: 4, p: 4 }}>
                    <Box flex={1} mr={{ xs: 0, sm: '2em' }}>
                        <SectionTitle label="Transaction Information"></SectionTitle>
                        <InitialData></InitialData>
                        <ValuesSetter></ValuesSetter>
                        <SelectInput source="tipe_transaksi" choices={rollover} optionText="id" optionValue="value" disabled />
                        <br />
                        <TenorInfo />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '2em' }}>
                        <SectionTitle label="Pencairan Bunga"></SectionTitle>
                        <DataTransaksiDT></DataTransaksiDT>
                        <SectionTitle2 label="Penyesuaian Lembar Saham Jaminan"></SectionTitle2>
                        <PenyesuaianLembarSahamJaminan></PenyesuaianLembarSahamJaminan>
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '2em' }}>
                        <SectionTitle label="Rollover Information"></SectionTitle>
                        <RolloverInformation></RolloverInformation>
                    </Box>
                </Box>

            </SimpleForm>
        </Show >
    )
};