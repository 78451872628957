import React, { useState, useEffect, useContext } from 'react';
import { DataProviderContext, Show, Toolbar, SaveButton, SimpleForm } from 'react-admin';
import NumberFormat from 'react-number-format';

export const DocumentShow = (props) => {
    const [name, setName] = useState();
    const [data, setData] = useState();
    const [document, setDocument] = useState();
    const [dataNasabah, setdataNasabah] = useState();
    const [subRekening, setRekening] = useState('');
    const [bank, setBank] = useState('');
    const [id, setID] = useState(props.id)
    const [rdn, setRDN] = useState('');
    const [values, setValues] = useState();
    const [buyer, setBuyer] = useState();
    const dataProvider = useContext(DataProviderContext);
    console.log(props);

    useEffect(() => {
        var trans_id = 0;
        dataProvider.getOne('gmra-document', { id: id }).then(response => {
            trans_id = response.data.trans_id
            console.log("TID", trans_id)
            dataProvider.getOne('gmra-trans', { id: response.data.trans_id }).then(response => {
                setValues(response.data);
                dataProvider.getList('gmra-seller?SellerID=' + response.data.kode_buyer, { limit: 1 }).then(({ data }) => {
                    setBuyer(data[0])

                });
                dataProvider.getList('gmra-client?cid=' + response.data.kode_nasabah, { limit: 1 }).then(({ data }) => {
                    setdataNasabah(data[0]);


                });
            });
            dataProvider.getList('gmra-document-list?trans_id=' + response.data.trans_id, { limit: 10 }).then(response => {
                console.log("RES", response);
                setDocument(response.data)
            });
        });




    }, [])


    const DataDokumen = props => {
        // const { values } = useFormState();

        if (typeof values === "undefined" || typeof dataNasabah === "undefined" || typeof buyer === "undefined") {
            return (<span></span>)
        }
        else {
            return (
                <div>
                    <div>
                        <span><h2>{values.no_surat_konfirmasi}</h2></span>
                    </div>
                    <table style={{ fontSize: 14 }} >
                        <tr>
                            <td width="50%" style={{ verticalAlign: 'top' }}>
                                <table>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Kode Nasabah
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {dataNasabah.ClientID}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nama Nasabah
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {dataNasabah.ClientName}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nama Bank
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {dataNasabah.BankName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Sub Rekening
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {dataNasabah.KSEISubAccountNo}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ height: 30 }}>
                                            RDN
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {dataNasabah.SavingsID}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}></td></tr>
                                    <tr>
                                        <td style={{ height: 30 }} width="45%">
                                            Nama Saham
                                        </td>
                                        <td width="10%">
                                            :
                                        </td >
                                        <td width="50%">
                                            PT Sinarmas Multi Artha Tbk
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Kode Saham
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.kode_saham}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Mata Uang
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            IDR
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nominal Repo
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.nominal_repo}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />

                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Harga Beli
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.harga_beli}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Jumlah
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.jumlah}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nominal Beli
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.nominal_beli}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Fee Beli
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.fee_beli} %
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nominal Beli + Fee
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.total_beli}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />

                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Tanggal Efektif
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.tanggal_efektif.split('T')[0]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Penyelesaian Transaksi
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            T+1
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Tanggal Settlement
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.tanggal_settlement.split('T')[0]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Tenor
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.tenor}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Tanggal Jatuh Tempo
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.tanggal_jatuh_tempo.split('T')[0]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Tingkat Bunga
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {(values.tingkat_bunga * 100).toFixed(2)} %
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Jumlah Hari Bunga
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.jumlah_hari_bunga}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td style={{ verticalAlign: 'top' }}>
                                <table>

                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Harga Buyback
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.harga_buyback}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }} width="45%">
                                            Harga Penjualan Kembali
                                        </td>
                                        <td width="10%">
                                            :
                                        </td >
                                        <td width="50%">
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.harga_penjualan_kembali}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Fee Buy Back
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                value={values.fee_buyback}
                                                displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={','}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nilai Investasi Bersih
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>  <NumberFormat
                                            fixedDecimalScale={true}
                                            value={values.nilai_investasi_bersih}
                                            displayType={'text'}
                                            thousandSeparator={'.'} decimalSeparator={','}
                                        />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Pricing Rate - Gross
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {(values.pricing_rate_gross * 100).toFixed(2)} %
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Pricing Rate - Net
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>

                                            {(values.pricing_rate_net * 100).toFixed(2)} %

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Kode Seller
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.kode_buyer}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nama Seller
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {buyer.SellerName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Nama Broker
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            Sinarmas Sekuritas
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 30 }}>
                                            Sales
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {values.sales}
                                        </td>
                                    </tr>



                                </table>
                                <hr></hr>
                                {/* <div>DOCUMENT LIST</div>
                                <hr></hr>
                                <table>
                                    <tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td></tr>
                                    {document.map((value) => {
                                        if (value.document.includes("SK")) {
                                            var approval;
                                            if (values.approval_full == 1) {
                                                approval = <td style={{ color: "green" }}>Approved</td>;
                                            } else {
                                                approval = <td style={{ color: "#FFBD00" }}>Menunggu Approval Direksi </td>;
                                            }
                                            return <tr><td style={{ paddingRight: 50 }}>Surat Konfirmasi II_Customer</td>{approval}</tr>
                                        } else if (value.document.includes("SIC_FOP_B")) {
                                            return <tr><td style={{ paddingRight: 50 }}>Surat Instruksi Beli FOP Customer</td><td>Generated</td></tr>
                                        } else if (value.document.includes("SIC_FOP_S")) {
                                            return <tr><td style={{ paddingRight: 50 }}>Surat Instruksi Jual FOP Customer</td><td>Generated</td></tr>
                                        }
                                        else if (value.document.includes("SIC_DVP_B")) {
                                            return <tr><td style={{ paddingRight: 50 }}>Surat Instruksi Beli DVP Customer</td><td>Generated</td></tr>
                                        }
                                        else if (value.document.includes("SIC_DVP_S")) {
                                            return <tr><td style={{ paddingRight: 50 }}>Surat Instruksi Jual DVP Customer</td><td>Generated</td></tr>
                                        }
                                        else if (value.document.includes("SUK")) {
                                            return <tr><td style={{ paddingRight: 50 }}>Surat Kuasa</td><td>Generated</td></tr>
                                        }
                                        else if (value.document.includes("SP")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Surat Pernyataan dan Kuasa</td><td>Generated</td></tr>)
                                        } else if (value.document.includes("DGMR")) {
                                            var approval;
                                      
                                            return (<tr><td style={{ paddingRight: 50 }}>GMRA</td><td>Generated</td></tr>)
                                        } else if (value.document.includes("SB")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Surat Instruksi Pencabutan Blokir Saham</td><td>Generated</td></tr>)
                                        } else if (value.document.includes("ST_FOP_B")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Buy Transaction FOP Company</td><td>Generated</td></tr>)
                                        } else if (value.document.includes("ST_FOP_S")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Sell Transaction FOP Company</td><td>Generated</td></tr>)
                                        }
                                        else if (value.document.includes("ST_DVP_B")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Buy Transaction DVP Company</td><td>Generated</td></tr>)
                                        }
                                        else if (value.document.includes("ST_DVP_S")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Sell Transaction DVP Company</td><td>Generated</td></tr>)
                                        }
                                        else if (value.document.includes("SAMB") || value.document.includes("SS")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Surat Konfirmasi II</td><td>Generated</td></tr>)
                                        } else if (value.document.includes("IBS")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Surat Blokir Saham</td><td>Generated</td></tr>)
                                        }
                                        else if (value.document.includes("SUK")) {
                                            return (<tr><td style={{ paddingRight: 50 }}>Surat Kuasa</td><td>Generated</td></tr>)
                                        }
                                    })}
                                </table> */}
                            </td>
                        </tr>
                    </table>

                </div >
            )
        }
        return (<span></span>)
    };

    const UserEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton disabled />
        </Toolbar>
    );
    return (

        <Show {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <DataDokumen></DataDokumen>
            </SimpleForm>
        </Show>
    )
};