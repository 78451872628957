import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';


const DateTypeField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);

    const myArr = record[source];
    if (myArr == "TE") {
        return (<div>Tanggal Efektif</div>)
    }
    else if (myArr == "TJT") {
        return (<div>Tanggal Jatuh Tempo</div>)
    }
    return (<div></div>)
}

DateTypeField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.number
};

export default DateTypeField;