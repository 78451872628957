import { downloadCSV } from "react-admin";
import jsonExport from 'jsonexport/dist';
import { normalizedMonth, status, dateToString } from "../utils/helper";

const DownloadExcel = async (data, state, display) => {
    let fields = [];
    switch (display) {
        case status.REPORT_DISPLAY.PER_TRANSACTION:
            if(state == 0) {
                fields = [
                    'No', 'Kode Nasabah', 'Nama Nasabah', 'SRE Number', 'SID Number', 'Contract Number', 'Tenor', 'Tanggal Efektif', 'Tanggal Jatuh Tempo', 'Nominal Beli',
                    'Lembar Saham', 'Nominal Pembelian', 'Harga Buyback', 'Nilai Investasi Bersih', 'Tipe Transaksi','Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal','Jumlah Saham Dijual','Harga Penjualan Kembali','Lembar Saham Jaminan','Jumlah Lembar Saham Yang Disesuaikan', 'Harga FOP','Jumlah Saham Perpanjang','Nominal Saham Jaminan Perpanjang','Tanggal Jatuh Tempo Selanjutnya','Pricing Rate Net','Harga Penjualan Kembali - Perpanjang','No Kontrak Sebelumnya'
                ]; 
            } else {
            fields = [
                'No', 'Kode Nasabah', 'Nama Nasabah', 'SRE Number', 'SID Number', 'Contract Number', 'Tenor', 'Tanggal Efektif', 'Tanggal Jatuh Tempo', 'Nominal Beli',
                'Lembar Saham', 'Nominal Pembelian', 'Opsi Maturity', 'Harga Buyback', 'Nilai Investasi Bersih', 'Tipe Transaksi','Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal','Jumlah Saham Dijual','Harga Penjualan Kembali','Lembar Saham Jaminan', 'Jumlah Lembar Saham Yang Disesuaikan','Harga FOP','Jumlah Saham Perpanjang','Nominal Saham Jaminan Perpanjang','Tanggal Jatuh Tempo Selanjutnya','Pricing Rate Net','Harga Penjualan Kembali - Perpanjang','No Kontrak Sebelumnya'
             ];
            }
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, ];
            // }
            break;
        case status.REPORT_DISPLAY.PER_DATE:
            fields = ['No', 'Tanggal Efektif', 'Maturity Date', 'Lembar Saham', 'Nominal Pembelian', 'Nilai Investasi Bersih', 'Nilai Investasi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'];
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, ];
            // }
            break;
        case status.REPORT_DISPLAY.PER_MONTH:
            fields = ['No', 'Bulan', 'Tahun', 'Lembar Saham', 'Nominal Pembelian', 'Nilai Investasi Bersih', 'Nilai Investasi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'];
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, ];
            // }
            break;
        case status.REPORT_DISPLAY.PER_NAME:
            fields = ['No', 'Nama Nasabah', 'Kode Nasabah', 'SRE Number', 'SID Number', 'Lembar Saham', 'Nominal Beli'];
            break;
        default:
            break;
    }

    // console.log('data: ', data);
    const dataForExport = data.map((item, i) => {
        let d = {};

        switch (display) {
            case status.REPORT_DISPLAY.PER_TRANSACTION:

                d['No'] = item.id;
                d['Kode Nasabah'] = item.kode_nasabah;
                d['Nama Nasabah'] = item.customer_name;
                d['SRE Number'] = item.sre || '-';
                d['SID Number'] = item.sid || '-';
                d['Contract Number'] = item.contract_number;
                d['Tenor'] = item.tenor;
                d['Tanggal Efektif'] = dateToString(item.tanggal_efektif);
                d['Tanggal Jatuh Tempo'] = dateToString(item.tanggal_jatuh_tempo);
                d['Nominal Beli'] = item.nominal_beli;
                d['Lembar Saham'] = item.jumlah;
                d['Nominal Pembelian'] = item.nominal_pembelian;
                if (state == 1) {
                    d['Opsi Maturity'] = item.opsi_maturity;
                }
                
                d['Harga Buyback'] = `${item.harga_buyback}`;
                d['Nilai Investasi Bersih'] = `${item.nilai_investasi_bersih}`;
                d['Tipe Transaksi'] = item.tipe_transaksi
                if (item.nilai_bersih_redemption == null) {
                    d['Nilai Bersih Redemption'] = `0`;
                }
                else {
                    d['Nilai Bersih Redemption'] = `${item.nilai_bersih_redemption}`;
                }
                if (item.nilai_bersih_rollover_principal == null) {
                    d['Nilai Bersih Rollover Principal'] = `0`;
                }
                else {
                    d['Nilai Bersih Rollover Principal'] = `${item.nilai_bersih_rollover_principal}`;
                }
                d['Jumlah Saham Dijual']=`${item.jumlah_saham_dijual}`
                d['Harga Penjualan Kembali']=`${item.harga_penjualan_kembali}`
                d['Jumlah Lembar Saham Yang Disesuaikan']=`${item.jumlah_lembar_saham_jaminan_yang_disesuaikan}`
                d['Lembar Saham Jaminan']=`${item.lembar_saham_jaminan}`
                d['Harga FOP']=`${item.hargaFOP}`
                d['Jumlah Saham Perpanjang']=`${item.jumlah_saham_perpanjang}`
                d['Nominal Saham Jaminan Perpanjang']=`${item.nilai_saham_jaminan}`
                d['Tanggal Jatuh Tempo Selanjutnya']=`${item.tanggal_jatuh_tempo_selanjutnya}`
                d['Pricing Rate Net']=`${item.pricing_rate_net}`
                d['Harga Penjualan Kembali - Perpanjang']=`${item.harga_penjualan_kembali_perpanjang}`
                d['No Kontrak Sebelumnya']=`${item.no_kontrak_sebelumnya}`

                // if (state === status.REPORT_STATUS.MATURED) {
                // }

                return d;
            case status.REPORT_DISPLAY.PER_DATE:
                d['No'] = item.id;
                d['Tanggal Efektif'] = dateToString(item.tanggal_efektif);
                d['Maturity Date'] = dateToString(item.tanggal_jatuh_tempo);
                d['Lembar Saham'] = item.jumlah;
                d['Nominal Pembelian'] = item.nominal_pembelian;

                d['Nilai Investasi Bersih'] = `${item.nilai_investasi_bersih}`;
                d['Nilai Investasi'] = `${item.nilai_investasi}`;
                d['Nilai Bersih Redemption'] = `${item.nilai_bersih_redemption}`;
                d['Nilai Bersih Rollover Principal'] = `${item.nilai_bersih_rollover_principal}`;
                // if (state === status.REPORT_STATUS.MATURED) {                    
                // }

                return d;
            case status.REPORT_DISPLAY.PER_MONTH:
                d['No'] = item.id;
                d['Bulan'] = normalizedMonth(item.month);
                d['Tahun'] = item.year;
                d['Lembar Saham'] = `${item.jumlah}`;
                d['Nominal Pembelian'] = `${item.nominal_pembelian}`;

                // if (state === status.REPORT_STATUS.MATURED) {
                // }
                d['Nilai Investasi Bersih'] = `${item.nilai_investasi_bersih}`;
                d['Nilai Investasi'] = `${item.nilai_investasi}`;
                d['Nilai Bersih Redemption'] = `${item.nilai_bersih_redemption}`;
                d['Nilai Bersih Rollover Principal'] = `${item.nilai_bersih_rollover_principal}`;

                return d;
            case status.REPORT_DISPLAY.PER_NAME:
                d['No'] = item.id;
                d['Nama Nasabah'] = item.customer_name;
                d['Kode Nasabah'] = item.kode_nasabah;
                d['SRE Number'] = item.sre || '-';
                d['SID Number'] = item.sid || '-';
                d['Lembar Saham'] = `${item.jumlah}`;
                d['Nominal Beli'] = `${item.nominal_beli}`;
                if (item.nilai_bersih_redemption != undefined) {           
                        d['Nilai Bersih Redemption'] = `${item.nilai_bersih_redemption}`
                }
                if (item.nilai_bersih_rollover_principal != undefined) {                   
                        d['Nilai Bersih Rollover Principal'] = `${item.nilai_bersih_rollover_principal}`
                }

                return d;
            default:
                break;
        }
    });

    await jsonExport(dataForExport, {
        headers: fields
    }, (err, csv) => {
        // console.log(csv)
        downloadCSV(csv, 'Recap'); // download as 'posts.csv` file
    });
};

export { DownloadExcel };