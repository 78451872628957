import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const BSField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    var BS='';
    const myArr = record[source];
   if(myArr==1){
    BS='B'
   }
   else
   {
    BS='S'
   }
    return (<div>{BS}</div>)
}

BSField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.number
};

export default BSField;