import * as React from "react";
import { Edit, SimpleForm, NumberInput, TextInput, FileField, DateInput, FileInput, BooleanInput } from 'react-admin';
import { useFormState } from 'react-final-form';


export const HargaEdit = (props) => {
    const DataTransaksi2 = props => {
        const { values } = useFormState();
        values.last_update= new Date();
        values.update_by=localStorage.getItem("username")
        console.log(values);
        var data = ''
        if (typeof values.internal_memo !== 'undefined') {
            Promise.resolve(convertFileToBase64(values.internal_memo.rawFile)).then((res) => {
                console.log(res);
                console.log(dataURLtoFile(res, 'a.pdf'));
                values.baseFile = res
            });

        }

        return null
    }
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    const convertFileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    return (
        <Edit undoable={false} {...props}>
            <SimpleForm>
                <TextInput source="kode_saham" defaultValue='SMMA' disabled />
                <TextInput source="nama_saham" disabled />
                <NumberInput source="harga_beli" />
                <DataTransaksi2 />

                <NumberInput source="tingkat_bunga6" />
                <NumberInput source="tingkat_bunga12" />

                <DateInput source="tanggal_efektif" />
                <FileInput source="internal_memo" label="Related files (max 500 Kb)" accept="application/pdf" maxSize={500000}>
                    <FileField source="src" title="title" />
                </FileInput>
                <BooleanInput source="active" />
            </SimpleForm>
        </Edit>)
};
