import { colors, makeStyles, Modal, Box, Typography, Card, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import { Button as Buttons } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import {
    useQuery,
    ListContextProvider,
    Pagination,
    Title,
    Button,
    SimpleForm,
    FileInput,
    FileField,
    Toolbar,
    SaveButton,
    useRefresh,
    useCreate,
    DataProviderContext,
    ValidationError,
    Datagrid, DateField, TextField, useListContext, useListController, TopToolbar
} from 'react-admin';
import keyBy from 'lodash/keyBy'

import { DownloadExcel } from './components/DownloadExcel';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MyDocument } from './components/DownloadPDF';
import { dateToISO, getStatus, normalizedDate, status } from './utils/helper';
import { ListNasabah, FormatTransaksi, SuratInstruksi, UploadList, MatchList } from './components/Cell';
import * as XLSX from "xlsx";
import SuratInstruksiField from '../field/SuratInstruksiField';
//import ImportButton from '.components/ImportButton';
const useStyle = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        position: 'absolute',
        width: 1000,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const useStyles = makeStyles(theme => ({
    filterSection: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20,
        // border: '1px solid red'
    },
    child: {
        paddingLeft: 10,
    },
    row: {
        display: 'table',
        content: '',
        clear: 'both',
        textAlign: 'left',
        width: '100%'
    },
    column: {
        float: 'left',
        // width: '20%',
        padding: '10px',
        flexDirection: 'row',
        height: 50
    },
    button: {
        marginTop: 10,
        marginLeft: 230
    },
    viewButton: {
        backgroundColor: colors.blue[400],
        color: 'white',
        paddingRight: 10
    },
    resetButton: {
        backgroundColor: colors.grey[400],
        color: 'white',
        paddingRight: 10,
        marginLeft: 10
    },
    text: {
        fontSize: 13,
        padding: 0,
        textAlign: 'left'
    },
    cellPad: { padding: 0 },
    exportButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
}));

export function MarginMonitoring(props) {
    const toDay = new Date().toISOString().substring(0, 10);

    const [payload, setPayload] = useState({
        monitoringDate: '',
        kode_saham: null,
        kode_nasabah: null,
        status: '0',
        display: '0',
        tradeIds: undefined
    });

    const [page, setPage] = useState(1);
    const [dataList, setDataList] = useState([]);
    const [pdfDataList, setPdfDataList] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const [pdfLoadData, setPdfLoadData] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [url, setUrl] = useState();
    const [openAlert, setOpenAlert] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [openMatch, setOpenMatch] = useState(false);
    const [openMt, setOpenMt] = useState(false);
    const [items, setItems] = useState([]);
    const [matching, setMatching] = useState([])
    const [contextArray, setContextArray] = useState([])
    const [monitoring_date, setMonitoringDate] = useState();
    const [kode_saham, setKodeSaham] = useState();
    const [kode_nasabah, setKodeNasabah] = useState();
    const refresh = useRefresh();
    const dataProvider = useContext(DataProviderContext);
    const classes = useStyles();
    const handleMontDate = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setMonitoringDate(value)

    }
    const handleKodeSaham = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setKodeSaham(value)

    }
    const handleKodeNasabah = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setKodeNasabah(value)

    }
    const handlePayload = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        if ((name == "display" && value == 3) || (name == "display" && value == 2)) {
            setPayload({
                ...payload,
                [name]: value,
                tradeIds: undefined,
                monitoringDate: ''
            });
        } else {
            setPayload({
                ...payload,
                [name]: value,
                tradeIds: undefined,
            });
        }

        setOpenMatch(false)
        //  console.log("PL", payload)
        setPage(1);
    };


    const getPdfTitle = () => {
        let title = '';
        const { monitoring_date: monitoringDate, effectiveEndDate, maturityStartDate, maturityEndDate } = payload;

        if (monitoringDate && effectiveEndDate) {
            title += `Tanggal efektif: ${normalizedDate(monitoringDate)} - ${normalizedDate(effectiveEndDate)}\n\n`;
        }

        if ((Number(payload.status) === 1) && (maturityStartDate && maturityEndDate)) {
            title += `Tanggal jatuh tempo: ${normalizedDate(maturityStartDate)} - ${normalizedDate(maturityEndDate)}\n\n`;
        }

        title += `Status: ${getStatus('status', payload.status)}\n\nDisplay: ${getStatus('display', payload.display)}`;

        return title;

    }

    const resetButton = () => {
        setPayload({
            monitoring_date: '',
            kode_saham: '',
            display: 0,
            kode_nasabah: '',
        })
    }

    const handleSubmit = () => {

        setPayload({
            ...payload,
            monitoringDate: monitoring_date,
            kode_saham: kode_saham,
            kode_nasabah: kode_nasabah,
            tradeIds: undefined,
        })
        setOpenMatch(false)
        //console.log("PL", payload)
        setPage(1);
    }

    const optQuery = (perPage) => {

        const filter = {
            status: payload.status,
            display: payload.display
        };

        if (payload.monitoringDate !== '') {
            filter.monitoringDate = payload.monitoringDate;
        }
        if (payload.kode_nasabah !== '') {
            filter.kode_nasabah = payload.kode_nasabah;
        }
        if (payload.kode_saham !== '') {
            filter.kode_saham = payload.kode_saham;
        }
        if (payload.tradeIds !== undefined) {
            filter.tradeId = payload.tradeIds
            filter.item = items
        }

        console.log("PAYLOAD", payload)
        return {
            type: 'GET_LIST',
            resource: 'gmra-margin-monitoring',
            payload: {
                pagination: {
                    page,
                    perPage
                },
                sort: { field: 'id', order: 'ASC' },
                filter
            }
        }
    }

    const { data, total, loading, error } = useQuery(optQuery(perPage));
    if (loading && loadData) {
        setLoadData(false);
    }
    if (!loading && !loadData) {
        // console.log('data: ', data);
        setLoadData(true);
        setDataList(data);
    }
    if (error && dataList?.length > 0) {
        setDataList([]);
    }

    const { data: pdfData, loading: pdfDataLoading, error: pdfDataError } = useQuery(optQuery(8000));

    if (pdfDataLoading && pdfLoadData) {
        setPdfLoadData(false);
    }

    if (!pdfDataLoading && !pdfLoadData) {

        //    console.log("PDFDATA", pdfData)
        setPdfDataList(pdfData);
        setPdfLoadData(true);
    }

    if (pdfDataError && pdfDataList?.length > 0) {
        setPdfDataList([]);
    }

    const _renderTable = () => {
        const s = Number(payload.status);
        const d = Number(payload.display);

        switch (d) {
            case 4:
                return MatchList()
            case 3:
                return UploadList(dataList)
            case 2:
                return FormatTransaksi()
            case 1:
                return SuratInstruksi()
            case 0:
                return ListNasabah()
            default:
                console.log('nothing...');
                break;
        }
    }


    const UserEditToolbar = props => (
        <div style={{ display: "none" }}>
            <Toolbar {...props} >
                <SaveButton disabled />
            </Toolbar>
        </div>

    );

    const validationExcell = (data) => {
        let balikan
        for (let i = 0; i < data.length; i++) {
            // lowercase key object, takut user masukan dengan huruf kapital 
            let newobj = {}
            var key, keys = Object.keys(data[i]);
            let n
            n = keys.length;
            while (n--) {
                key = keys[n];
                newobj[key.toLowerCase()] = data[i][key];
            }
            console.log(newobj)

            if (newobj.trade_id == undefined) {
                alert('Gagal upload Data Trade_ID belum ada')
                balikan = false
                break;
            }
            if (newobj.clientid == undefined) {
                alert('Gagal upload Data ClientID belum ada')
                balikan = false
                break;
            }
            if (newobj.market == undefined) {
                alert('Gagal upload Data Market belum ada')
                balikan = false
                break;
            }
            if (newobj['b/s'] == undefined) {
                alert('Gagal upload Data B/S belum ada')
                balikan = false
                break;
            }
            if (newobj.stock == undefined) {
                alert('Gagal upload Data stock belum ada')
                balikan = false
                break;
            }
            if (newobj.price == undefined) {
                alert('Gagal upload Data price belum ada')
                balikan = false
                break;
            }
            if (newobj.lot == undefined) {
                alert('Gagal upload Data Lot belum ada')
                balikan = false
                break;
            }
            if (newobj.volume == undefined) {
                alert('Gagal upload Data volume belum ada')
                balikan = false
                break;
            }
            if (newobj['gross amount'] == undefined) {
                alert('Gagal upload Data Gross Amount belum ada')
                balikan = false
                break;
            }
            if (newobj['no surat konfirmasi'] == undefined) {
                alert('Gagal upload Data No Surat Konfirmasi belum ada')
                balikan = false
                break;
            }
        }
        return balikan
    }


    const readExcelAndInsertTable = () => {
       
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(url?.rawFile);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { raw: false });
                // console.log(data);
                resolve(dataList);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            console.log(d);
            console.log("ahaaa 2")
            setItems(d);
            var tradeid = 0;
            var clientid = "";
            var market = "";
            var bs = "";
            var stock = "";
            var price = 0;
            var lot = 0;
            var volume = 0;
            var gross_amount = 0;
            var no_surat_konfirmasi = "";
            var monitoring_date = "";
            var counter = 0;
            var matching = ""
            var dataUnmatch = []
            const totalData = d.filter((data) => data.matching == "MATCH").length
            //const dataUnMatch = d.filter((data) => data.matching == "UNMATCH")

            if (validationExcell(d) == false) {
                return
            }
            // console.log(payload.monitoringDate)
            // if (payload.monitoringDate == undefined) {
            //     return alert("Harap isi monitoring date")
            // }
            // console.log(dataList)
            const dataUnMacthServe = d.filter((data) => data.matching == "UNMATCH").length
            if (dataUnMacthServe == dataList.length) {
                alert("Semua data UNMATCH")
                return
            }
            for (let data of dataList) {
                // lowercase key object, takut user masukan dengan huruf kapital 
                if (data.matching == "MATCH") {
                    let newobj = {}
                    var key, keys = Object.keys(data);
                    let n
                    n = keys.length;
                    while (n--) {
                        key = keys[n];
                        newobj[key.toLowerCase()] = data[key];
                    }

                    console.log(newobj);
                    tradeid = newobj.trade_id;
                    clientid = newobj.clientid;
                    market = newobj.market;
                    bs = newobj['b/s'];
                    stock = newobj.stock;
                    price = newobj.price;
                    lot = newobj.lot;
                    volume = newobj.volume;
                    gross_amount = newobj.price * newobj.lot * 100
                    no_surat_konfirmasi = newobj['no surat konfirmasi']
                    if (payload.monitoringDate == '' || payload.monitoringDate == undefined) {
                        monitoring_date = toDay
                        // alert('Mohon isi monitoring Date')
                        // return
                    } else {
                        monitoring_date = payload.monitoringDate
                    }
                    matching = newobj.matching
                    console.log(monitoring_date)
                    dataProvider.create('gmra-history', {
                        data: {
                            "tradeid": tradeid,
                            "clientid": clientid,
                            "market": market,
                            "bs": bs,
                            "stock": stock,
                            "price": price,
                            "lot": lot,
                            "volume": volume,
                            "gross_amount": gross_amount,
                            "created_at": new Date(),
                            "no_surat_konfirmasi": no_surat_konfirmasi,
                            "monitoring_date": monitoring_date
                        }
                    })

                        .then(response => {
                            //console.log(response);
                            counter++;
                            setUrl(undefined)
                            const dataUnMatch = d.filter((data) => data.matching == "UNMATCH")
                            console.log(counter)
                            console.log(totalData)
                            console.log(dataUnMatch)
                            let arrs = []
                            for (let data of dataUnMatch) {
                                arrs.push(data.trade_id)
                            }
                            console.log(arrs.join(''))


                            if (counter == 1) {
                                if (counter === totalData) {
                                    if (arrs.length == 0) {
                                        alert(`Import request ${totalData} data is successfull `);
                                    }
                                    else {
                                        //alert(`Import request ${totalData} data is successfull`);
                                        alert(`Import request ${totalData} data is successfull and Data UNMATCH Trade ID  is ${arrs.join(',')}`);
                                    }

                                }
                            } else {
                                if (counter === totalData) {

                                    if (arrs.length == 0) {
                                        alert(`Import request ${totalData} data is successfull `);
                                    }
                                    else {
                                        //alert(`Import request ${totalData} data is successfull`);
                                        alert(`Import request ${totalData} data is successfull and Data UNMATCH Trade ID  is ${arrs.join(',')}`);
                                    }

                                }
                            }
                        }).catch(err => {
                            console.log(err);
                            data -= 1;
                            alert(JSON.stringify(err));

                            counter++;
                            if (counter === d.length - 1) {
                                alert(`Import request ${totalData} data is successfull`);
                            }
                        })
                }

            }
            setOpenAlert(false);
        });

    };
    const previewExcel = () => {
     
        if (url == undefined) {
            alert("Pilih File terlebih dahulu, Harus file excell tidak boleh CSV")
            return
        }
    
        // if (payload.monitoringDate == "" || payload.monitoringDate == undefined) {
        //     alert("Harap isi Monitoring Date Terlebih dahulu")
        //     return
        // }
            if (monitoring_date== "" ||monitoring_date == undefined) {
            alert("Harap isi Monitoring Date Terlebih dahulu")
            return
        }
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(url?.rawFile);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { raw: false });
                // console.log(data);
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            if (validationExcell(d) == false) {
                return
            }
            console.log(d);
            let resultArray = []
            let tradeIdArray = []
            for (let data of d) {
                // lowercase key object, takut user masukan dengan huruf kapital 
                let newobj = {}
                var key, keys = Object.keys(data);
                let n
                n = keys.length;
                while (n--) {
                    key = keys[n];
                    newobj[key.toLowerCase()] = data[key];
                }
                newobj.monitoring_date = toDay
                //console.log(newobj);
                resultArray.push(newobj)
                tradeIdArray.push(newobj.trade_id)

            }
            setItems(resultArray);
            setPayload({
                ...payload,
                tradeIds: tradeIdArray
            });
            setOpenMatch(true)
        });

    }

    const saveHistory = () => {
        if (url == undefined) {
            alert("Pilih File terlebih dahulu, Harus file excell tidak boleh CSV")
            return
        }
        // if (payload.monitoringDate == undefined) {
        //     return alert("Harap isi monitoring date")
        // }
        setOpenAlert(true)
    }


    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }
    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`
        };
    }
    const classe = useStyle();
    const [modalStyle] = useState(getModalStyle);
    const handleClose = () => {
        setOpenPreview(false);
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    console.log(dataList)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        border: '2px solid black',
        boxShadow: 24,
        p: 4,
    };

    const styless = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    console.log(dataList)




    return (
        <>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openPreview}
                onClose={handleClose}

            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Preview
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <table style={{ textAlign: 'center', width: 850, marginTop: "20px", marginBottom: "20px", border: '1px solid black' }}>
                            <tr style={{ border: '1px solid black' }}>
                                <th>Trade ID</th>
                                <th>Client ID</th>
                                <th>Market</th>
                                <th>B/S</th>
                                <th>Stock</th>
                                <th>Price</th>
                                <th>Lot</th>
                                <th>Volume</th>
                                <th>Gross Amount</th>
                                <th>No Surat Konfirmasi</th>
                            </tr>
                            {items.map((data) => (
                                <tr style={{ border: '1px solid black' }}>
                                    <td>{data.trade_id}</td>
                                    <td>{data.clientid}</td>
                                    <td>{data.market}</td>
                                    <td>{data["b/s"]}</td>
                                    <td>{data.stock}</td>
                                    <td>{data.price}</td>
                                    <td>{data.lot}</td>
                                    <td>{data.volume}</td>
                                    <td>{data.price * data.lot * 100}</td>
                                    <td>{data["no surat konfirmasi"]}</td>
                                </tr>
                            ))}

                        </table>
                        <Buttons variant="contained" color="secondary" onClick={handleClose}>
                            Close
                        </Buttons>
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styless}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Konfirmasi
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Apa Anda Yakin Ingin Melanjutkan Proses ?
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                        <Buttons variant="contained" color="secondary" onClick={() => readExcelAndInsertTable()}>
                            Prosses
                        </Buttons>
                        <Buttons variant="contained" color="danger" onClick={() => handleCloseAlert()}>
                            Close
                        </Buttons>
                    </div>
                </Box>
            </Modal>
            <div className={classes.filterSection}>
                <div className={classes.row}>
                    <div className={classes.column} style={{ width: 'auto' }}>
                        <div style={{ width: 200 }}>
                            Monitoring Date
                        </div>
                    </div>
                    <div className={classes.column}>
                        {payload.display === status.MONITORING_DISPLAY.UPLOAD_LIST_MATCH ?
                            <input
                                type="date"
                                onChange={handleMontDate}
                                name="monitoringDate"
                                value={monitoring_date}
            
                            />
                            :
                            <input
                                type="date"
                                onChange={handleMontDate}
                                name="monitoringDate"
                                value={monitoring_date}
                            />
                        }


                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column} style={{ width: 'auto' }}>
                        <div style={{ width: 200 }}>
                            Kode Saham
                        </div>
                    </div>
                    <div className={classes.column}>
                        <input
                            type="text"
                            onChange={handleKodeSaham}
                            name="kode_saham"
                            value={kode_saham}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column} style={{ width: 'auto' }}>
                        <div style={{ width: 200 }}>
                            Display Category
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div>
                                <input
                                    id="display0"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={0}
                                    checked={payload.display === status.MONITORING_DISPLAY.LIST_NASABAH ? true : false}
                                />
                                <label className={classes.child} htmlFor="status0">List Nasabah</label>
                            </div>

                            {/* OJK */}
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="display1"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={1}
                                    checked={payload.display === status.MONITORING_DISPLAY.SURAT_INSTRUKSI ? true : false}
                                />
                                <label className={classes.child} htmlFor="status1">Surat Instruksi</label>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="display2"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={2}
                                    checked={payload.display === status.MONITORING_DISPLAY.FORMAT_TRANSAKSI ? true : false}
                                />
                                <label className={classes.child} htmlFor="status2">Format Transaksi</label>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="display3"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={3}
                                    checked={payload.display === status.MONITORING_DISPLAY.UPLOAD_LIST_MATCH ? true : false}
                                />
                                <label className={classes.child} htmlFor="status3">Upload List Match</label>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="display4"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={4}
                                    checked={payload.display === status.MONITORING_DISPLAY.MATCH_REPORT ? true : false}
                                />
                                <label className={classes.child} htmlFor="status4">Match Report</label>
                            </div>
                        </div>


                    </div>
                    {payload.display == 3 ?
                        <SimpleForm toolbar={<UserEditToolbar />}>
                            <FileInput source="files" label=" " accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                format={(value) => setUrl(value)}
                                sx={{ "& .RaFileInput-dropZone": { my: '-20px' } }}
                            >
                                <FileField source="src" title="title" />
                            </FileInput>
                            {url == undefined ? null :
                                <a style={{ fontSize: "16px", color: "blue", textDecoration: "none", textTransform: "capitalize", marginBottom: "30px" }}>{url?.title}</a>
                            }
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Buttons
                                    type="button"
                                    variant="contained"
                                    color="success"
                                    onClick={() => previewExcel()}
                                >
                                    Click Match
                                </Buttons>
                                {openMatch == true ?
                                    <Buttons
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => saveHistory()}
                                    >
                                        Proccess
                                    </Buttons>
                                    : null}

                            </div>
                        </SimpleForm>
                        : payload.display == 4 ? null :
                            <div className={classes.row}>
                                <div className={classes.column} style={{ width: 'auto' }}>
                                    <div style={{ width: 200 }}>
                                        Kode Nasabah
                                    </div>
                                </div>
                                <div className={classes.column}>
                                    <input
                                        type="text"
                                        onChange={handleKodeNasabah}
                                        name="kode_nasabah"
                                        value={kode_nasabah}
                                    />
                                </div>
                            </div>
                    }
                </div>

                {(payload.display == 3 || payload.display == 4) ? null :
                    (loading == true || pdfDataLoading == true) ?
                        <div className={classes.button}>
                            <Button
                                disabled
                                label="View"
                                onClick={handleSubmit}
                                className={classes.viewButton}
                            />
                            <Button
                                disabled
                                label="Reset"
                                onClick={resetButton}
                                className={classes.resetButton}
                            />
                        </div>
                        :
                        <div className={classes.button}>
                            <Button
                                label="View"
                                onClick={handleSubmit}
                                className={classes.viewButton}
                            />
                            <Button
                                label="Reset"
                                onClick={resetButton}
                                className={classes.resetButton}
                            />
                        </div>


                }
            </div>
            <div>
                {(loading == true) ?
                    <div className={classes.rowCenter}>
                        <div><b>Loading....</b></div>
                    </div>
                    :
                    <div></div>
                }
            </div>
            {payload.display == 3 || payload.display == 1 ? null :
                <div className={classes.exportButton}>
                    <Button
                        label="Excel"
                        onClick={async () => {
                            await DownloadExcel(pdfDataList, payload.status, payload.display)
                        }}
                        className=""
                    />
                    {/* <PDFDownloadLink
                    document={
                        <MyDocument
                            data={pdfDataList?.length > 0 && pdfDataList}
                            state={payload.status}
                            display={payload.display}
                            title={getPdfTitle()}
                        />
                    }
                    style={{
                        textDecoration: 'none'
                    }}
                    fileName="test.pdf"
                >
                    {({ loading }) => (
                        loading
                            ? 'Loading document...'
                            : (
                                <Button
                                    label="PDF"
                                    className=""
                                />
                            )
                    )}
                </PDFDownloadLink> */}

                </div>
            }

            <ListContextProvider
                value={{
                    data: keyBy(dataList, 'id'),
                    ids: dataList?.length > 0 && dataList.map(({ id }) => id) || [],
                    total,
                    page,
                    perPage,
                    setPerPage: number => {
                        setPerPage(number)
                    },
                    setPage,
                    currentSort: { order: 'ASC' },
                    basePath: "/gmra-margin-monitoring",
                    resource: 'gmra-margin-monitoring',
                    selectedIds: []
                }}
            >
                <div style={{
                    overflowX: 'scroll',
                    width: window.innerWidth - 300
                }}>
                    {_renderTable()}
                </div>
                <Pagination />
            </ListContextProvider >

        </>
    )
}