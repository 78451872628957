import decodeJwt from 'jwt-decode';

export default {
    login: ({ strategy, username, password }) => {
        localStorage.removeItem('token');
        localStorage.removeItem('feathers-jwt')
        localStorage.setItem('feathers-jwt', '')
        //const strategy = 'mybo';
        // const strategy = 'local';
        const KODE = username;
        const PASSWORD = password;
        var host = ""
        host = 'http://172.16.115.49:3030/authentication';
        if (window.location.origin == 'https://gmra.siminvest.co.id') {
            host = 'https://gmra.siminvest.co.id/api/v1/authentication';
        }
        else if (window.location.origin == 'https://gmra.sinarmascakrawala.co.id') {
            host = 'https://gmra.sinarmascakrawala.co.id/api/v1/authentication';
        }
        else if (window.location.origin == 'https://gmra.nirmalataruna.co.id') {
            host = 'https://gmra.nirmalataruna.co.id/api/v1/authentication';
        }
        else if (window.location.origin == 'https://gmra.shintautama.co.id') {
            host = 'https://gmra.shintautama.co.id/api/v1/authentication';
        }
       // host = 'http://172.16.115.49:3030/authentication';
        var request
        if (strategy == 'local') {

            // request = new Request('http://172.17.26.150:3030/authentication', {
            request = new Request(host, {
                method: 'POST',
                body: JSON.stringify({ strategy, username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
        }

        else if (strategy == 'mybo') {
            request = new Request(host, {
                //      request = new Request('http://172.17.26.150:3030/authentication', {
                // request = new Request('https://gmra.siminvest.co.id/api/v1/authentication', {
                method: 'POST',
                body: JSON.stringify({ strategy, KODE, PASSWORD }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
        }

        const fetcher = fetch(request).then(response => {
            //  console.log(response)
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject({ redirectTo: '/login' });
            }
            //console.log("RESPONSE", response);
            return response.json();
        }).then((item) => {

            localStorage.setItem('feathers-jwt', item.accessToken)
            localStorage.setItem('username', username);
            localStorage.setItem('token', item.accessToken);
            localStorage.setItem('permissions', [item.user.roles]);
            localStorage.setItem('oid', item.user.office_id)
            localStorage.setItem('uid', item.user.id)
            localStorage.setItem('kode_buyer', item.user.buyer_kode);

        });

        setTimeout({}, 3000);
        //console.log(fetcher)
        return fetcher

    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('feathers-jwt')
        return Promise.resolve();
    },
    checkError: error => {
        //console.log(error)

        localStorage.setItem('feathers-jwt')
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.reject({ redirectTo: '/login' });
    },
    checkAuth: () => {
        return localStorage.getItem('feathers-jwt') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        if (role == null) {
            return Promise.resolve([]);
        }
        return role ? Promise.resolve(role) : Promise.reject();
    }
};