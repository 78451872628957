import { forwardRef } from 'react';
import { Layout, Sidebar, AppBar, Logout, UserMenu, ListButton, MenuItemLink } from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Key as KeyIcon } from '@mui/icons-material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';


const MyLayout = (props) => (
  <Layout
    {...props}
    appBar={MyAppBar}
    sidebar={MySidebar}
    sx={{
      // sidebar background color
      "& .RaLayout-contentWithSidebar": {
        backgroundColor: '#737b86'
      }
    }}
  />
);

const MyAppBar = (props) => {
  // const usr = getCurrentUser();
  return (
    <AppBar
      {...props}
      userMenu={(
        <>
          <MyUserMenu />
        </>
      )}
    />
  );
};

// change sidebar theme
const MySidebar = (props) => (
  <Sidebar
    {...props}
    // actually this sx props can be set directly from <Layout> Component
    sx={{
      // sidebar menu color
      "& .RaSidebar-fixed .MuiMenuItem-root": {
        color: '#fff'
      },
      // sidebar menu icon color
      "& .RaSidebar-fixed .MuiListItemIcon-root": {
        color: '#fff'
      },
      // sidebar menu menu active
      "& .RaSidebar-fixed .MuiMenuItem-root.RaMenuItemLink-active": {
        backgroundColor: '#a0a5ad',
        color: '#fff'
      },
    }}
  />
);


const MyUserMenu = props => (
  <UserMenu {...props}>
    {/* <ChangePassword /> */}
    <Logouts />
  </UserMenu>
);

const Logouts = forwardRef((props, ref) => {
  // localStorage.removeItem('token');
  // localStorage.removeItem('permissions');
  // localStorage.removeItem('feathers-jwt')

  return (
    <MenuItemLink to="/login" primaryText="Logout" leftIcon={<PowerSettingsNewIcon/>} />
  );
});

const ChangePassword = forwardRef((props, ref) => {
  return (
    <MenuItemLink to="/change-password" primaryText="Change-password" leftIcon={<KeyIcon />} />
  );
});

export default MyLayout;
