import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';


const LinkField2 = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    const myArr = record[source];
    // var stringk = `data:application/pdf;base64,${myArr}`
    if (myArr == "") {
        return (<div>-</div>)
    }
    else {
        return (<div><a href={myArr} >Download</a></div>)
    }

}

LinkField2.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.string.isRequired,
};

export default LinkField2;