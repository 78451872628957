import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';


const DocumentListField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    console.log(record);
    const myArr = record[source].split("&&");
    var x = 1;
    const myComp = myArr.map((data) => {

        if (data.includes("SK")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Surat Konfirmasi II Customer</a></div>)
        } else if (data.includes("SIC_FOP_B")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Buy Instruction FOP Customer</a></div>)
        } else if (data.includes("SIC_FOP_S")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Sell Instruction FOP Customer</a></div>)
        } else if (data.includes("SIC_DVP_B")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Buy Instruction DVP Customer</a></div>)
        } else if (data.includes("SIC_DVP_S")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Sell Instruction DVP Customer</a></div>)
        } else if (data.includes("DGMR")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>GMRA</a></div>)
        } else if (data.includes("SB-")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Surat Instruksi Pencabutan Blokir Saham</a></div>)
        } else if (data.includes("ST_FOP_S")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Sell Instruction FOP Company</a></div>)
        } else if (data.includes("ST_FOP_B")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Buy Instruction FOP Company</a></div>)
        } else if (data.includes("ST_DVP_S")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Sell Instruction DVP Company</a></div>)
        } else if (data.includes("ST_DVP_B")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Buy Instruction DVP Company</a></div>)
        } else if (data.includes("SAMB") || data.includes("SS")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Surat Konfirmasi II Seller</a></div>)
        } else if (data.includes("IBS-")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Surat blokir Saham</a></div>)
        } else if (data.includes("SUK")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Surat Kuasa</a></div>)
        } else if (data.includes("SBP")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Surat Instruksi Pencabutan Blokir Saham Perpanjang</a></div>)
        } else if (data.includes("SP-")) {
            if (data.includes("IBSP-")) {
                return (<div><a href={data} style={{ fontSize: 12 }}>Surat blokir Saham Perpanjang</a></div>)
            }
            else {
                return (<div><a href={data} style={{ fontSize: 12 }}>Surat Pernyataan dan Kuasa </a></div>)
            }
        } else if (data.includes("SPP-")) {
            return (<div><a href={data} style={{ fontSize: 12 }}>Surat Pernyataan dan Kuasa Perpanjang</a></div>)
        }

    }



    )
    return myComp;
}

DocumentListField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.string.isRequired,
};

export default DocumentListField;