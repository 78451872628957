import React from 'react';

import {
    List,
    Datagrid,
    CreateButton,
    TopToolbar,
    TextField,
    NumberField,
    DateField,
    TextInput,
    EditButton,
    FilterButton,
    BooleanField,
    useRecordContext,
    BooleanInput
} from 'react-admin';

import AttachmenttField from '../field/AttachmentField';

const postFilters = [
    <TextInput label="Kode Saham" source="kode_saham" defaultValue="" />,
];
const EditDocument = (props) => {
    const record = useRecordContext(props);
    var link = "/#/gmra-threshold/" + record.id

    if (record.active == 0) {
        return (<a href={link}><button disabled>EDIT</button></a>)
    }
    else {
        return (<a href={link}><button>EDIT</button></a>)
    }
}
function ListActions(props) {

    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton />
        </TopToolbar>)
}

export function ThresholdList(props) {
    return (
        <List {...props} title="Harga" filters={postFilters}  actions={<ListActions />} bulkActionButtons={false}>
            <Datagrid >
               
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
                <TextField source="threshold" label="Rasio Margin %" />
                <TextField source="min_rasio" label="Minimum %" />
                <TextField source="max_rasio" label="Maximum %" />
                <AttachmenttField source="baseFile" label="Attachment" />
                <BooleanField source="active" />
                <EditDocument source="active" label="Action" />
                <DateField source="UpdatedDate" label="Last Update Date" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
            </Datagrid>
        </List>
    )
}