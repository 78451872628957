import React, { useState, useEffect, useContext } from 'react';
import { Create, SimpleForm, NumberInput, TextInput, FileField, DateInput, FileInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import { DataProviderContext } from 'react-admin';
var fs = require('browserify-fs');

var md5 = require("md5");

export const TaxReportCreate = (props) => {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="kode_nasabah" label="Kode Nasabah" required />
                <NumberInput source="year" label="Tahun" />
                <TextInput source="is_generated" style={{ display: 'none' }} defaultValue="0" />
                <TextInput source="url" style={{ display: 'none' }} defaultValue="" />
            </SimpleForm>
        </Create>
    );
}
