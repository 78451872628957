import { Datagrid, DateField, TextField, useListContext, useRecordContext, Title } from "react-admin";
import { formatMoney, normalizedDate, normalizedMonth } from "../utils/helper";
import ThousandField from '../../field/ThousandField';
import PercentageField from '../../field/PercentageField';
import BSField from "../../field/BSField";
import SuratInstruksiField from '../../field/SuratInstruksiField';
import PositiveField from '../../field/PositiveField';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Modal, Box, Typography, Card, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';

const FormatMoney = (props) => {
    const record = useRecordContext(props);
    const { withCurrency } = props;
    return <>{formatMoney(record[props.source], false)}</>
};

const FormatMonth = (props) => {
    const record = useRecordContext(props);
    return <>{normalizedMonth(record.month)}</>
};

const FormatDate = (props) => {
    const record = useRecordContext(props);
    return <>{normalizedDate(record[props.source])}</>
};

const ListNasabah = () => (
    <Datagrid>
        <TextField source="id" label="No" />
        <TextField source="kode_buyer" textAlign='left' label="Kode Principal" />
        <TextField source="ClientID" textAlign='left' label="Kode Nasabah" />
        <TextField source="ClientName" textAlign='left' label="Nama Nasabah" />
        <TextField source="KSEISubAccountNo" textAlign='left' label="SRENumber" />
        <TextField source="no_surat_konfirmasi" textAlign='left' label="Contract Number" />
        <TextField source="tenor" textAlign='left' label="Tenor" />
        <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'long', day: 'numeric' }} textAlign="center" />
        <DateField source="tanggal_jatuh_tempo" options={{ year: 'numeric', month: 'long', day: 'numeric' }} textAlign="center" />
        <ThousandField source="jumlah" label="Lembar Saham" />
        <ThousandField source="nominal_beli" label="Nominal Beli" />
        <FormatMoney source="harga_beli" label="harga_saham" />
        <PercentageField source="rasio_marjin" label="Rasio Margin" />
        <TextField source="Action" textAlign='left' label="Opsi" />
        <ThousandField source="jumlah_saham_disesuaikan" label="Lembar Top Up/Deduct" />
        <ThousandField source="total_saham" label="Total Saham" />
        <ThousandField source="market_value" label="Market Value" />
    </Datagrid>
);

const FormatTransaksi = () => (
    <Datagrid>
        <TextField source="BS" textAlign='left' label="Buy/Sell" />
        <TextField source="kode_saham" textAlign='left' label="Kode Saham" />
        <TextField source="harga_beli" textAlign='left' label="Price" />
        <PositiveField source="jumlah_saham_disesuaikan" label="Lembar Top Up/Deduct" />
        <TextField source="ClientID" textAlign='left' label="Kode Nasabah" />
    </Datagrid>
);
const SuratInstruksi = () => (
    <Datagrid>
        <TextField source="kode_nasabah" textAlign='left' label="Kode Nasabah" />
        <TextField source="nama_nasabah" textAlign='left' label="Nama Nasabah" />
        <TextField source="no_surat_konfirmasi" textAlign='left' label="No Surat Konfirmasi" />
        <TextField source="kode_buyer" textAlign='left' label="Principal" />
        <BSField source="BS" textAlign='left' label="Buy/Sell" />
        <TextField source="kode_saham" textAlign='left' label="Kode Saham" />
        <TextField source="volume" textAlign='left' label="Volume" />
        <TextField source="harga_beli" textAlign='left' label="Harga Saham" />
        <TextField source="nilai_transaksi" textAlign='left' label="Nilai Transaksi" />
        <DateField source="tanggal_transaksi" options={{ year: 'numeric', month: 'long', day: 'numeric' }} textAlign="center" />
        <DateField source="tanggal_penyelesaian" options={{ year: 'numeric', month: 'long', day: 'numeric' }} textAlign="center" />
        <SuratInstruksiField source="link_surat" />
    </Datagrid>
);

const UploadList = (dataList) => {
    console.log(dataList)
    return (
        <div>
            <Title title="Book list" />
            <Card>
                <Table sx={{ padding: 2 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Trade ID</TableCell>
                            <TableCell>Client ID</TableCell>
                            <TableCell>Market</TableCell>
                            <TableCell>B/S</TableCell>
                            <TableCell>Stock</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Lot</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>Gross Amount</TableCell>
                            <TableCell>Nomor Surat Konfirmasi</TableCell>
                            <TableCell>Matching</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataList?.map(book => (

                            <TableRow >
                                <TableCell>{book.trade_id}</TableCell>
                                <TableCell>{book.clientid}</TableCell>
                                <TableCell>{book.market}</TableCell>
                                <TableCell>{book['b/s']}</TableCell>
                                <TableCell>{book.stock}</TableCell>
                                <TableCell>{book.price}</TableCell>
                                <TableCell>{book.lot}</TableCell>
                                <TableCell>{book.volume}</TableCell>
                                <TableCell>{book['gross amount']}</TableCell>
                                <TableCell>{book['no surat konfirmasi']}</TableCell>
                                <TableCell>{book.matching}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>

        </div>
    )
}

const MatchList = () => {
    return (
        <Datagrid>
            <TextField textAlign='center' source="tradeId" label="Trade ID" />
            <TextField textAlign='center' source="clientId" label="Client ID" />
            <TextField textAlign='center' source="market" label="Market" />
            <TextField textAlign='center' source="bs" label="B/S" />
            <TextField textAlign='center' source="stock" label="Stock" />
            <TextField textAlign='center' source="price" label="Price" />
            <TextField textAlign='center' source="lot" label="Lot" />
            <TextField textAlign='center' source="volume" label="Volume" />
            <TextField textAlign='lcenter' source="gross_amount" label="Gross Amount" />
            <TextField textAlign='center' source="no_surat_konfirmasi" label="No Surat Konfirmasi" />
            <DateField source="monitoring_date" options={{ year: 'numeric', month: 'long', day: 'numeric' }} label="Date" textAlign="center" />
        </Datagrid>
    )
}

export { ListNasabah, FormatTransaksi, SuratInstruksi, UploadList, MatchList };

