import React, { useState } from 'react';
import {
    List,
    Datagrid,
    CreateButton,
    TopToolbar,
    TextField,
    DateField,
    TextInput,
    FilterButton,
    EditButton,
    useRecordContext,
    DateInput
} from 'react-admin';
import DocumentUploadField from '../field/DocumentUploadField';
import ThousandField from '../field/ThousandField';
import { makeStyles } from '@material-ui/core/styles';

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});
const postFilters = [
    <DateInput label="Tanggal Efektif" source="tanggal_efektif" />,
    <DateInput label="Tanggal Jatuh Tempo" source="tanggal_jatuh_tempo" />,
    <TextInput label="Kode Nasabah" source="kode_nasabah" />,
    <TextInput label="No Surat Konfirmasi" source="no_surat_konfirmasi" />,
    <TextInput label="Sales" source="sales" />
];

function ListActions(props) {

    function handleClick(event) {
        // Here, we invoke the callback with the new value
        props.onClick(event.target.value);
    }
    return (
        <TopToolbar>
            <FilterButton />
        </TopToolbar>)
}


export function UploadList(props) {
    const [modalIsOpen, showModal] = useState(false);
    const classes = useStyles();


    const ShowDocument = (props) => {
        const record = useRecordContext(props);
        const link = "/#/gmra-trans/" + record.id + "/show"
        return (<a href={link} >{record.no_surat_konfirmasi}</a>)
    }
    const convertFileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    return (
        <List {...props} filters={postFilters} sort={{ field: 'id', order: 'DESC' }} filter={{ active: 1 }} actions={<ListActions onClick={() => showModal(true)} />} bulkActionButtons={false} >
            <Datagrid rowClick="edit">
                <ShowDocument source="no_surat_konfirmasi" label="No Surat Konfirmasi"></ShowDocument>
                <TextField source="kode_nasabah" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="cabang" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="sales" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_saham" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <ThousandField source="nominal_beli" />
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <DateField source="tanggal_jatuh_tempo" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <DocumentUploadField source="document" />
                <EditButton basePath="/gmra-upload-list" />
            </Datagrid>
        </List>
    )
}