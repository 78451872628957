import { colors, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import {
    useQuery,
    ListContextProvider,
    Pagination,
    Button,
    useListContext
} from 'react-admin';
import keyBy from 'lodash/keyBy'
import {
    ReportTransactionListPerDate,
    ReportTransactionListPerMonth,
    ReportTransactionListPerName,
    ReportTransactionListPerTx
} from './components/Cell';
import { DownloadExcel } from './components/DownloadExcel';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MyDocument } from './components/DownloadPDF';
import { dateToISO, getStatus, normalizedDate, status } from './utils/helper';
import "./ReportList.css"

const useStyles = makeStyles({
    filterSection: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20,
        // border: '1px solid red'
    },
    child: {
        paddingLeft: 10,
    },
    row: {
        display: 'table',
        content: '',
        clear: 'both',
        textAlign: 'left',
        width: '100%'
    },
    rowCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%'
    },
    column: {
        float: 'left',
        // width: '20%',
        padding: '10px',
        flexDirection: 'row',
        height: 50
    },
    button: {
        marginTop: 10,
        marginLeft: 230
    },
    viewButton: {
        backgroundColor: colors.blue[400],
        color: 'white',
        paddingRight: 10
    },
    resetButton: {
        backgroundColor: colors.grey[400],
        color: 'white',
        paddingRight: 10,
        marginLeft: 10
    },
    text: {
        fontSize: 13,
        padding: 0,
        textAlign: 'left'
    },
    cellPad: { padding: 0 },
    exportButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
});

export function ReportList(props) {
    const [payload, setPayload] = useState({
        effectiveStartDate: '',

        maturityStartDate: '',

        status: '0',
        display: ''
    });
    const [page, setPage] = useState(1);
    const [dataList, setDataList] = useState([]);
    const [pdfDataList, setPdfDataList] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const [pdfLoadData, setPdfLoadData] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const classes = useStyles();


    const handlePayload = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        setPayload({
            ...payload,
            [name]: value
        });

        setPage(1);
        setDataList([]);
    };

    const getPdfTitle = () => {
        let title = '';
        if (payload.display != '') {
            const { effectiveStartDate, maturityStartDate } = payload;
            if (effectiveStartDate) {
                title += `Tanggal efektif: ${normalizedDate(effectiveStartDate)}}\n\n`;
            }
            if ((Number(payload.status) === 1) && (maturityStartDate)) {
                title += `Tanggal jatuh tempo: ${normalizedDate(maturityStartDate)}}\n\n`;
            }
            title += `Status: ${getStatus('status', payload.status)}\n\nDisplay: ${getStatus('display', payload.display)}`;
        }
        return title;

    }

    const resetButton = () => {
        setPayload({
            effectiveStartDate: '',

            maturityStartDate: '',

            status: '0',
            display: ''
        })
    }

    const optQuery = (perPage) => {
        const filter = {
            status: payload.status,
            display: payload.display
        };

        if (payload.effectiveStartDate !== '') {
            filter.effectiveStartDate = payload.effectiveStartDate;

        }

        if (payload.maturityStartDate !== '') {
            filter.maturityStartDate = payload.maturityStartDate;

        }

        return {
            type: 'GET_LIST',
            resource: 'gmra-report',
            payload: {
                pagination: {
                    page,
                    perPage
                },
                sort: { field: 'id', order: 'ASC' },
                filter
            }
        }
    }

    const { data, total, loading, error } = useQuery(optQuery(perPage));
    console.log(payload)

    const handleSubmit = async () => {
        if (payload.effectiveStartDate != '') {

        }
        if (payload.maturityStartDate != '') {

        }
        console.log('submit clicked');
        setDataList(data);

    }

    const { data: pdfData, loading: pdfDataLoading, error: pdfDataError } = useQuery(optQuery(8000));
    if (pdfDataLoading && pdfLoadData) {
        setPdfLoadData(false);
    }

    if (!pdfDataLoading && !pdfLoadData) {
        setPdfDataList(pdfData);
        setPdfLoadData(true);
    }

    if (pdfDataError && pdfDataList?.length > 0) {
        setPdfDataList([]);
    }
    const _renderTable = () => {
        const s = Number(payload.status);
        const d = Number(payload.display);

        switch (d) {
            case 3:
                return ReportTransactionListPerName(s);
            case 2:
                return ReportTransactionListPerMonth(s);
            case 1:
                return ReportTransactionListPerDate(s);
            case 0:
                return ReportTransactionListPerTx(s);
            default:
                console.log('nothing...');
                break;
        }
    }
    let display = payload.display

    const previousValues = useRef({ page, data, payload, display });

    useEffect(() => {
        console.log(previousValues.current.page)
        console.log(page)
        console.log(previousValues.current.data)
        console.log(data)
        console.log(previousValues.current.payload)
        console.log(payload)
        console.log(previousValues.current.display)
        console.log(payload.display)


        if ((previousValues.current.page != 1) && (previousValues.current.display != payload.display)) {
            console.log("change display")
            setPdfDataList(pdfData);
            previousValues.current = { page, data, payload, display };
            return
        }
        if ((previousValues.current.payload == payload) && (previousValues.current.payload != undefined)) {
            setDataList(data);
            console.log("both changed")

        }
        console.log("in")
        setPdfDataList(pdfData);
        previousValues.current = { page, data, payload, display };

    }, [data]);


    return (
        <>
            <div className={classes.filterSection}>
                <div className={classes.row}>
                    <div className={classes.column} style={{ width: 'auto' }}>
                        <div style={{ width: 200 }}>
                            Tanggal Efektif
                        </div>
                    </div>
                    <div className={classes.column}>
                        <input
                            type="date"
                            onChange={handlePayload}
                            name="effectiveStartDate"
                            value={payload.effectiveStartDate}
                        />


                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column} style={{ width: 'auto' }}>
                        <div style={{ width: 200 }}>
                            Tanggal Jatuh tempo
                        </div>
                    </div>
                    <div className={classes.column}>
                        <input
                            type="date"
                            onChange={handlePayload}
                            name="maturityStartDate"
                            value={payload.maturityStartDate}
                        />


                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column} style={{ width: 'auto' }}>
                        <div style={{ width: 200 }}>
                            Status
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div>
                                <input
                                    id="status0"
                                    type="checkbox"
                                    name="status"
                                    onClick={handlePayload}
                                    value={0}
                                    checked={payload.status === '0' ? true : false}
                                />
                                <label className={classes.child} htmlFor="status0">Active</label>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="status1"
                                    type="checkbox"
                                    name="status"
                                    onClick={handlePayload}
                                    value={1}
                                    checked={payload.status === '1' ? true : false}
                                />
                                <label className={classes.child} htmlFor="status1">Matured</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column} style={{ width: 'auto' }}>
                        <div style={{ width: 200 }}>
                            Display
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div>
                                <input
                                    id="display0"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={0}
                                    checked={payload.display === status.REPORT_DISPLAY.PER_TRANSACTION ? true : false}
                                />
                                <label className={classes.child} htmlFor="display0">Per Transaction</label>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="display1"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={1}
                                    checked={payload.display === status.REPORT_DISPLAY.PER_DATE ? true : false}
                                />
                                <label className={classes.child} htmlFor="display1">Per Date</label>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="display2"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={2}
                                    checked={payload.display === status.REPORT_DISPLAY.PER_MONTH ? true : false}
                                />
                                <label className={classes.child} htmlFor="display2">Per Month</label>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    id="display3"
                                    type="checkbox"
                                    name="display"
                                    onClick={handlePayload}
                                    value={3}
                                    checked={payload.display === status.REPORT_DISPLAY.PER_NAME ? true : false}
                                />
                                <label className={classes.child} htmlFor="display3">Per Name</label>
                            </div>
                        </div>
                    </div>
                </div>

                {(loading == true || pdfDataLoading == true) ?
                    <div className={classes.rowCenter}>
                        <div>Loading....</div>
                    </div>
                    :
                    <div className={classes.button}>
                        <Button
                            label="Click View"
                            onClick={handleSubmit}
                            className={classes.viewButton}
                        />
                        <Button
                            label="Reset"
                            onClick={resetButton}
                            className={classes.resetButton}
                        />
                    </div>
                }

            </div>
            {payload.display != '' ?
                <div className={classes.exportButton}>
                    <Button
                        label="Excel"
                        onClick={async () => {
                            await DownloadExcel(pdfDataList, payload.status, payload.display)
                        }}
                        className=""
                    />
                    <PDFDownloadLink
                        document={
                            <MyDocument
                                data={pdfDataList?.length > 0 && pdfDataList}
                                state={payload.status}
                                display={payload.display}
                                title={getPdfTitle()}
                            />
                        }
                        style={{
                            textDecoration: 'none'
                        }}
                        fileName="test.pdf"
                    >
                        {({ loading }) => (
                            loading
                                ? 'Loading document...'
                                : (
                                    <Button
                                        label="PDF"
                                        className=""
                                    />
                                )
                        )}
                    </PDFDownloadLink>

                </div>
                : null}
            <ListContextProvider
                value={{
                    data: keyBy(dataList, 'id'),
                    ids: dataList?.length > 0 && dataList.map(({ id }) => id) || [],
                    total,
                    page,
                    perPage,
                    setPerPage: number => {
                        setPerPage(number)
                    },
                    setPage,
                    currentSort: { order: 'ASC' },
                    basePath: "/gmra-report",
                    resource: 'gmra-report',
                    selectedIds: []
                }}
            >
                {(loading == true || pdfDataLoading == true) ?
                    <div className={classes.rowCenter}>
                        <div className="loader"></div>
                    </div>
                    :
                    <>
                        <div style={{
                            overflowX: 'scroll',
                            width: window.innerWidth - 300
                        }}>
                            {payload.display != '' ? _renderTable() : null}
                        </div>
                        <Pagination />
                    </>}
            </ListContextProvider >
        </>
    )
}