import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatMoney, normalizedDate, normalizedMonth, status } from '../utils/helper';


export const MyDocument = ({ data, state, display, title }) => {
    let fields = [];
    switch (display) {
        case status.REPORT_DISPLAY.PER_TRANSACTION:
            if (state == 0) {
                fields = [
                    'No', 'Kode Nasabah', 'Nama Nasabah', 'SRE Number', 'SID Number', 'Contract Number', 'Tenor', 'Tanggal Efektif', 'Tanggal Jatuh Tempo', 'Nominal Beli',
                    'Lembar Saham', 'Nominal Pembelian', 'Harga Buyback', 'Nilai Investasi Bersih','Tipe Transaksi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'
                ];
            } else {
                fields = [
                    'No', 'Kode Nasabah', 'Nama Nasabah', 'SRE Number', 'SID Number', 'Contract Number', 'Tenor', 'Tanggal Efektif', 'Tanggal Jatuh Tempo', 'Nominal Beli',
                    'Lembar Saham', 'Nominal Pembelian', 'Opsi Maturity', 'Harga Buyback', 'Nilai Investasi Bersih', 'Tipe Transaksi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'
                ];
            }
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, 'Harga Buybac,k', 'Nilai Investasi Bersih', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'];
            // }
            break;
        case status.REPORT_DISPLAY.PER_DATE:
            fields = ['No', 'Tanggal Efektif', 'Tanggal Jatuh Tempo', 'Lembar Saham', 'Nominal Pembelian', 'Nilai Investasi Bersih', 'Nilai Investasi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'];
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, 'Nilai Investasi Bersih', 'Nilai Investasi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'];
            // }
            break;
        case status.REPORT_DISPLAY.PER_MONTH:
            fields = ['No', 'Bulan', 'Tahun', 'Lembar Saham', 'Nominal Pembelian', 'Nilai Investasi Bersih', 'Nilai Investasi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'];
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, 'Nilai Investasi Bersih', 'Nilai Investasi', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Principal'];
            // }
            break;
        case status.REPORT_DISPLAY.PER_NAME:
            if (data[0]?.nilai_bersih_redemption != undefined) {
                fields = ['No', 'Nama Nasabah', 'Kode Nasabah', 'SRE Number', 'SID Number', 'Lembar Saham', 'Nominal beli', 'Nilai Bersih Redemption', 'Nilai Bersih Rollover Pricipal'];
            } else {
                fields = ['No', 'Nama Nasabah', 'Kode Nasabah', 'SRE Number', 'SID Number', 'Lembar Saham', 'Nominal beli'];
            }
            break;
        default:
            break;
    }
    const fontSize = fields.length > 10 ? 6 : 7;
    // Create styles
    const styles = StyleSheet.create({
        table: {
            width: '100%',
        },
        headerTitle: {
            alignItems: 'center',
            paddingTop: 20,
            paddingBottom: 20
        },
        title: {
            fontSize: 13,
            paddingBottom: 3,
            fontWeight: 'thin'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #EEE',
            paddingTop: 8,
            paddingBottom: 8,
        },
        header: {
            borderTop: 'none',
        },
        bold: {
            fontWeight: 'bold',
        },
        row1: {
            width: `${(100 / (fields.length))}%`,
            paddingLeft: 10,
            fontSize,
            textAlign: 'center'
        },
        row2: {
            width: `2%`,
            paddingLeft: 10,
            fontSize,
            textAlign: 'center'
        },
        customRow: {
            width: '10%',
            paddingLeft: 10,
            fontSize,
            textAlign: 'center'
        }
    });

    const getStyleField = (field) => {
        if (display === status.REPORT_DISPLAY.PER_TRANSACTION) {
            switch (field) {
                case 'No':
                    return styles.row2;
                case 'SRE Number':
                case 'SID Number':
                    const styleSID = {
                        ...styles.customRow,
                        width: '7%'
                    }
                    return styleSID;
                case 'Contract Number':
                    const style = {
                        ...styles.customRow,
                        width: '11%'
                    }
                    return style;
                case 'Tenor':
                    const customStyle = {
                        ...styles.customRow,
                        width: '3%'
                    }

                    return customStyle;
                default:
                    return styles.row1;
            }
        }

        return styles.row1

    }

    const cell = data?.length > 0 && data.map((item, i) => {
        switch (display) {
            case status.REPORT_DISPLAY.PER_TRANSACTION:
                return (
                    <View style={styles.row} wrap={false}>
                        <Text style={getStyleField('No')}>{item.id}</Text>
                        <Text style={styles.row1}>{item.kode_nasabah}</Text>
                        <Text style={styles.row1}>{item.customer_name}</Text>
                        <Text style={getStyleField('SRE Number')}>{item.sre}</Text>
                        <Text style={getStyleField('SID Number')}>{item.sid}</Text>
                        <Text style={getStyleField('Contract Number')}>{item.contract_number}</Text>
                        <Text style={[styles.row1, { width: '2%' }]}>{item.tenor}</Text>
                        <Text style={styles.row1}>{normalizedDate(item.tanggal_efektif)}</Text>
                        <Text style={styles.row1}>{normalizedDate(item.tanggal_jatuh_tempo)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nominal_beli, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.jumlah, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nominal_pembelian, false)}</Text>
                        {state == 1 ? 
                        <Text style={styles.row1}>{item.opsi_maturity}</Text>
                        :null}                   
                        <Text style={styles.row1}>{formatMoney(item.harga_buyback, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_investasi_bersih, false)}</Text>
                        <Text style={styles.row1}>{item.tipe_transaksi}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_bersih_redemption, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_bersih_rollover_principal, false)}</Text>
                    </View>
                );
            case status.REPORT_DISPLAY.PER_DATE:
                return (
                    <View style={styles.row} wrap={false}>
                        <Text style={getStyleField('No')}>{item.id}</Text>
                        <Text style={styles.row1}>{normalizedDate(item.tanggal_efektif)}</Text>
                        <Text style={styles.row1}>{normalizedDate(item.tanggal_jatuh_tempo)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.jumlah, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nominal_pembelian, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_investasi_bersih, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_investasi, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_bersih_redemption, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_bersih_rollover_principal, false)}</Text>

                    </View>
                );
            case status.REPORT_DISPLAY.PER_MONTH:
                return (
                    <View style={styles.row} wrap={false}>
                        <Text style={getStyleField('No')}>{item.id}</Text>
                        <Text style={styles.row1}>{normalizedMonth(item.month)}</Text>
                        <Text style={styles.row1}>{item.year}</Text>
                        <Text style={styles.row1}>{formatMoney(item.jumlah, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nominal_pembelian, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_investasi_bersih, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_investasi, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_bersih_redemption, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nilai_bersih_rollover_principal, false)}</Text>
                    </View>
                );
            case status.REPORT_DISPLAY.PER_NAME:
                return (
                    <View style={styles.row} wrap={false}>
                        <Text style={getStyleField('No')}>{item.id}</Text>
                        <Text style={styles.row1}>{item.customer_name}</Text>
                        <Text style={styles.row1}>{item.kode_nasabah}</Text>
                        <Text style={getStyleField('SRE Number')}>{item.sre}</Text>
                        <Text style={getStyleField('SID Number')}>{item.sid}</Text>
                        <Text style={styles.row1}>{formatMoney(item.jumlah, false)}</Text>
                        <Text style={styles.row1}>{formatMoney(item.nominal_beli, false)}</Text>

                        {(item.nilai_bersih_redemption != undefined) && (
                            <Text style={styles.row1}>{formatMoney(item.nilai_bersih_redemption, false)}</Text>
                        )}

                        {item.nilai_bersih_rollover_principal != undefined && (
                            <Text style={styles.row1}>{formatMoney(item.nilai_bersih_rollover_principal, false)}</Text>
                        )}

                    </View>
                );
            default:
                break;
        }
    });
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                {/* <View style={styles.headerTitle}>
                    <Text style={styles.title}>{title}</Text>
                </View> */}
                <View style={styles.table}>
                    <View style={[styles.row, styles.bold, styles.header]}>
                        {
                            fields.map((field) => (
                                <Text style={getStyleField(field)}>{field}</Text>
                            ))
                        }
                    </View>
                    {cell}

                </View>
            </Page>
        </Document>
    )
}