import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';


const RedeemField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    
    const redeem = record[source];
    if (redeem == 1) {
        return (<div style={{color:'red'}}>Closed</div>)
    }
    else {
        return (<div style={{color:'green'}}>Active</div>)
    }

}

RedeemField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.number
};

export default RedeemField;