const formatMoney = (money, withCurrency = false) => {
    money = new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(Number(money));
    if (!withCurrency) {
        money = money.replace('Rp', '').split(',')[0];
    }

    return money;
};

const normalizedMonth = (m) => {    
    const monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    return monthNames[m - 1];
};

const normalizedDate = (dateString) => {
    const date = new Date(dateString || new Date());
    const options = {
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
    };
    return new Intl.DateTimeFormat('en', options).format(date);
}

const dateToISO = (date) => {
    return date.toISOString().split('T')[0];
}

const dateToString = (date) => {
    return new Date(date).toLocaleString().split(',')[0];
}
const status = {    
    REPORT_STATUS: {
        'ACTIVE': '0',
        'MATURED': '1'
    },
    REPORT_DISPLAY: {
        'PER_TRANSACTION': '0',
        'PER_DATE': '1',
        'PER_MONTH': '2',
        'PER_NAME': '3',
    }
};

const getStatus = (prop, value) => {
    const s = Object.keys(status.REPORT_STATUS);
    const d = Object.keys(status.REPORT_DISPLAY);

    return prop === 'status' ? s[value].toLowerCase() : d[value].replace('_', ' ').toLowerCase();
}

export { formatMoney, normalizedMonth, normalizedDate, status, getStatus, dateToISO, dateToString };