import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import { DataProviderContext } from 'react-admin';

const DocumentUploadField = (props) => {

    const { source } = props;
    const dataProvider = useContext(DataProviderContext);
    const record = useRecordContext(props);
    const deleteRecord = (id) => {
        console.log("PRESSED", id)
        dataProvider.delete('gmra-upload-list', {
            id: id,
            previousData: { title: "hello, world!" }
        })
    }

    if (record[source] != null) {
        const myArr = record[source].split(",");
        var x = 1;
        const myComp = myArr.map((data) => {
            return (<div><a href={data.split("||")[0]} style={{ fontSize: 12 }}>{data.split("||")[1]}</a> <button onClick={(e) => { e.stopPropagation(); deleteRecord(data.split("||")[2]);window.location.reload(); }}>X</button></div>)
        })
        return myComp;
    }
    else {
        return "-"
    }

}

DocumentUploadField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.string.isRequired,
};

export default DocumentUploadField;