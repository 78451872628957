import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
    Button,
    useRefresh
} from 'react-admin';
import Buttons from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as XLSX from "xlsx";
import { DataProviderContext } from 'react-admin';

import md5 from 'md5'

const ImportRollover = () => {
    const romanHash = {
        I: 1,
        V: 5,
        X: 10,
        L: 50,
        C: 100,
        D: 500,
        M: 1000,
    };
    const [open, setOpen] = useState(false);
    const [min_rasio, setMinRasio] = useState(1);
    const [max_rasio, setMaxRasio] = useState(1);
    const [items, setItems] = useState([]);
    const [kode_saham, setKode] = useState([]);
    const [threshold, setThreshold] = useState();
    const refresh = useRefresh();
    const [harga, setHarga] = useState([]);
    const [data, setData] = useState();
    const [bunga6, setBunga6] = useState([]);
    const [bunga12, setBunga12] = useState([]);
    const [nama_saham, setNama] = useState([]);
    const [rb, setRB] = useState(0);
    const [rc, setRC] = useState(0);
    const [rollover, setRollover] = useState(1);
    const [jumlahDocument, setCountDocument] = useState(0);
    const [files, setFile] = useState();
    const [submitbutton, setSubmitDisableButton] = useState(false)
    const [tglEfektif, setTglEfektif] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [documentList, setDocumentList] = useState();
    const [holidays, setHoliday] = useState([]);
    const dataProvider = useContext(DataProviderContext);
    const [order, setOrder] = useState(0);
    useEffect(() => {
        countDocument();
    }, tglEfektif)
    const handleClick = () => { countDocument(); setOpen(true); setRollover(1) };
    const handleDialogClose = () => setOpen(false);
    const processDataExcel = () => processData();
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    Date.prototype.addMonth = function (month) {
        var date = new Date(this.valueOf());
        date.setMonth(date.getMonth() + month);
        return date;
    }

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    function calculateJatuhTempo(from, tenor) {
        var todate;
        var tov;

        var d, m, y, ds;

        if (tenor == 12) {
            tov = from.addMonth(12)
            if (tov.getFullYear() % 4 == 0 && tov.getMonth() > 1) {
                tov = tov.addDays(1);
                console.log("KABISAT")
            }

            while (checkHoliday(tov)) {
                tov = tov.addDays(1);
            }

            if (tov.getDay() == 6) {
                tov = tov.addDays(2);
            } else if (tov.getDay() == 0) {
                tov = tov.addDays(1);
            }
            //console.log(tov)
            while (checkHoliday(tov)) {
                tov = tov.addDays(1);
                console.log("HOLIDAY MEET")
            }
            if (tov.getDay() == 6) {
                tov = tov.addDays(2);
            } else if (tov.getDay() == 0) {
                tov = tov.addDays(1);
            }
            var libur = true;
            while (libur) {
                libur = false;
                if (tov.getDay() == 6 || tov.getDay() == 0) {
                    libur = true;
                }
                var libur2 = checkHoliday(tov);
                if (libur == true || libur2 == true) {
                    libur = true;
                    tov = tov.addDays(1);
                }
            }
            d = (tov.getDate() < 10 ? '0' : '') + tov.getDate();
            m = ((tov.getMonth() + 1) < 10 ? '0' : '') + (tov.getMonth() + 1);
            y = tov.getFullYear();
        }
        else {
            //  tov = from.addDays(-1);
            tov = from.addMonth(6);
            if (tov.getFullYear() % 4 == 0 && tov.getMonth() > 1) {
                tov.addDays(1);
            }
            console.log("TOV BEFORE ADD", tov)
            //   tov = tov.addDays(1);
            while (checkHoliday(tov)) {
                tov = tov.addDays(1);
                //  console.log("HOLIDAY MEET")
            }
            if (tov.getDay() == 6) {
                tov = tov.addDays(2);
            } else if (tov.getDay() == 0) {
                tov = tov.addDays(1);
            }

            while (checkHoliday(tov)) {
                tov = tov.addDays(1);
            }
            if (tov.getDay() == 6) {
                tov = tov.addDays(2);
            } else if (tov.getDay() == 0) {
                tov = tov.addDays(1);
            }
            while (checkHoliday(tov)) {
                tov = tov.addDays(1);
                console.log("HOLIDAY MEET")
            }
            if (tov.getDay() == 6) {
                tov = tov.addDays(2);
            } else if (tov.getDay() == 0) {
                tov = tov.addDays(1);
            }
            var libur = true;
            while (libur) {
                libur = false;
                if (tov.getDay() == 6 || tov.getDay() == 0) {
                    libur = true;
                }
                var libur2 = checkHoliday(tov);
                if (libur == true || libur2 == true) {
                    libur = true;
                    tov = tov.addDays(1);
                }
            }

            d = (tov.getDate() < 10 ? '0' : '') + tov.getDate();
            m = ((tov.getMonth() + 1) < 10 ? '0' : '') + ((tov.getMonth() + 1));
            y = tov.getFullYear();
            console.log("TOVMONTHF", tov.getMonth())
            console.log("df", d)
            console.log("mf", m)
            console.log("yf", y)
        }


        todate = new Date(y, m - 1, d);
        console.log("TODATE", todate)
        console.log("FROMDATE", from)
        return todate
    }
    function romanize(num) {
        if (isNaN(num))
            return NaN;
        var digits = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }
    function romanToInt(s) {
        let accumulator = 0;
        for (let i = 0; i < s.length; i++) {
            if (s[i] === "I" && s[i + 1] === "V") {
                accumulator += 4;
                i++;
            } else if (s[i] === "I" && s[i + 1] === "X") {
                accumulator += 9;
                i++;
            } else if (s[i] === "X" && s[i + 1] === "L") {
                accumulator += 40;
                i++;
            } else if (s[i] === "X" && s[i + 1] === "C") {
                accumulator += 90;
                i++;
            } else if (s[i] === "C" && s[i + 1] === "D") {
                accumulator += 400;
                i++;
            } else if (s[i] === "C" && s[i + 1] === "M") {
                accumulator += 900;
                i++;
            } else {
                accumulator += romanHash[s[i]];
            }
        }
        return accumulator;
    }
    function processData() {
        var noorder = order;

        var x = items.length;
        var jumlahSahamDiblokir = 0;
        var nominal_beli = 0;
        var nominal_bayar = 0;
        var nominal_repo = 0
        var tanggal_jatuh_tempo = 0;
        var tingkat_bunga = 0;
        var hari_bunga = 365;
        var harga_penjualan_kembali = 0;
        var nilai_investasi_bersih = 0;

        var fee = 0.001;
        var tojatuh = '';
        var fromdate = '';
        var working_days = 0
        var holiday;
        var pricing_rate_g = 0;
        var pricing_rate_n = 0;
        var harga_buyback = 0;
        var net_interest = 0;
        var tenor = 0;
        var kode_nasabah = "";
        var holiday = [];
        var kode_buyer = "";
        const totalData = items.length;
        var sales;
        var cabang;
        var tipe_nasabah = 0;
        var date;
        var noSurat = jumlahDocument;
        var transcode;
        var kode_nasabah_lama
        var no_surat_konfirmasi_lama
        var nominal_repo_lama
        var kode_saham_lama
        var harga_beli_lama
        var jumlah_lama
        var nominal_beli_lama
        var total_beli_lama
        var fee_beli_lama
        var net_ac_lama
        var tenor_lama
        var penyelesaian_transaksi_lama
        var tanggal_settlement_lama
        var tanggal_jatuh_tempo_lama
        var tingkat_bunga_lama
        var jumlah_hari_bunga_lama
        var harga_buyback_lama
        var harga_penjualan_kembali_lama
        var fee_buyback_lama
        var nilai_investasi_bersih_lama
        var pricing_rate_gross_lama
        var pricing_rate_net_lama
        var kode_buyer_lama
        var sales_lama
        var cabang_lama
        var free_of_payment_lama
        var ten = 0
        var nama_saham_lama
        var no_surat_konfirmasi_baru
        var tenorarray = items;
        var x = 0;
        let counter = 0;
        alert('Import request is in the process');

        for (var i = 0; i < items.length; i++) {

            //var no_surat = items[i].no_surat_konfirmasi
            // var nomorsurat = no_surat.split("/");
            // no_surat_konfirmasi_baru = nomorsurat[0] + "-I" + "/" + nomorsurat[1] + "/" + nomorsurat[2] + "/" + nomorsurat[3]
            try {
                dataProvider.getList('gmra-maturity?no_surat_konfirmasi=' + items[i].no_surat_konfirmasi, { limit: 1 }).then(response => {
                    // console.log("RESPONSE", tenorarray);
                    console.log(rollover)
                    var kseisingleid = response.data[0].KSEISingleID;

                    // console.log(tenorarray[x])
                    //  

                    // console.log("tenor5", i)

                    var rid = response.data[0].id;
                    no_surat_konfirmasi_lama = response.data[0].no_surat_konfirmasi;
                    var ink = tenorarray.findIndex(z => z.no_surat_konfirmasi == no_surat_konfirmasi_lama)
                    if (rollover == 1) { //REDEEM

                    }
                    else {
                        tenor = tenorarray[ink].Tenor;

                    }
                    //      console.log(ink)

                    var nomorsurat = no_surat_konfirmasi_lama.split("/");
                    var no_surat_r
                    if (nomorsurat[0].includes("-")) {
                        no_surat_r = romanToInt(nomorsurat[0].split("-")[1]);
                        no_surat_r++;
                    }
                    else { no_surat_r = 1 }
                    no_surat_konfirmasi_baru = nomorsurat[0].split("-")[0] + "-" + romanize(no_surat_r) + "/" + nomorsurat[1] + "/" + nomorsurat[2] + "/" + nomorsurat[3]
                    nominal_repo_lama = response.data[0].nominal_repo;
                    kode_saham_lama = response.data[0].kode_saham;
                    harga_beli_lama = response.data[0].harga_beli;
                    jumlah_lama = response.data[0].jumlah;
                    nominal_beli_lama = response.data[0].nominal_beli;
                    total_beli_lama = response.data[0].total_beli;
                    fee_beli_lama = response.data[0].fee_beli;
                    net_ac_lama = response.data[0].net_ac;
                    tenor_lama = response.data[0].tenor;
                    kode_nasabah_lama = response.data[0].kode_nasabah;
                    penyelesaian_transaksi_lama = response.data[0].penyelesaian_transaksi;
                    tanggal_settlement_lama = response.data[0].tanggal_settlement;
                    tanggal_jatuh_tempo_lama = response.data[0].tanggal_jatuh_tempo;
                    tingkat_bunga_lama = response.data[0].tingkat_bunga;
                    jumlah_hari_bunga_lama = response.data[0].jumlah_hari_bunga;
                    harga_buyback_lama = response.data[0].harga_buyback;
                    harga_penjualan_kembali_lama = response.data[0].harga_penjualan_kembali
                    fee_buyback_lama = response.data[0].fee_buyback;
                    nilai_investasi_bersih_lama = response.data[0].nilai_investasi_bersih
                    pricing_rate_gross_lama = response.data[0].pricing_rate_gross;
                    pricing_rate_net_lama = response.data[0].pricing_rate_gross;
                    kode_buyer_lama = response.data[0].kode_buyer;
                    sales_lama = response.data[0].sales;
                    cabang_lama = response.data[0].cabang
                    free_of_payment_lama = response.data[0].free_of_payment
                    nama_saham_lama = response.data[0].nama_saham
                    var processed = response.data[0].processed_rollover;
                    var mature = response.data[0].mature

                    if (rollover == 1) { //REDEEM
                        // console.log("RID", rid)
                        dataProvider.update('gmra-trans', {
                            id: rid,
                            data: { redeem: 1, processed_rollover: 1, document_redeem_generated: 0 }
                        }).then(() => {
                            counter++;
                            if (counter === items.length - 1) {
                                alert(`Import request ${totalData} data is successfull`);
                            }
                        });
                    }
                    else {

                        if (processed == 1 || mature != 1 || tenor == null) {

                        }
                        else {
                            noSurat++;
                            noorder++;
                            var hargaloop = 0
                            var tb12 = 0;
                            var tb6 = 0;
                            hargaloop = harga[response.data[0].kode_saham];
                            tb12 = bunga12[response.data[0].kode_saham]
                            tb6 = bunga6[response.data[0].kode_saham];
                            var imbal_hasil = nilai_investasi_bersih_lama - nominal_beli_lama;
                            var jumlah_shm_dijual = rollover == 2 ? (Math.ceil((imbal_hasil / (1 - (0.2 / 100)) / harga_buyback_lama))) : 0;
                            var harga_pasar_after_margin = Math.ceil(hargaloop / 1.6)
                            var lmbr_saham_jaminan = jumlah_lama - jumlah_shm_dijual;
                            var nilai_saham_jaminan = rollover == 2 ? nominal_beli_lama : nilai_investasi_bersih_lama
                            var harga_pasar = hargaloop;
                            var nilai_pasar_saham_jaminan = lmbr_saham_jaminan * harga_pasar
                            nominal_repo = rollover == 2 ? nominal_beli_lama : nilai_investasi_bersih_lama;

                            var rasio_marjin = 0
                            var rasio_marjin_flag = 0
                            var sesuai = 0;

                            try {
                                rasio_marjin = (1 + ((nilai_pasar_saham_jaminan - nilai_saham_jaminan) / nilai_saham_jaminan));

                            }
                            catch (e) {
                                rasio_marjin = 0;

                            }

                            if (rasio_marjin > max_rasio) {
                                rasio_marjin_flag = 1
                                sesuai = 2; //DEDUCT
                            }
                            else if (rasio_marjin < 1.6) {
                                rasio_marjin_flag = 1
                                sesuai = 1 //TOP UP
                            }
                            else {
                                rasio_marjin_flag = 3
                                sesuai = 0; //MARGIN SAFE
                            }


                            sales = sales_lama
                            cabang = cabang_lama
                            kode_buyer = kode_buyer_lama
                            kode_nasabah = kode_nasabah_lama
                            tipe_nasabah = 1

                            var found = 0;
                            var jumlah_lembar_saham_jaminan_yang_disesuaikan = sesuai == 1 ? Math.floor(nilai_saham_jaminan / harga_pasar_after_margin) - lmbr_saham_jaminan : sesuai == 2 ? lmbr_saham_jaminan - Math.floor(nilai_saham_jaminan / harga_pasar_after_margin) : 0

                            nominal_beli = nominal_repo
                            var jumlah_saham_disesuaikan = 0
                            if (rasio_marjin_flag != 3) {
                                jumlah_saham_disesuaikan = Math.ceil(Math.abs(nilai_pasar_saham_jaminan - nilai_saham_jaminan) / (hargaloop))
                            }

                            jumlahSahamDiblokir = sesuai == 1 ? jumlah_lembar_saham_jaminan_yang_disesuaikan + lmbr_saham_jaminan : lmbr_saham_jaminan - jumlah_lembar_saham_jaminan_yang_disesuaikan


                            nominal_beli = nominal_repo

                            var net_ac = response.data[0].net_ac;

                            tingkat_bunga = (tenor == 12) ? tb12 : tb6;

                            // harga_penjualan_kembali = (nominal_beli * (1 + (tingkat_bunga * hari_bunga / 365)))
                            // console.log("hari_bunga", hari_bunga)
                            // console.log("tingkat_bunga", tingkat_bunga)
                            // console.log("tenor", tenor)

                            fromdate = tanggal_jatuh_tempo_lama
                            from = new Date(fromdate)

                            var todate = calculateJatuhTempo(from, tenor)
                            //  console.log("TODATE", todate)
                            var d, m, y, ds;
                            d = `${todate.getDate()}`.padStart(2, '0');
                            m = `${todate.getMonth() + 1}`.padStart(2, '0');
                            y = todate.getFullYear();
                            tojatuh = [y, m, d].join('-');
                            var Difference_In_Time = todate.getTime() - from.getTime();


                            var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

                            working_days = Difference_In_Days;
                            // holiday = holidays;

                            //harga_buyback_perlembar = Math.ceil((nilai_saham_jaminan * (1 + bungax * (jumlah_hari_bunga / 365)) / (1 - 0.002) / jumlahSahamDiblokir))

                            harga_buyback = Math.round(Math.ceil((((nilai_saham_jaminan * (1 + tingkat_bunga * (working_days / 365)))) / (1 - 0.002)) / jumlahSahamDiblokir));
                            console.log("Math.ceil((((" + nilai_saham_jaminan + "* (1 +" + tingkat_bunga + "* (" + working_days + "/ 365)))) / (1 - 0.002)) / " + jumlahSahamDiblokir + ");")
                            harga_penjualan_kembali = harga_buyback * jumlahSahamDiblokir;
                            nominal_bayar = nominal_beli;
                            feeBuyback = 0.2 / 100 * harga_penjualan_kembali;

                            harga_penjualan_kembali = Math.round(harga_penjualan_kembali);
                            nilai_investasi_bersih = harga_penjualan_kembali - feeBuyback;
                            if (nilai_investasi_bersih % 1 == 0.5) {
                                nilai_investasi_bersih += 0.1
                            }

                            nilai_investasi_bersih = Math.round(nilai_investasi_bersih);
                            // console.log(" nilai_investasi_bersih", nilai_investasi_bersih)
                            // console.log("feeBuyback", feeBuyback)
                            pricing_rate_g = Math.round(((((harga_penjualan_kembali / nominal_beli) - 1) * 365 / working_days)) * 10000) / 10000;
                            pricing_rate_n = Math.round((((nilai_investasi_bersih / nominal_beli) - 1) * 365 / working_days) * 10000) / 10000;

                            net_interest = (nominal_beli * tingkat_bunga * working_days / 365) + (nominal_bayar - nominal_beli);
                            var today = new Date();
                            var todaystring = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

                            var from = new Date(fromdate);
                            from.setDate(from.getDate() + 1)
                            // var tostext = [from.getFullYear(), from.getMonth() - 1, from.getDate() + 1].join('/');

                            var yeardoc = from.getFullYear();
                            var monthdoc = from.getMonth();
                            var tomo = ("" + (from.getMonth() + 1)).padStart(2, 0)
                            var monthromawi = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"]
                            var settlement = from
                            settlement.setDate(settlement.getDate() + 1)

                            const dayset = `${settlement.getDate()}`.padStart(2, '0');
                            const monthset = `${settlement.getMonth() + 1}`.padStart(2, '0');
                            const yearset = settlement.getFullYear();
                            var tostext = yearset + "-" + monthset + "-" + dayset;
                            // console.log("settlement_date", tostext)
                            var noDocument = noSurat.toString().padStart(3, '0') + "/SMS-GMRA/" + monthromawi[monthdoc] + "/" + yeardoc;
                            // console.log("NOMINALBELI", nominal_beli);
                            var username = localStorage.getItem('username')
                            var hash = md5(new Date().toString() + noDocument);

                            var createdate = new Date().toString();
                            var feeBuyback = Math.ceil(Math.abs(nilai_investasi_bersih - harga_penjualan_kembali));
                            if (rollover == 2) {
                                dataProvider.create('gmra-trans', {
                                    data: {
                                        "kode_nasabah": kode_nasabah_lama,
                                        "nominal_repo": nominal_repo,
                                        "kode_saham": kode_saham_lama,
                                        "harga_beli": hargaloop,
                                        "jumlah": jumlahSahamDiblokir,
                                        "nominal_beli": nominal_beli,
                                        "total_beli": nominal_bayar,
                                        "fee_beli": 0,
                                        "net_ac": net_ac,
                                        "tanggal_efektif": fromdate,
                                        "tenor": tenor,
                                        "penyelesaian_transaksi": "T+2",
                                        "tanggal_settlement": tostext,
                                        "tanggal_jatuh_tempo": tojatuh,
                                        "tingkat_bunga": tingkat_bunga,
                                        "jumlah_hari_bunga": working_days,
                                        "harga_buyback": harga_buyback,
                                        "harga_penjualan_kembali": harga_penjualan_kembali,
                                        "fee_buyback": feeBuyback,
                                        "nilai_investasi_bersih": nilai_investasi_bersih,
                                        "pricing_rate_gross": pricing_rate_g,
                                        "pricing_rate_net": pricing_rate_n,
                                        "kode_buyer": kode_buyer,
                                        "sales": sales,
                                        "cabang": cabang,
                                        "free_of_payment": "-",
                                        "no_surat_konfirmasi": no_surat_konfirmasi_baru,
                                        "security_hash": hash,
                                        "tipe_nasabah": tipe_nasabah,
                                        "tipe_transaksi": 2,
                                        "tanggal_pembuatan": todaystring,
                                        "CreatedBy": username,
                                        "CreatedDate": todaystring,
                                        "active": 1,
                                        "order_number": noorder,
                                        "nama_saham": nama_saham_lama,
                                        "rollover": 1,
                                        "interest": 0,
                                        "rollover_id": rid,
                                        "penyesuaian": sesuai,
                                        "KSEISingleID": kseisingleid,
                                        "min_rasio": '1.6',
                                        "max_rasio": '1.7'
                                    }
                                }).then(() => {
                                    //   console.log(response); 
                                    counter++;
                                    if (counter === items.length - 1) {
                                        alert(`Import request ${totalData} data is successfull`);
                                    }
                                });

                                dataProvider.update('gmra-trans', {
                                    id: rid,
                                    data: { processed_rollover: 1 }
                                })
                            }
                            else {
                                dataProvider.create('gmra-trans', {
                                    data: {
                                        "kode_nasabah": kode_nasabah_lama,
                                        "nominal_repo": nominal_repo,
                                        "kode_saham": kode_saham_lama,
                                        "harga_beli": hargaloop,
                                        "jumlah": jumlahSahamDiblokir,
                                        "nominal_beli": nominal_beli,
                                        "total_beli": nominal_bayar,
                                        "fee_beli": 0,
                                        "net_ac": net_ac,
                                        "tanggal_efektif": fromdate,
                                        "tenor": tenor,
                                        "penyelesaian_transaksi": "T+2",
                                        "tanggal_settlement": tostext,
                                        "tanggal_jatuh_tempo": tojatuh,
                                        "tingkat_bunga": tingkat_bunga,
                                        "jumlah_hari_bunga": working_days,
                                        "harga_buyback": harga_buyback,
                                        "harga_penjualan_kembali": harga_penjualan_kembali,
                                        "fee_buyback": feeBuyback,
                                        "nilai_investasi_bersih": nilai_investasi_bersih,
                                        "pricing_rate_gross": pricing_rate_g,
                                        "pricing_rate_net": pricing_rate_n,
                                        "kode_buyer": kode_buyer,
                                        "sales": sales,
                                        "cabang": cabang,
                                        "free_of_payment": "-",
                                        "no_surat_konfirmasi": no_surat_konfirmasi_baru,
                                        "security_hash": hash,
                                        "tipe_nasabah": tipe_nasabah,
                                        "tipe_transaksi": 3,
                                        "tanggal_pembuatan": todaystring,
                                        "CreatedBy": username,
                                        "CreatedDate": todaystring,
                                        "active": 1,
                                        "order_number": noorder,
                                        "nama_saham": nama_saham_lama,
                                        "rollover": 1,
                                        "interest": 1,
                                        "rollover_id": rid,
                                        "penyesuaian": sesuai,
                                        "KSEISingleID": kseisingleid,
                                        "min_rasio": '1.6',
                                        "max_rasio": '1.7'
                                    }
                                }).then(() => {
                                    //console.log(response);
                                    counter++;
                                    if (counter === items.length - 1) {
                                        alert(`Import request ${totalData} data is successfull`);
                                    }
                                });

                                dataProvider.update('gmra-trans', {
                                    id: rid,
                                    data: { processed_rollover: 1 }
                                })


                            }


                        }
                    }
                });
            }
            catch (e) {
                console.log(e)
            }




        }
        // setCountDocument(noSurat);
        setOpen(false);
        refresh();
    }

    function postData(jsondata) {
        //console.log(jsondata);
        JSON.stringify(jsondata)
        dataProvider.create('gmra-trans', {
            data: {
                jsondata
            }
        }).then(response => { setOpen(false); });
    }

    const countDocument = () => {
        var doc = [];
        var te = new Date();
        var todaymonth = te.getMonth() + 1;
        var todayyear = te.getFullYear();
        dataProvider.getOne('gmra-trans-number?month=' + todaymonth + '&year=' + todayyear, { id: 1 }).then(response => {
            //  console.log(response);
            setCountDocument(response.data[0].nomor);
            setOrder(response.data[0].total);
            setRB(response.data[0].rb);
            setRC(response.data[0].rc)

            var nomordok = parseInt(response.data[0].nomor) + 1;
            try {
                setDocumentList(response)

            } catch (e) {
                alert("Mohon isi Tanggal Efektif terlebih dahulu.")
                console.log(e);
            }
        });


    }

    function checkHoliday(date) {

        const day = `${date.getDate()}`.padStart(2, '0');
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const year = date.getFullYear();

        var datum = year + "-" + (month) + "-" + day;

        return holidays.includes(datum)
    }

    function formatDate(date) {
        var d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        //console.log([year, month, day].join('-'));
        return [year, month, day].join('-');
    }

    let workingDaysBetweenDates = (d0, d1, holiday) => {
        /* Two working days and an sunday (not working day) */
        var hd = holiday;
        // console.log("COUNTING");
        //  console.log(hd);
        var startDate = parseDate(d0);
        var endDate = parseDate(d1);

        // Validate input
        if (endDate <= startDate) {
            return 0;
        }

        // Calculate days between dates
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        startDate.setHours(0, 0, 0, 1);  // Start just after midnight
        endDate.setHours(23, 59, 59, 999);  // End just before midnight
        var diff = endDate - startDate;  // Milliseconds between datetime objects    
        var days = Math.ceil(diff / millisecondsPerDay);

        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days -= weeks * 2;

        // Handle special cases
        var startDay = startDate.getDay();
        var endDay = endDate.getDay();

        // Remove weekend not previously removed.   
        if (startDay - endDay > 1) {
            days -= 2;
        }
        // Remove start day if span starts on Sunday but ends before Saturday
        if (startDay == 0 && endDay != 6) {
            days--;
        }
        // Remove end day if span ends on Saturday but starts after Sunday
        if (endDay == 6 && startDay != 0) {
            days--;
        }
        /* Here is the code */
        hd.forEach(day => {
            if ((day >= d0) && (day <= d1)) {
                /* If it is not saturday (6) or sunday (0), substract it */
                if ((parseDate(day).getDay() % 6) != 0) {
                    days--;
                }
            }
        });
        return days;
    }

    function parseDate(input) {
        // Transform date from text to date
        var parts = input.match(/(\d+)/g);
        // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }




    useEffect(() => {


        var date;
        var holiday;
        var from = new Date()

        var d = (from.getDate() < 10 ? '0' : '') + from.getDate();

        var m = ((from.getMonth() + 1) < 10 ? '0' : '') + (from.getMonth() + 1);

        var y = from.getFullYear() + 2;
        var todate = new Date(y, m, d);
        var to = [y, m, d].join('-');
        dataProvider.getList('holiday?from=' + formatDate(from) + '&to=' + to + '', { limit: 100 })
            .then(({ data }) => {
                date = [];
                holiday = data[0].Date;
                for (var x = 0; x < holiday.length; x++) {
                    date.push(holiday[x].Date.split("T")[0]);
                }
                console.log(date)
                setHoliday(date);
            });

        countDocument();
    }, []);

    useEffect(() => {
        dataProvider.getManyReference('gmra-harga?active=1', {
            limit: 1
        })
            .then(({ data }) => {
                console.log("HARGA", data);
                setData(data);
                var arrHarga = [];
                var arrBunga6 = [];
                var arrBunga12 = [];
                var arrNamaSaham = [];

                for (var y = 0; y < data.length; y++) {
                    arrHarga[data[y].kode_saham] = data[y].harga_beli
                    arrBunga6[data[y].kode_saham] = data[y].tingkat_bunga6
                    arrBunga12[data[y].kode_saham] = data[y].tingkat_bunga12
                    arrNamaSaham[data[y].kode_saham] = data[y].nama_saham
                }
                // console.log(data[data.length - 1].harga_beli)
                setNama(arrNamaSaham);
                setHarga(arrHarga);
                setBunga6(arrBunga6);
                setBunga12(arrBunga12);

                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })

        dataProvider.getList('gmra-threshold?&active=1', { limit: 1 }).then(({ data }) => {
            try {

                setThreshold(data[0].threshold / 100)
                setMinRasio(data[0].min_rasio / 100);
                setMaxRasio(data[0].max_rasio / 100);

            }
            catch (e) {
                console.log(e)
            }
        });
    }, [items, kode_saham]);

    const handleConfirm = () => {

        setOpen(false);
    };

    const handleChange = (e) => {
        setSubmitDisableButton(true)
        setRollover(e.target.value)

    }

    useEffect(() => {
        setSubmitDisableButton(false)
    }, [rollover])

    const readExcel = (file) => {
        console.log(file)
        if (file.name.split('.')[1] == "xls" || file.name.split('.')[1] == "xlsx") {
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;

                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws, { raw: false });
                    console.log(data);
                    resolve(data);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };
            });

            promise.then((d) => {
                setItems(d);
            });
        }
        else {
            alert("Import Error")

        }
    };

    return (
        <Fragment>
            <Button label="Import" onClick={handleClick} />
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Do you want to import excel data?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <label>Action</label>
                        <select onChange={(e) => handleChange(e)} >
                            <option value={1}>Redemption</option>
                            <option value={2}>Rollover Principal</option>
                            <option value={3}>Rollover Principal + Interest</option>

                        </select>
                        {/* <input
                            type="date"
                            onChange={(value) => {
                                console.log(value.target.value);
                                setTglEfektif(value.target.value);
                            }}
                        /><br /> */}

                        <input
                            type="file"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file.name.split('.')[1] == 'xlsx' || file.name.split('.')[1] == 'xls') { readExcel(file); } else {
                                    alert("File Format Error")
                                    e.target.value = null;
                                }
                                //

                            }}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={handleDialogClose} color="primary" >
                        Cancel
                    </Buttons>
                    <Buttons onClick={processDataExcel} color="primary" disabled={submitbutton} autoFocus>
                        Confirm
                    </Buttons>
                </DialogActions>
            </Dialog>

        </Fragment>
    );
}

export default ImportRollover;