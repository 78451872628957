import React, { useState } from 'react';
import {
    List,
    Datagrid,
    TopToolbar,
    TextField,
    DateField,
    TextInput,
    FilterButton,
    useRecordContext,
    DateInput,CheckboxGroupInput

} from 'react-admin';

import ThousandField from '../field/ThousandField';
import { makeStyles } from '@material-ui/core/styles';
import ImportRollover from './ImportRollover';

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});

const postFilters = [
    <DateInput label="Tanggal Efektif" source="tanggal_efektif" />,
    <DateInput label="Tanggal Jatuh Tempo" source="tanggal_jatuh_tempo" />,
    <TextInput label="Kode Nasabah" source="kode_nasabah" />,
    <TextInput label="No Surat Konfirmasi" source="no_surat_konfirmasi" />,
    <TextInput label="Sales" source="sales" />,
    <CheckboxGroupInput source="tipe_transaksi" choices={[
        { id: '0', name: 'New Subscription' },
        { id: '1', name: 'Redeemed' },
        { id: '2', name: 'Rollover Principal' },
        { id: '3', name: 'Rollover Principal + Interest' },
    ]} />
];
const choice = [
    { id: 1, value: "1" },
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 4, value: "4" },
]


function ListActions(props) {


    return (
        <TopToolbar>
            <FilterButton />
            <ImportRollover />
        </TopToolbar>)
}
const MaturityButton = (props) => {
    const record = useRecordContext(props);
    // const link = '/#/gmra-maturity/create?source={"id":"'+record.id+'","kode_nasabah":"'+record.kode_nasabah+'","nominal_repo":"'+record.nominal_repo+'","tenor":"'+record.tenor+'","tanggal_efektif":"'+record.tanggal_efektif+'","kode_saham":"'+record.kode_saham+'","kode_buyer":"'+record.kode_buyer+'","sales":"'+record.sales+'","no_surat_konfirmasi":"'+record.no_surat_konfirmasi+'"}'
    const link = '/#/gmra-maturity/create?source={"rollover_id":"' + record.id + '"}'
    const linkshow = '/#/gmra-maturity/' + record.processed_id + '/show'
    const linkshowredeem = '/#/gmra-maturity/' + record.id + ''
    if (record.processed_rollover == null) {
        return (<a href={link} style={{ whiteSpace: 'nowrap' }}>Process Maturity</a>)
    }
    else {
        if (record.redeem == 1) {
            return (<div> <a href={linkshowredeem} style={{ whiteSpace: 'nowrap' }}>Redeemed</a> </div>)
        }
        else {
            return (<div> <a href={linkshow} style={{ whiteSpace: 'nowrap' }}>Rolled over</a> </div>)
        }
    }
}


const RedeemButton = (props) => {
    const record = useRecordContext(props);
    // const link = '/#/gmra-maturity/create?source={"id":"'+record.id+'","kode_nasabah":"'+record.kode_nasabah+'","nominal_repo":"'+record.nominal_repo+'","tenor":"'+record.tenor+'","tanggal_efektif":"'+record.tanggal_efektif+'","kode_saham":"'+record.kode_saham+'","kode_buyer":"'+record.kode_buyer+'","sales":"'+record.sales+'","no_surat_konfirmasi":"'+record.no_surat_konfirmasi+'"}'
    const link = '/#/gmra-maturity/' + record.id
    if (record.processed_rollover == null) {
        return (<a href={link} >Redemption</a>)
    }
    else { return (<div></div>) }


}
export function MaturityList(props) {
    const [modalIsOpen, showModal] = useState(false);
    const classes = useStyles();



    const TipeTransaksi = (props) => {
        const record = useRecordContext(props);
        if (record.tipe_transaksi == 0) {
            return <span className={classes.text}>New Subcription</span>
        }
        else if (record.tipe_transaksi == 1) {
            return <span className={classes.text}>Redemption</span>
        }
        else if (record.tipe_transaksi == 2) {
            return <span className={classes.text}>Rollover Principal</span>
        }
        else if (record.tipe_transaksi == 3) {
            return <span className={classes.text}>Rollover Principal + Interest</span>
        }
    }



    const ShowDocument = (props) => {
        const record = useRecordContext(props);
        var link = "";
        if (record.tipe_transaksi == 0) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 1) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 2) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 3) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }
        return (<a href={link} style={{ whiteSpace: 'nowrap' }} >{record.no_surat_konfirmasi}</a>)
    }
    return (
        <List {...props} filters={postFilters} sort={{ field: 'processed_rollover', order: 'ASC' }} filter={{ active: 1, mature: 1 }} actions={<ListActions onClick={() => showModal(true)} />} bulkActionButtons={false} >
            <Datagrid>
                <TextField source="order_number" label="No." cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <ShowDocument source="no_surat_konfirmasi" label="No Surat Konfirmasi"></ShowDocument>
                <TextField source="kode_nasabah" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="cabang" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="sales" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_saham" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <ThousandField source="nominal_beli" />
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <DateField source="tanggal_jatuh_tempo" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TipeTransaksi label="Tipe Transaksi" ></TipeTransaksi>
                <MaturityButton />
                <RedeemButton />
            </Datagrid>
        </List>
    )
}