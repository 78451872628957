import feathers from 'feathers-client';

//const host = 'https://gmra.siminvest.co.id/api/v1';

var host = ''
host = 'http://172.16.115.49:3030';

if (window.location.origin == 'https://gmra.siminvest.co.id') {
    host = 'https://gmra.siminvest.co.id/api/v1';
}
else if(window.location.origin == 'https://gmra.sinarmascakrawala.co.id'){
    host = 'https://gmra.sinarmascakrawala.co.id/api/v1';
}
else if(window.location.origin == 'https://gmra.nirmalataruna.co.id'){
    host = 'https://gmra.nirmalataruna.co.id/api/v1';
}
else if (window.location.origin == 'https://gmra.shintautama.co.id') {
    host = 'https://gmra.shintautama.co.id/api/v1';
}
//host = 'http://172.17.26.51:3030';
export default feathers()
    .configure(feathers.hooks())
    .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
    .configure(feathers.authentication({ storage: localStorage }));
