import React, { useState, useContext } from 'react';
import { Fragment } from 'react';
import ActionDelete from '@material-ui/icons/Delete';

import {
    List,
    Datagrid,
  
    TopToolbar,
    TextField,
    DataProviderContext,
    DateField,
    DateInput,
    TextInput,
    BulkUpdateWithConfirmButton, FilterButton, useRecordContext, BulkDeleteWithConfirmButton
} from 'react-admin';
import ThousandField from '../field/ThousandField';


import { makeStyles } from '@material-ui/core/styles';

import {
    useRefresh,
    useUnselectAll,
    useResourceContext,
} from 'ra-core';

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});
const postFilters = [
    <DateInput label="Tanggal Efektif" source="tanggal_efektif" />,
    <DateInput label="Tanggal Jatuh Tempo" source="tanggal_jatuh_tempo" />,
    <TextInput label="Kode Nasabah" source="kode_nasabah" />,
    <TextInput label="No Surat Konfirmasi" source="no_surat_konfirmasi" />,
    <TextInput label="Sales" source="sales" />
];
const choice = [
    { id: 1, value: "1" },
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 4, value: "4" },
]
function ListActions(props) {

    function handleClick(event) {
        // Here, we invoke the callback with the new value
        props.onClick(event.target.value);
    }
    return (
        <TopToolbar>
            <FilterButton />
        </TopToolbar>)
}




export function ATransactionList(props) {
    const [modalIsOpen, showModal] = useState(false);
    const classes = useStyles();
    const dataProvider = useContext(DataProviderContext);

    const TipeTransaksi = (props) => {
        const record = useRecordContext(props);

        if (record.tipe_transaksi == 0) {
            return <span className={classes.text}>New Subcription</span>
        }
        else if (record.tipe_transaksi == 1) {
            return <span className={classes.text}>Redemption</span>
        }
        else if (record.tipe_transaksi == 2) {
            return <span className={classes.text}>Rollover Principal</span>
        }
        else if (record.tipe_transaksi == 3) {
            return <span className={classes.text}>Rollover Principal + Interest</span>
        }

    }

    const PostBulkActionButtons = props => {
        console.log("BULK", props)
        const refresh = useRefresh();
        const unselectAll = useUnselectAll();
        const resource = useResourceContext(props);
        const views = { active: 0, DeletedBy: localStorage.getItem('username'), DeletedDate: new Date() };
        return (
            <Fragment>
                {/* <BulkUpdateWithConfirmButton {...props} data={views} label="Delete" icon={<ActionDelete />} onSuccess={() => {
                    refresh();
                    unselectAll(resource);
                }} /> */}
                  <BulkDeleteWithConfirmButton {...props} data={views} label="Delete" icon={<ActionDelete />} onSuccess={() => {
                    refresh();
                    unselectAll(resource);
                }} />
            </Fragment>
        )
    };

    const ShowDocument = (props) => {
        const record = useRecordContext(props);
        var link = "";
        if (record.tipe_transaksi == 0) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 1) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 2) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 3) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }

        return (<a href={link} >{record.no_surat_konfirmasi}</a>)
    }
    return (
        <List {...props} filters={postFilters} actions={<ListActions />} bulkActionButtons={<PostBulkActionButtons />} filter={{ active: 1 }} >
            <Datagrid rowClick="edit">
                <TextField source="order_number" label="No." cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <ShowDocument source="no_surat_konfirmasi" label="No Surat Konfirmasi"></ShowDocument>
                <TextField source="transaction_code" label="Transaction Code" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_nasabah" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="cabang" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="sales" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_saham" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                {/* <NumberField source="nominal_beli" options={{ style: 'currency', currency: 'IDR' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center"/> */}
                {/* <NumberField source="nominal_beli" options={{ style: 'currency', currency: 'IDR' }} locales="fr-FR" /> */}
                <ThousandField source="nominal_beli" />
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'long', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <DateField source="tanggal_jatuh_tempo" options={{ year: 'numeric', month: 'long', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                {/* <TipeTransaksi label="Action"></TipeTransaksi> */}
            </Datagrid>
        </List>
    )
}