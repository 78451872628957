import React, { Fragment, useState, useContext, useEffect } from 'react';
import {
    Button,
    useRefresh
} from 'react-admin';
import Buttons from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataProviderContext } from 'react-admin';
import ReactDropdown, { Dropdown } from 'react-dropdown'
import _, { map, intersection } from 'underscore';


const MergeButton = () => {
    const [open, setOpen] = useState(false);
    const [transaksi, setTransaksi] = useState(1)
    const [dateused, setDateUsed] = useState("TE")
    const [docType, setType] = useState(0)
    const [date, setDate] = useState();
    const [isDisable, setIsDisable] = useState(false);
    const [check, setCheck] = useState(false);
    const [idfrom, setFrom] = useState(0);
    const [idto, setTo] = useState(0);
    const [idcollection, setIDCollection] = useState([]);
    const [isCheckedNew, setCheckNew] = useState(false);
    const [isCheckedRed, setCheckRed] = useState(false);
    const [isCheckedRP, setCheckRP] = useState(false);
    const [isCheckedRPI, setCheckRPI] = useState(false);
    const [isCheckedMR, setCheckMR] = useState(false);
    const [jenisDoc, setJenisDoc] = useState([]);
    const refresh = useRefresh();
    const dataProvider = useContext(DataProviderContext);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const mergeDoc = () => mergeDocPress();


    useEffect(() => { getIDPrint() }, [date, docType])
    function getIDPrint() {

        dataProvider.getList('gmra-print-id?tanggal_efektif=' + date + '', { limit: 1 })
            .then(({ data }) => {
                var id = [];
                var docid = data[0].id;
                for (var x = 0; x < docid.length; x++) {
                    try {
                        console.log(docid[x].id)
                        id.push(docid[x].id);
                    }
                    catch (e) {

                    }
                }

                setIDCollection(id);
            })

    }

    function mergeDocPress() {

        if (date == undefined) {
            alert("Please input effective date");
            return;
        }
        var news = 0;
        var redeem = 0;
        var rollover = 0;
        var interest = 0;
        var margin = 0;
        if (docType == 0) {
            alert("Please select a type of document to merged")
        }
        else {
            // if (transaksi == 1) { //New Sub
            //     redeem = 0;
            //     rollover = 0;
            //     interest = 0;
            //     margin = 0;
            // }
            // else if (transaksi == 2) { //Rollover
            //     redeem = 0;
            //     rollover = 1;
            //     interest = 0;
            //     margin = 0;
            // }
            // else if (transaksi == 3) { //Rollover + Interest
            //     redeem = 0;
            //     rollover = 0;
            //     interest = 1;
            //     margin = 0;
            // }
            // else if (transaksi == 4) { //Redeem
            //     redeem = 1;
            //     rollover = 0;
            //     interest = 0;
            //     margin = 0;
            // }
            // else if (transaksi == 5) {//Rasio Margin
            //     redeem = 0;
            //     rollover = 0;
            //     interest = 0;
            //     margin = 1;
            // }
            var tipe_transaksi = [];
            if (isCheckedNew) {
                news = 1
                tipe_transaksi.push('0')
            }
            if (isCheckedRed) {
                redeem = 1
                tipe_transaksi.push('1')
            }
            if (isCheckedRP) {
                rollover = 1
                tipe_transaksi.push('2')
            }
            if (isCheckedRPI) {
                interest = 1
                tipe_transaksi.push('3')
            }
            if (isCheckedMR) {
                margin = 1;
            }

            var stringtipetransaksi = '';
            for (var z = 0; z < tipe_transaksi.length; z++) {

                if (z == tipe_transaksi.length - 1) {
                    stringtipetransaksi += '' + tipe_transaksi[z] + ''
                }
                else {
                    stringtipetransaksi += '' + tipe_transaksi[z] + ','
                }
            }
            if (check) {

                if (idfrom == 0 || idto == 0) {
                    alert("Please enter From field and To Field")

                }
                else {
                    var loop = "";
                    for (var x = idfrom.value; x <= idto.value; x++) {
                        if (x == idto.value) {
                            loop += x;
                        }
                        else {
                            loop += x + ",";
                        }
                    }
                    if (date != undefined) {
                        dataProvider.create('gmra-print', {
                            data: {
                                date_used: dateused,
                                tanggal_efektif: date,
                                document_generated: 0,
                                document_type: docType,
                                printid: loop,
                                redeem: redeem,
                                rollover: rollover,
                                interest: interest,
                                margin: margin,
                                news:news,
                                tipe_transaksi: stringtipetransaksi
                            }
                        })
                    }
                }
            }
            else {
                if (date != undefined) {
                    dataProvider.create('gmra-print', {
                        data: {
                            date_used: dateused,
                            tanggal_efektif: date,
                            document_generated: 0,
                            document_type: docType,
                            flag: 0,
                            news:news,
                            redeem: redeem,
                            rollover: rollover,
                            interest: interest,
                            margin: margin,
                            tipe_transaksi: stringtipetransaksi
                        }
                    })
                }
            }
        }
        refresh();
        setTransaksi(1)
        setOpen(false);

    }
    function getDoc(value) {
        setDate(value)
    }
    function handleRadio(src) {
        setType(src)
        // alert(src);
    }
    async function checkHolidayOrWeekend(date) {
        const resp = await dataProvider.getList(`holiday?from=${date}&to=${date}`, { limit: 1 });
        const data = resp[0];

        const selectedDate = new Date(date);
        const isWeekend = (selectedDate.getDay() === 6) || (selectedDate.getDay() === 0);

        if (data.Date.length > 0 || isWeekend) {
            setIsDisable(true);
            alert('Tanggal Efektif tidak dapat diset pada hari libur');
            return
        }
        setIsDisable(false);
    }

    const handleChange = (e) => {
        console.log(e)
        setTransaksi(e.target.value)
    }

    const handleChangec = (e) => {
        console.log(e)
        // setTransaksi(e.target.value)
    }
    const handleChangeDate = (e) => {
        console.log(e.target.value)
        setDateUsed(e.target.value)
    }

    const handleCheck = (e) => {
        console.log(e)
        setCheck(e.target.value);
    }


    function intersection2() {
        var result = [];
        var lists;

        if (arguments.length === 1) {
            lists = arguments[0];
        } else {
            lists = arguments;
        }

        for (var i = 0; i < lists.length; i++) {
            var currentList = lists[i];
            for (var y = 0; y < currentList.length; y++) {
                var currentValue = currentList[y];
                if (result.indexOf(currentValue) === -1) {
                    var existsInAll = true;
                    for (var x = 0; x < lists.length; x++) {
                        if (lists[x].indexOf(currentValue) === -1) {
                            existsInAll = false;
                            break;
                        }
                    }
                    if (existsInAll) {
                        result.push(currentValue);
                    }
                }
            }
        }
        return result;
    }


    useEffect(() => {
        var arrcom = [];
        var combine = [];
        var newsub = [1, 2, 3, 4, 7, 8, 15, 5, 6]
        var rollover = [2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        var rolloveri = [2, 3, 7, 8, 11, 12, 13, 14]
        var redeem = [9, 10]
        var margint = [98, 99, 100]
        if (isCheckedNew) {
            arrcom.concat(newsub)
            arrcom.push(newsub)
            // combine = [...combine, ...newsub]
        }
        if (isCheckedRP) {
            arrcom.concat(rollover)
            arrcom.push(rollover)
            //   combine = [...combine, ...rollover]
        }
        if (isCheckedRPI) {
            arrcom.concat(rolloveri)
            arrcom.push(rolloveri)
            //  combine = [...combine, ...rolloveri]
        }
        if (isCheckedRed) {
            arrcom.concat(redeem)
            arrcom.push(redeem)
            //  combine = [...combine, ...redeem]
        }
        if (isCheckedMR) {
            arrcom.concat(margint)
            arrcom.push(margint)
            //   combine = [...combine, ...margint]
        }
        var result = [];
        result = intersection2(arrcom)
        console.log(result)
        setJenisDoc(result);


    }, [isCheckedMR, isCheckedNew, isCheckedRP, isCheckedRPI, isCheckedRed])

    const DocumentChoice = () => {
        const menudoc = [{ id: 1, name: "GMRA" },
        { id: 2, name: "Instruksi Blokir Saham" },
        { id: 3, name: "Surat Konfirmasi Saham" },
        { id: 4, name: "Surat Konfirmasi Saham Seller " },
        { id: 5, name: "Surat Instruksi Beli Customer DVP-RVP" },
        { id: 6, name: "Surat Instruksi Jual Company DVP-RVP" },
        { id: 7, name: "Surat Cabut Blokir" },
        { id: 8, name: "Surat Pernyataan" },
        { id: 9, name: "Surat Instruksi Jual Customer DVP-RVP" },
        { id: 10, name: "Surat Instruksi Beli Company DVP-RVP" },
        { id: 11, name: "Surat Instruksi Beli Customer FOP " },
        { id: 12, name: "Surat Instruksi Jual Customer FOP" },
        { id: 13, name: "Surat Instruksi Beli Company FOP" },
        { id: 14, name: "Surat Instruksi Jual Company FOP" },
        { id: 15, name: "Surat Kuasa Repo" },
        { id: 97, name: "All" },
        { id: 98, name: "All (Margin)" },
        { id: 99, name: "Surat Instruksi Top Up" },
        { id: 100, name: "Surat Instruksi Deduct" }
        ];
        return (
            <div> <h5>Jenis Dokumen</h5>
                {
                    menudoc.map((obj) => {


                        if (jenisDoc.includes(obj.id)) {
                            return (
                                <>
                                    <input type="radio" name="type" values="97" onChange={() => handleRadio(obj.id)}></input><label> {obj.name}</label> <br></br>
                                </>
                            )
                        }
                    })
                }
            </div>
        );

        // if (transaksi == 1) { // New Sub
        //     return (<>
        //         <input type="radio" name="type" values="97" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
        //         <input type="radio" name="type" values="1" onChange={() => handleRadio(1)}></input><label> &nbsp;GMRA </label> <br></br>
        //         <input type="radio" name="type" values="2" onChange={() => handleRadio(2)}></input><label> &nbsp;Instruksi Blokir Saham </label> <br></br>
        //         <input type="radio" name="type" values="3" onChange={() => handleRadio(3)}></input><label> &nbsp;Surat Konfirmasi Saham </label> <br></br>
        //         <input type="radio" name="type" values="4" onChange={() => handleRadio(4)}></input><label> &nbsp;Surat Konfirmasi Saham Seller </label> <br></br>
        //         <input type="radio" name="type" values="7" onChange={() => handleRadio(7)}></input><label> &nbsp;Surat Cabut Blokir </label> <br></br>
        //         <input type="radio" name="type" values="8" onChange={() => handleRadio(8)}></input><label> &nbsp;Surat Pernyataan </label> <br></br>
        //         <input type="radio" name="type" values="15" onChange={() => handleRadio(15)}></input><label> &nbsp;Surat Kuasa Repo</label> <br></br>
        //         <input type="radio" name="type" values="5" onChange={() => handleRadio(5)}></input><label> &nbsp;Surat Instruksi Beli Customer DVP-RVP</label> <br></br>
        //         <input type="radio" name="type" values="6" onChange={() => handleRadio(6)}></input><label> &nbsp;Surat Instruksi Jual Company DVP-RVP </label> <br></br>
        //     </>)
        // }
        // else if (transaksi == 2) { //Rollover
        //     return (<>
        //         <input type="radio" name="type" values="97" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
        //         <input type="radio" name="type" values="9" onChange={() => handleRadio(9)}></input><label> &nbsp;Surat Instruksi Jual Customer DVP-RVP </label> <br></br>
        //         <input type="radio" name="type" values="10" onChange={() => handleRadio(10)}></input><label> &nbsp;Surat Instruksi Beli Company DVP-RVP</label> <br></br>
        //         <input type="radio" name="type" values="5" onChange={() => handleRadio(5)}></input><label> &nbsp;Surat Instruksi Beli Customer DVP-RVP </label> <br></br>
        //         <input type="radio" name="type" values="6" onChange={() => handleRadio(6)}></input><label> &nbsp;Surat Instruksi Jual Company DVP-RVP </label> <br></br>
        //         <input type="radio" name="type" values="11" onChange={() => handleRadio(11)}></input><label> &nbsp;Surat Instruksi Beli Customer FOP </label> <br></br>
        //         <input type="radio" name="type" values="14" onChange={() => handleRadio(14)}></input><label> &nbsp;Surat Instruksi Jual Company FOP </label> <br></br>
        //         <input type="radio" name="type" values="12" onChange={() => handleRadio(12)}></input><label> &nbsp;Surat Instruksi Jual Customer FOP </label> <br></br>
        //         <input type="radio" name="type" values="13" onChange={() => handleRadio(13)}></input><label> &nbsp;Surat Instruksi Beli Company FOP</label> <br></br>
        //         <input type="radio" name="type" values="2" onChange={() => handleRadio(2)}></input><label> &nbsp;Instruksi Blokir Saham </label> <br></br>
        //         <input type="radio" name="type" values="3" onChange={() => handleRadio(3)}></input><label> &nbsp;Surat Konfirmasi Saham </label> <br></br>
        //         <input type="radio" name="type" values="7" onChange={() => handleRadio(7)}></input><label> &nbsp;Surat Cabut Blokir </label> <br></br>
        //         <input type="radio" name="type" values="8" onChange={() => handleRadio(8)}></input><label> &nbsp;Surat Pernyataan </label> <br></br>
        //     </>)
        // }
        // else if (transaksi == 3) {//Rollover+Interest
        //     return (<>
        //         <input type="radio" name="type" values="9" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
        //         <input type="radio" name="type" values="2" onChange={() => handleRadio(2)}></input><label> &nbsp;Instruksi Blokir Saham </label> <br></br>
        //         <input type="radio" name="type" values="7" onChange={() => handleRadio(7)}></input><label> &nbsp;Surat Cabut Blokir </label> <br></br>
        //         <input type="radio" name="type" values="11" onChange={() => handleRadio(11)}></input><label> &nbsp;Surat Instruksi Beli Customer FOP </label> <br></br>
        //         <input type="radio" name="type" values="14" onChange={() => handleRadio(14)}></input><label> &nbsp;Surat Instruksi Jual Company FOP </label> <br></br>
        //         <input type="radio" name="type" values="12" onChange={() => handleRadio(12)}></input><label> &nbsp;Surat Instruksi Jual Customer FOP </label> <br></br>
        //         <input type="radio" name="type" values="13" onChange={() => handleRadio(13)}></input><label> &nbsp;Surat Instruksi Beli Company FOP</label> <br></br>
        //         <input type="radio" name="type" values="3" onChange={() => handleRadio(3)}></input><label> &nbsp;Surat Konfirmasi Saham </label> <br></br>
        //         <input type="radio" name="type" values="8" onChange={() => handleRadio(8)}></input><label> &nbsp;Surat Pernyataan </label> <br></br>
        //     </>)
        // }
        // else if (transaksi == 4) {//Redeem
        //     return (<>
        //         <input type="radio" name="type" values="9" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
        //         <input type="radio" name="type" values="9" onChange={() => handleRadio(9)}></input><label> &nbsp;Surat Instruksi Jual Customer DVP-RVP </label> <br></br>
        //         <input type="radio" name="type" values="10" onChange={() => handleRadio(10)}></input><label> &nbsp;Surat Instruksi Beli Company DVP-RVP</label> <br></br>
        //     </>)
        // }
        // else if (transaksi == 5) {//Margin
        //     return (<>
        //         <input type="radio" name="type" values="98" onChange={() => handleRadio(98)}></input><label> &nbsp;All</label> <br></br>
        //         <input type="radio" name="type" values="99" onChange={() => handleRadio(99)}></input><label> &nbsp;Surat Instruksi Top Up</label> <br></br>
        //         <input type="radio" name="type" values="100" onChange={() => handleRadio(100)}></input><label> &nbsp;Surat Instruksi Deduct</label> <br></br>
        //     </>)
        // }

        // let data = .map((obj) => obj.id);

    }
    const handleOnChange = () => {
        setCheck(!check);
    }

    const handleOnChangeTo = (value) => {
        setTo(value)
    }
    const handleOnChangeFrom = (value) => {
        setFrom(value)
    }

    const PrintOrder = () => {

        if (check) {
            return (<>
                FROM: <ReactDropdown options={idcollection} value={idfrom} onChange={handleOnChangeFrom}></ReactDropdown>
                TO: <ReactDropdown options={idcollection} value={idto} onChange={handleOnChangeTo}></ReactDropdown>
            </>)
        }
        else {
            return (<></>)
        }
    }

    return (
        <Fragment>
            <Button label="Merge" onClick={handleClick} />
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Insert document date you want to generate"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tanggal yang di gunakan:
                        <select onChange={(e) => handleChangeDate(e)} >
                            <option value={"TE"}>Tanggal Efektif</option>
                            <option value={"TJT"}>Tanggal Jatuh Tempo</option>
                        </select><br />
                        Tanggal :
                        <input
                            type="date"
                            onBlur={async (e) => {
                                const selectedDate = e.target.value;
                                await checkHolidayOrWeekend(selectedDate);
                                getDoc(e.target.value);
                            }}
                        />
                        <br />
                        <br />
                        Tipe Transaksi:<br />
                        <input type="checkbox" id="myCheck" checked={isCheckedNew} onChange={() => setCheckNew((prev) => !prev)} /> <label>New Subscription </label><br />
                        <input type="checkbox" id="myCheck" checked={isCheckedRP} onChange={() => setCheckRP((prev) => !prev)} /> <label>Rollover Principal</label><br />
                        <input type="checkbox" id="myCheck" checked={isCheckedRPI} onChange={() => setCheckRPI((prev) => !prev)} /> <label>Rollover Principal + Interest</label><br />
                        <input type="checkbox" id="myCheck" checked={isCheckedRed} onChange={() => setCheckRed((prev) => !prev)} /> <label>Redemption</label><br />
                        <input type="checkbox" id="myCheck" checked={isCheckedMR} onChange={() => setCheckMR((prev) => !prev)} /> <label>Margin Rasio</label> <br />
                        {/* <select onChange={(e) => handleChange(e)} >
                            <option value={1}>New</option>
                            <option value={2}>Rollover Principal</option>
                            <option value={3}>Rollover Principal + Interest</option>
                            <option value={4}>Redemption</option>
                            <option value={5}>Margin Rasio</option>
                        </select> */}
                        {/* <br />
                        <input
                            type="checkbox"
                            id="topping"
                            name="topping"
                            value="Paneer"
                            checked={check}
                            onChange={handleOnChange}
                        /> Print By Order */}
                        <br />


                    </DialogContentText>

                    <DialogContentText>
                        <DocumentChoice></DocumentChoice>

                    </DialogContentText>
                    <DialogContentText>
                        <PrintOrder></PrintOrder>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={handleDialogClose} color="primary">
                        Cancel
                    </Buttons>
                    <Buttons disabled={isDisable} onClick={mergeDoc} color="primary" autoFocus>
                        Confirm
                    </Buttons>
                </DialogActions>
            </Dialog>

        </Fragment >
    );
}

export default MergeButton;