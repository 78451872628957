import { Form, required, TextInput, Title, SimpleForm, useCreate, useNotify, useRedirect } from 'react-admin';
import { Grid, Paper, Box, Button, Typography, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js'

export const ChangePassword = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [form, appendForm] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: ""
  })

  const handleChange = (e) => {
    appendForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.value,
    });
  }

  console.log(form)

  const submitForm = async () => {

    try {
      const token = localStorage.getItem('feathers-jwt');
      const uid= localStorage.getItem('uid')
     
      const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`  },
        body: JSON.stringify({ currentPass: CryptoJS.AES.encrypt(form.currentPass, '4g3nts3cr3t').toString(), newPass: form.newPass, confirmPass: form.confirmPass, uid:uid })
      };
      const resp = await fetch('http://172.17.26.150:3030/gmra-change-password', requestOptions);
      const data = await resp.json();
      console.log(data)
      if(data.code !== 201){
        notify(data.message, { type: 'error' });
      }else {
        notify('Your change password is successfull', { type: 'success' });
      }

    } catch (err) {
      console.error(err);
      notify(`Error: ${JSON.stringify(err)}`, { type: 'error' });

    }
  };

  return (
    <Grid container sx={{ mt: 4 }} >
      <Title title="Change Password" />
      <Grid item xs={10} md={6} lg={5} >
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Change Password
          </Typography>
          <Box sx={{ pr: 7 }}>
            <TextField
              name="currentPass"
              type="password"
              //type="text"
              label="Current Password"
              variant="standard"
              fullWidth
              validate={[required()]}
              onChange={(e) => handleChange(e)}
            />
            <TextField
              name="newPass"
              type="password"
              //type="text"
              label="New Password"
              variant="standard"
              fullWidth
              validate={[required()]}
              onChange={(e) => handleChange(e)}
            />
            <TextField
              name="confirmPass"
              type="password"
              //type="text"
              label="Re-type New Password"
              variant="standard"
              fullWidth
              validate={[required()]}
              onChange={(e) => handleChange(e)}
            />
            <div style={{ marginTop: "30px" }}>
              <Button variant="contained" type="button" onClick={() => submitForm()}>
                Change
              </Button>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

