import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import LinkField2 from '../field/LinkField2';

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});

const postFilters = [

    <DateInput label="Kode Nasabah" source="kode_nasabah" />,

];





export function TaxReportList(props) {
    // const [modalIsOpen, showModal] = useState(false);
    const classes = useStyles();




    return (
        <List {...props} filters={postFilters} sort={{ field: 'id', order: 'DESC' }} >
            <Datagrid rowClick="edit">
                <TextField source="id" label="No." cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_nasabah" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <LinkField2 source="url" />
            </Datagrid>
        </List>
    )
}