import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    TopToolbar,
    TextInput,
    DateInput,
    FilterButton,
    SelectInput,
    useListContext
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import DocumentListField from '../field/DocumentListField';

import { makeStyles } from '@material-ui/core/styles';
import ThousandField from '../field/ThousandField';
// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});


export function DocumentListMature(props) {
    const classes = useStyles();
    function ListActions(props) {

        function handleClick(event) {
            // Here, we invoke the callback with the new value
            props.onClick(event.target.value);
        }
        return (
            <TopToolbar>
                <FilterButton />
            </TopToolbar>)
    }

    const DocList = props => {
        const { data } = useListContext()

        return (
            <div></div>
        )
    }

    const TipeTransaksi = (props) => {
        const record = useRecordContext(props);

        if (record.tipe_transaksi == 0) {
            return <span className={classes.text} >New Subcription</span>
        }
        else if (record.tipe_transaksi == 1) {
            return <span className={classes.text} >Redemption</span>
        }
        else if (record.tipe_transaksi == 2) {
            return <span className={classes.text}>Rollover Principal</span>
        }
        else if (record.tipe_transaksi == 3) {
            return <span className={classes.text}>Rollover Principal + Interest</span>
        }

    }


    const Tanggal = (props) => {
        const { source } = props;
        const record = useRecordContext(props);

        const date = record[source].split("T")[0];
        const y = date.split("-")[0];
        const d = date.split("-")[2];
        const m = date.split("-")[1];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const dates = [d, months[m - 1], y].join(" ");

        return dates;

    }



    const TipeNasabah = (props) => {
        const record = useRecordContext(props);
        console.log(record)
        if (record.tipe_nasabah == 1) {
            return <span className={classes.text} >Existing</span>
        }
        else {
            return <span className={classes.text} >New</span>
        }

    }

    const ShowDocument = (props) => {
        const record = useRecordContext(props);

        const link = "/#/gmra-document/" + record.id + "/show"
        return (<a href={link} >{record.no_surat_konfirmasi}</a>)

    }
    const postFilters = [
        <DateInput label="Tanggal Efektif" source="tanggal_efektif" />,
        <DateInput label="Tanggal Jatuh Tempo" source="tanggal_jatuh_tempo" />,
        <TextInput label="Kode Nasabah" source="kode_nasabah" />,
        <TextInput label="No Surat Konfirmasi" source="no_surat_konfirmasi" />,
        <TextInput label="Sales" source="sales" />,
        <SelectInput source="tipe_transaksi" choices={[

            { id: '1', name: 'Redemption' },
            { id: '2', name: 'Rollover Principal' },
            { id: '3', name: 'Rollover Principal + Interest' }
        ]} />
    ];

    TipeTransaksi.defaultProps = { label: 'Tipe Transaksi' };
    TipeNasabah.defaultProps = { label: 'Tipe Nasabah' };
    return (
        <List {...props} filters={postFilters} actions={<ListActions />} filter={{ active: 1, maturity_doc: 1 }} bulkActionButtons={false}>
            <Datagrid classes={classes} >

                <Tanggal source="tanggal_efektif" textAlign="center" label="Tgl. Efektif" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text}></Tanggal>
                <Tanggal source="tanggal_jatuh_tempo" textAlign="center" label="Tgl.Jatuh Tempo" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text}></Tanggal>
                {/* <TextField source="transaction_code" textAlign="center" label="Transaction Code" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text} />*/}
                <TextField source="kode_nasabah" className={classes.text} textAlign="center" label="Kode Nasabah" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} />
                <TipeNasabah source="tipe_nasabah" textAlign="center" label="Tipe Nasabah" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text}></TipeNasabah>
                <TipeTransaksi textAlign="center" label="Tipe Transaksi" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text}></TipeTransaksi>
                {/* <TextField source="free_of_payment" textAlign="center" label="Free of Payment" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text} /> */}
                <ShowDocument source="no_surat_konfirmasi" textAlign="center" link={false} label="No Surat Konfirmasi" headerClassName={classes.text} cellClassName={classes.cellPad} className={classes.text}></ShowDocument>
                <NumberField source="cabang" textAlign="center" label="Cabang" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text} />
                <TextField source="sales" textAlign="center" label="Sales" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text} />
                <TextField source="kode_saham" textAlign="center" label="Kode Saham" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text} />
                <ThousandField source="nominal_beli" />
                <DocumentListField source="document_link" />

            </Datagrid>
        </List>
    )
}