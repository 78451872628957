
import React, { useState, useEffect, useContext } from 'react';
import { Create, Button, SimpleForm, SelectInput, TextInput, DateInput, RadioButtonGroupInput, NumberInput } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import { useFormState } from 'react-final-form';
import md5 from 'md5'
import NumberFormat from 'react-number-format';

export const TransactionCreate = (props) => {

    const dataProvider = useContext(DataProviderContext);
    const [tipe_nasabah, setTipeNasabah] = useState(0);

    const [max_rasio,setMaxRasio]=useState(1);
    const [min_rasio,setMinRasio]=useState(1);
    const [threshold, setThreshold] = useState(0);
    const [jumlahDocument, setCountDocument] = useState(0);
    const [sales, setSales] = useState([]);
    const [name, setName] = useState();
    const [nama_saham, setNamaSaham] = useState('SMMA');
    const [order, setOrder] = useState(0);
    const [subRekening, setRekening] = useState('');
    const [bank, setBank] = useState('');
    const [rdn, setRDN] = useState('');
    const [harga, setHarga] = useState(0);
    const [bunga6, setBunga6] = useState(0);
    const [bunga12, setBunga12] = useState(0);
    const [jumlah, setJumlah] = useState(0);
    const [workingDays, setWorkingDay] = useState(0);
    const [nominalRepo, setRepo] = useState(0);
    const [tenor, setTenor] = useState();
    const [kode_nasabah, setNasabah] = useState();
    const [fee, setFee] = useState(0.002);
    const [nominal_beli, setNominalBeli] = useState(0);
    const [totalBeli, setTotalBeli] = useState(0);
    const [holidays, setHoliday] = useState([]);
    const [tanggal_efektif, setTanggalEfektif] = useState();
    const [tanggal_jatuh_tempo, setTanggalJatuhTempo] = useState();
    const [tingkat_bunga, setTingkatBunga] = useState(0);
    const [harga_buyback, setHargaBuyBack] = useState(0);

    const [kode_buyer, setKodeBuyer] = useState([]);
    const [noDokumen, setNoDokumen] = useState('');
    const [dateValid, setDateValid] = useState(0);
    const [final, setFinal] = useState(0);
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    Date.prototype.addMonth = function (month) {
        var date = new Date(this.valueOf());
        date.setMonth(date.getMonth() + month);
        return date;
    }
    const style = { width: 255, color: '#848482', backgroundColor: '#F3F2F1', height: 45, fontSize: 14, paddingTop: 2.5, paddingLeft: 10, marginTop: 30, marginBottom: 20, verticalAlign: 'center' }
    const label = { fontSize: 12, color: '#A9A9A9' }
    function formatDate(date) {
        var d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        //console.log([year, month, day].join('-'));
        return [year, month, day].join('-');
    }


    let workingDaysBetweenDates = (d0, d1, holiday) => {
        /* Two working days and an sunday (not working day) */
        var hd = holiday;
        //  console.log("COUNTING");

        var startDate = parseDate(d0);
        var endDate = parseDate(d1);

        // Validate input
        if (endDate <= startDate) {
            return 0;
        }

        // Calculate days between dates
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        startDate.setHours(0, 0, 0, 1);  // Start just after midnight
        endDate.setHours(23, 59, 59, 999);  // End just before midnight
        var diff = endDate - startDate;  // Milliseconds between datetime objects    
        var days = Math.ceil(diff / millisecondsPerDay);

        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days -= weeks * 2;

        // Handle special cases
        var startDay = startDate.getDay();
        var endDay = endDate.getDay();

        // Remove weekend not previously removed.   
        if (startDay - endDay > 1) {
            days -= 2;
        }
        // Remove start day if span starts on Sunday but ends before Saturday
        if (startDay == 0 && endDay != 6) {
            days--;
        }
        // Remove end day if span ends on Saturday but starts after Sunday
        if (endDay == 6 && startDay != 0) {
            days--;
        }
        /* Here is the code */
        hd.forEach(day => {
            if ((day >= d0) && (day <= d1)) {
                /* If it is not saturday (6) or sunday (0), substract it */
                if ((parseDate(day).getDay() % 6) != 0) {
                    days--;
                }
            }
        });
        return days;
    }

    function parseDate(input) {
        // Transform date from text to date
        var parts = input.match(/(\d+)/g);
        // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }

    const DataDokumen = props => {
        const { values } = useFormState();
        values.CreatedBy = localStorage.getItem('username');
        values.CreatedDate = new Date();
        values.active = 1;
        values.no_surat_konfirmasi = noDokumen;
        values.tipe_nasabah = tipe_nasabah;
        values.tipe_transaksi = 0;
        return (
            <div>
                <div >
                    <TextInput source="no_surat_konfirmasi" defaultValue="-" disabled />
                </div>
            </div>
        );
    };

    const DataNasabah = props => {
        const { values } = useFormState();
        setNasabah(values.kode_nasabah);
        var net

        dataProvider.getList('gmra-client?cid=' + values.kode_nasabah, { limit: 1 }).then(({ data }) => {
            try {
                //  console.log(data)
                setName(data[0].ClientName);
                setBank(data[0].BankName);
                setRekening(data[0].KSEISubAccountNo);
                setRDN(data[0].SavingsID);
                net = data[0].Cash + data[0].Sell - data[0].Buy;
                values.net_ac = net.toFixed(0);
                // console.log(net);

            }
            catch (e) {
                console.log(e)
            }
        });


        return (
            <div>

                <div>
                    <label>Nama Nasabah:</label><span> {name}</span> </div>
                <div>
                    <label>Sub Rekening Nasabah:</label><span> {subRekening}</span>
                </div>
                <div>
                    <label>Bank:</label><span> {bank}</span>
                </div>
                <div>
                    <label>RDN:</label><span> {rdn}</span>
                </div>
            </div>
        );
    };
    useEffect(() => {
        dataProvider.getList('gmra-threshold?tanggal_efektif[$lte]=' + tanggal_efektif + '&active=1', { limit: 1 }).then(({ data }) => {
            try {
              //  console.log("DATA Threshold", data[0])
                setThreshold(data[0].threshold)
                setMaxRasio(data[0].max_rasio / 100)
                setMinRasio(data[0].min_rasio / 100);
               

            }
            catch (e) {
                console.log(e)
            }
        });
    }, [tanggal_efektif])
    useEffect(() => {

        dataProvider.getManyReference('sales', { limit: 10 })
            .then((response) => { setSales(response); })
            .catch(error => {
                console.log(error)
            });

        dataProvider.getManyReference('seller', { limit: 10 })
            .then((response) => {
                setKodeBuyer(response);
            })
            .catch(error => {
                console.log(error)
            });
        var from = new Date()
        var d = (from.getDate() < 10 ? '0' : '') + from.getDate();
        var m = ((from.getMonth() + 1) < 10 ? '0' : '') + (from.getMonth() + 1);
        var y = from.getFullYear() + 2;
        var yf = from.getFullYear() - 1;
        var fromtext = [yf, m, d].join('-');
        var todate = new Date(y, m, d);
        var to = [y, m, d].join('-');
        dataProvider.getList('holiday?from=' + fromtext + '&to=' + to + '', { limit: 1 })
            .then(({ data }) => {
                var date = [];
                var holiday = data[0].Date;
                for (var x = 0; x < holiday.length; x++) {
                    try {
                        date.push(holiday[x].Date.split("T")[0]);
                    }
                    catch (e) {

                    }
                }

                setHoliday(date);
            })


    }, [])

    function formatDates(d, mode) {
        var date = d.getDate();
        var month = d.getMonth();

        var year = d.getFullYear();

        if (date < 10) {
            date = "0" + d.getDate();
        }
        if (month + 1 < 10) {
            month = "0" + (d.getMonth() + 1);
        }
        else {
            month = d.getMonth() + 1
        }

        var rv
        if (mode == 1) { rv = date + "-" + month + "-" + year; }
        if (mode == 2) { rv = [month, date, year].join("/") }
        return rv;

    }

    const DataSaham = props => {
        const { values } = useFormState();

        dataProvider.getList('gmra-saham?kode_saham=' + values.kode_saham, { limit: 1 }).then(({ data }) => {
            try {

                setNamaSaham(data[0].nama);
                values.nama_saham = data[0].nama


            }
            catch (e) {
                console.log(e)
            }
        });
        return (<div>

            <div>
                <label>Nama Saham:</label><span>{nama_saham}</span>
            </div>
        </div>);
    }
    const DataTransaksi = props => {
        const { values } = useFormState();

        setRepo(values.nominal_repo);
        var jumlahrepo = Math.floor(values.nominal_repo / harga);
        setJumlah(jumlahrepo);
        var nobeli = jumlahrepo * harga;

        sales.filter(function (entry) {
            if (entry.id == values.sales) {
                values.cabang = entry.oid
            }
        })
        setNominalBeli(nobeli);
        var totalbeli = nobeli + (nobeli * 0.1 / 100);
        setTotalBeli(totalbeli);
        values.threshold=threshold;
        values.harga_beli = Math.ceil(harga);
        values.jumlah = jumlahrepo.toFixed(0);
        values.nominal_beli = nobeli.toFixed(0);
        values.total_beli = totalbeli.toFixed(0);


        return (
            <div>
                <div>
                    <NumberFormat
                        value={threshold}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Rasio </div><div>{value} %</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={values.harga_beli}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Harga Beli</div><div>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={values.jumlah}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Jumlah</div><div>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={values.nominal_beli}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Nominal Beli</div><div>{value}</div></div>} />

                </div>
                <div>
                    <NumberFormat
                        value={0.1}
                        suffix={'%'}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Fee</div><div>{value}</div></div>} />

                </div>
                <div>
                    <NumberFormat
                        value={values.total_beli}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Nominal Beli + Fee</div><div>{value}</div></div>} />

                </div>


            </div>
        );
    };

    const countDocument = () => {

        if (typeof tanggal_efektif === 'undefined') {
            alert("Mohon isi Tanggal Efektif terlebih dahulu.")
        }
        else {
            var te = new Date(tanggal_efektif)
            dataProvider.getList('gmra-trans', {
                filter: { kode_nasabah: kode_nasabah }
            }).then(response => {
                if (response.total == 0) {
                    setTipeNasabah(0);
                }
                else {
                    setTipeNasabah(1);
                }

            })
            var doc = [];

            var todaymonth = te.getMonth() + 1;
            var todayyear = te.getFullYear();

            dataProvider.getOne('gmra-trans-number?month=' + todaymonth + '&year=' + todayyear, { id: 1 }).then(response => {
                console.log("RESPONSE", response);
                setCountDocument(response.data[0].nomor);
                setOrder(response.data[0].total)
                // setDocumentList(response)
                var nomordok = parseInt(response.data[0].nomor) + 1;
                try {


                    var yeardoc = te.getFullYear();
                    var monthdoc = te.getMonth();
                    var monthromawi = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"]
                    var noDocument = nomordok.toString().padStart(4, '0') + "/SMS-GMRA/" + monthromawi[monthdoc] + "/" + yeardoc;
                    setNoDokumen(noDocument);
                    setCountDocument(response.total)
                } catch (e) {
                    alert("Mohon isi Tanggal Efektif terlebih dahulu.")
                    console.log(e);
                }
            });

        }
    }



    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const generateNoDok = () => {
        countDocument();

    }


    const NominalRepo = props => {
        const { values } = useFormState();

        return (<NumberFormat
            value={values.nominal_repo}
            className="foo"
            displayType={'text'}
            thousandSeparator={true}
            renderText={(value, props) => <div {...props}>Value: {value}</div>}
        />)


    }


    const AlertDate = props => {
        const { values } = useFormState();
        var msgAlert = ""
        if (dateValid == 1) {
            msgAlert = "";
        }
        else {
            msgAlert = "Hari yang dipilih bukan hari kerja."
        }
        return (
            <div style={{ color: 'red' }}></div>
        )


    }


    function checkHoliday(date) {

        const day = `${date.getDate()}`.padStart(2, '0');
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const year = date.getFullYear();

        var datum = year + "-" + month + "-" + day;
        //  console.log("FORMATED DATE", datum);
        return holidays.includes(datum)
    }
    const DataTransaksi2 = props => {

        const { values } = useFormState();
        var bunga = values.tenor == 12 ? bunga12 : bunga6;
        var tenor = values.tenor;
        values.tingkat_bunga = bunga;
        values.order_number = order + 1;
        // var sechash = bcrypt.hashSync(new Date().toString(), 2);
        // console.log(sechash);
        var hash = md5(new Date().toString());
        values.security_hash = hash;
        var techeck = values.tanggal_efektif;
        setDateValid(1)
        if (holidays.includes(techeck)) {
            // values.tanggal_efektif = null;
            // alert("Tanggal Efektif tidak dapat di set pada hari libur");
            setDateValid(0)
        }
        var hari = new Date(techeck).getDay()
        if (hari == 0 || hari == 6) {
            // values.tanggal_efektif = null;
            //  alert("Tanggal Efektif tidak dapat di set pada hari libur");
            setDateValid(0)
        }

        setTingkatBunga(bunga)

        var today = new Date();
        values.tanggal_pembuatan = today;
        setTanggalEfektif(values.tanggal_efektif);
        // console.log(values.tanggal_efektif)


        dataProvider.getList('gmra-harga?tanggal_efektif[$lte]=' + values.tanggal_efektif + '&active=1&kode_saham=' + values.kode_saham, {
            limit: 1
        })
            .then(({ data }) => {
                //   console.log(data);
                setHarga(Math.ceil(data[data.length - 1].harga_beli / (threshold/100)));
                setBunga6(data[data.length - 1].tingkat_bunga6);
                setBunga12(data[data.length - 1].tingkat_bunga12);

            })
            .catch(error => {
                // console.log(error)
            })
        var from = new Date(values.tanggal_efektif)
        var d, m, y, ds;
        var tov
        if (tenor == 12) {
            tov = from.addDays(366);
            if (tov.getDay() == 6) {
                tov = tov.addDays(2);
            } else if (tov.getDay() == 0) {
                tov = tov.addDays(1);
            }
            //console.log(tov)
            while (checkHoliday(tov)) {
                tov = tov.addDays(1);
                console.log("HOLIDAY MEET")
            }


            d = (tov.getDate() < 10 ? '0' : '') + tov.getDate();

            m = ((tov.getMonth() + 1) < 10 ? '0' : '') + (tov.getMonth() + 1);
            y = tov.getFullYear();
        }
        else {

            // tov = from.addDays(-1);
            tov = from.addMonth(6);
            // tov = tov.addDays(1);
            if (tov.getDay() == 6) {
                tov = tov.addDays(2);
            } else if (tov.getDay() == 0) {
                tov = tov.addDays(1);
            }

            while (checkHoliday(tov)) {
                tov = tov.addDays(1);
                // console.log("HOLIDAY MEET")
            }
            var onholiday = false;

            d = (tov.getDate() < 10 ? '0' : '') + tov.getDate();

            m = ((tov.getMonth() + 1) < 10 ? '0' : '') + ((tov.getMonth() + 1) % 12);

            y = tov.getFullYear();


        }
        // if (tenor == 12) {
        //     d = (from.getDate() < 10 ? '0' : '') + from.getDate();
        //     ds = (from.getDate() + 1 < 10 ? '0' : '') + (from.getDate() + 1);
        //     m = ((from.getMonth() + 1) < 10 ? '0' : '') + (from.getMonth() + 1);
        //     y = from.getFullYear() + 1;
        // }
        // else {

        //     d = (from.getDate() < 10 ? '0' : '') + from.getDate();

        //     ds = (from.getDate() + 1 < 10 ? '0' : '') + (from.getDate() + 1);

        //     m = ((from.getMonth() + 1) < 10 ? '0' : '') + ((from.getMonth() + 1 + 6) % 12);

        //     if (from.getMonth() + 1 + 6 > 12) {
        //         y = from.getFullYear() + 1;
        //     }
        //     else {
        //         y = from.getFullYear();
        //     }
        //     var totext = [m, d, y].join('/');

        // }

        var yf = from.getFullYear();

        var fromtext = [yf, m, d].join('-');


        var to = [y, m, d].join('-');

        var check = true;
        var totext = [m, d, y].join('/');
        var tdd = new Date(to);
        // while (check == true) {
        //     check = false;

        //     if (holidays.includes(to)) {
        //         check = true;
        //         tdd.setDate(tdd.getDate() + 1);
        //         // console.log("It's Holiday");
        //     }
        //     var tocheck = tdd.getDay();

        //     if (tocheck == 0) {
        //         check = true;
        //         tdd.setDate(tdd.getDate() + 1);

        //         // console.log("It's Sunday");
        //     }

        //     if (tocheck == 6) {
        //         check = true;
        //         tdd.setDate(tdd.getDate() + 2);
        //         // console.log("It's Saturday");
        //     }
        //     to = formatDates(tdd, 1);
        //     //      console.log("TO", to);
        //     totext = formatDates(tdd, 2)
        // }


        setTanggalJatuhTempo(to);

        var settlement = from.addDays(1);
        if (settlement.getDay() == 6) {
            settlement = settlement.addDays(2)
        }
        else if (settlement.getDay() == 0) {
            settlement = settlement.addDays(1)
        }

        while (checkHoliday(settlement)) {
            settlement = settlement.addDays(1);
            //console.log("HOLIDAY MEET")
        }

        var tostext = [settlement.getFullYear(), settlement.getMonth() + 1, settlement.getDate()].join('/');
        var todate = new Date(totext);
        //  console.log(todate);

        values.tanggal_jatuh_tempo = totext;


        values.tanggal_settlement = tostext;
        var workingdays = 0;
        var harga_penjualan_kembali = 0;
        var pricing_rate_g = 0;
        var nilai_investasi_bersih = 0;
        var pricing_rate_n = 0;
        var date = holidays
        var Difference_In_Time = todate.getTime() - from.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);


        if (typeof date !== 'undefined') {
            // workingdays = workingDaysBetweenDates(fromtext, to, date);

            workingdays = Math.round(Difference_In_Days);

            setWorkingDay(workingdays);
            // setWorkingDay(365)

            var Difference_In_Time = todate.getTime() - from.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            values.jumlah_hari_bunga = workingdays;
            var nominal_bayar = nominal_beli * (1 + fee);
            var harga_buybacks = (((nominal_beli * (1 + bunga * (workingdays / 365))) + (0.001 * nominal_beli)) / (1 - 0.2 / 100)) / values.jumlah;
            harga_buybacks = Math.ceil(harga_buybacks);
           // console.log("HBB", harga_buybacks)
            harga_penjualan_kembali = harga_buybacks * values.jumlah;
            //  harga_penjualan_kembali = (((nominal_beli * (1 + bunga * (workingdays / 365))) + (values.total_beli - nominal_beli)) / (1 - (0.2 / 100)));
            values.harga_penjualan_kembali = Math.round(harga_penjualan_kembali);
           // console.log("HPK", harga_penjualan_kembali)
            setHargaBuyBack(harga_buybacks)


            values.harga_buyback = harga_buybacks;
            values.fee_beli = 0.1;
            // values.harga_penjualan_kembali = harga_penjualan_kembali.toFixed(0);
            nilai_investasi_bersih = harga_penjualan_kembali - (Math.round(harga_penjualan_kembali * 0.2 / 100));
            if (nilai_investasi_bersih % 1 == 0.5) {
                nilai_investasi_bersih += 0.1
            }
            values.nilai_investasi_bersih = Math.round(nilai_investasi_bersih);
            // values.fee_buyback = Math.ceil(0.2 / 100 * harga_penjualan_kembali);
            values.fee_buyback = Math.abs(nilai_investasi_bersih - harga_penjualan_kembali);

            pricing_rate_g = Math.round(((((harga_penjualan_kembali / nominal_beli) - 1) * 365 / workingdays)) * 10000) / 10000;


            pricing_rate_n = ((values.nilai_investasi_bersih / nominal_beli) - 1) * 365 / workingdays;

            values.pricing_rate_gross = (pricing_rate_g).toFixed(4);
            values.pricing_rate_net = (pricing_rate_n).toFixed(4);
        }



        return (
            <span>

            </span>
        );
    };

    const validateUserCreation = (values) => {
        const errors = {};

        if (dateValid == 0) {
            errors.tanggal_efektif = "Hari yang dipilih bukan merupakan hari kerja"
        }
        if (!values.kode_nasabah) {
            errors.kode_nasabah = "Mohon mengisi Kode Nasabah";
        }

        if (values.no_surat_konfirmasi == "-") {
            errors.no_surat_konfirmasi = "Mohon mengenerate No Surat Konfirmasi";
        }

        if (!values.sales) {
            errors.sales = "Mohon mengisi kode sales";
        }

        if (!values.nominal_repo || values.nominal_repo == 0) {
            errors.nominal_repo = "Mohon mengisi nominal Repo";
        }
        if (!values.tenor) {
            errors.tenor = "Mohon memilih pilihan yang tersedia";
        }

        if (!values.tanggal_efektif) {
            errors.tanggal_efektif = "Mohon mengisi Tanggal Efektif"
        }
        return errors
    };
    const NetAC = props => {
        const { values } = useFormState();
        return (
            <div>
                <NumberFormat
                    value={values.net_ac}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div> <label>Net AC:</label><span> {value}</span></div>} />

            </div>
        )
    }

    const TingkatBunga = props => {
        const { values } = useFormState();
        return (
            <div>
                <NumberFormat
                    value={values.tingkat_bunga}
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div style={style}{...props}><div style={label}>Tingkat Bunga:</div><div>{(value * 100).toFixed(2)} %</div></div>} />

            </div>
        )
    }
    const DataTransaksi3 = props => {
        const { values } = useFormState();
        return (
            <div>
                <div>
                    <NumberFormat
                        value={values.harga_penjualan_kembali}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Harga Penjualan Kembali</div><div>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={values.harga_buyback}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Harga Buyback</div><div>{value}</div></div>} />
                </div>
                <div>
                    <NumberFormat
                        value={values.nilai_investasi_bersih}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Nilai Investasi Bersih</div><div>{value}  </div></div>} />

                </div>
                <div>
                    <NumberFormat
                        value={values.pricing_rate_gross * 100}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Price Rate (Gross)</div><div>{value}  %</div></div>} />

                </div>
                <div>
                    <NumberFormat
                        value={values.pricing_rate_net * 100}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        renderText={(value, props) => <div style={style}{...props}><div style={label}>Price Rate (Net)</div><div>{value} %</div></div>} />
                </div>

            </div>
        )
    }

    return (
        <Create {...props} >
            <SimpleForm validate={validateUserCreation}>
                <TextInput source="kode_nasabah" required></TextInput>
                <DataNasabah></DataNasabah>
                <NetAC></NetAC>
                <table style={{ width: 512 }}>
                    <tr>
                        <td>
                            <NumberInput source="nominal_repo" required>
                            </NumberInput>
                        </td>
                        <td width="50%" style={{ verticalAlign: 'center' }}>
                            <NominalRepo></NominalRepo>
                        </td>
                    </tr>
                </table>

                <RadioButtonGroupInput source="tenor" choices={[
                    { id: '12', name: '12 bulan' },
                    { id: '6', name: '6 bulan' }
                ]} />
                <table style={{ width: 512 }}>
                    <tr>
                        <td>   <DateInput source="tanggal_efektif" defaultValue={tanggal_efektif} /></td>
                        <td>
                            <AlertDate />
                        </td>
                    </tr>
                </table>
                <TextInput source="kode_saham" />
                <DataSaham></DataSaham>
                <DataTransaksi></DataTransaksi>
                <TextInput disabled source="penyelesaian_transaksi" initialValue="T+1"></TextInput>
                <DateInput source="tanggal_jatuh_tempo" defaultValue={tanggal_jatuh_tempo} disabled />
                <TextInput source="jumlah_hari_bunga" defaultValue={workingDays} disabled />
                <TingkatBunga></TingkatBunga>
                <DataTransaksi2></DataTransaksi2>
                <DataTransaksi3></DataTransaksi3>
                <SelectInput source="kode_buyer" choices={kode_buyer} optionText="id" optionValue="id" />
                <SelectInput source="sales" choices={sales} optionText="id" optionValue="id" />
                <table style={{ width: 512 }}><tr><td><DataDokumen></DataDokumen> </td><td style={{ verticalAlign: 'center' }}><Button label="Generate No Document" onClick={() => generateNoDok()}></Button></td></tr></table>

            </SimpleForm>
        </Create >
    )
};