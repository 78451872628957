import * as React from "react";
import { Edit, SimpleForm, NumberInput, TextInput, FileField, DateInput, FileInput, BooleanInput, Toolbar, SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';


export const ThresholdEdit = (props) => {
    const DataTransaksi2 = props => {
        const { values } = useFormState();
        console.log(values);
        var data = ''
        if (typeof values.internal_memo !== 'undefined') {
            Promise.resolve(convertFileToBase64(values.internal_memo.rawFile)).then((res) => {
                console.log(res);
                console.log(dataURLtoFile(res, 'a.pdf'));
                values.baseFile = res
            });

        }

        return null
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        var montharr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, montharr[month - 1], day].join('-') ;
    }
    const InitialData = props => {
        const { values } = useFormState();
        var createdBy = values.CreatedBy
        var createdDate = values.CreatedDate
        var updateby = values.UpdatedBy
        var updatedate = values.UpdatedDate

        return (<div>
            <b> Created By : {createdBy} on {formatDate(createdDate)} <br />
                Last Update By : {updateby} on {formatDate(updatedate)} </b>

        </div>)
    }
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    const convertFileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    const UserEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    );
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <NumberInput source="threshold" />
                <NumberInput source="min_rasio" label="Min Rasio" disabled />
                <NumberInput source="max_rasio" label="Max Rasio" />
                <DataTransaksi2 />
                <DateInput source="tanggal_efektif" />
                {/* <FileInput source="internal_memo" label="Related files (max 500 Kb)" accept="application/pdf" maxSize={500000}>
                    <FileField source="src" title="title" />
                </FileInput> */}
                <BooleanInput source="active" />
                {/* <TextInput source="CreatedBy" disabled></TextInput>
                <TextInput source="CreatedDate" disabled></TextInput>
                <TextInput source="UpdatedBy" disabled label="Last Updated By"></TextInput>
                <TextInput source="UpdatedDate" disabled label="Last Updated Timestamp"></TextInput> */}
                <InitialData />
            </SimpleForm>
        </Edit>)
};
