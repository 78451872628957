import React, { useState, useEffect, useContext } from 'react';
import { Create, SimpleForm, NumberInput, TextInput, FileField, DateInput, FileInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import { DataProviderContext } from 'react-admin';
var fs = require('browserify-fs');

var md5 = require("md5");

export const ThresholdCreate = (props) => {
    const dataProvider = useContext(DataProviderContext);

    const DataTransaksi2 = props => {
        const { values } = useFormState();
    
        var data = ''
        if (typeof values.internal_memo !== 'undefined') {
            Promise.resolve(convertFileToBase64(values.internal_memo.rawFile)).then((res) => {
                // console.log(res);
                // console.log(dataURLtoFile(res, 'a.pdf'));
                //  savefile(res);
                values.baseFile = res
            });

        }

        return null
    }
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const savefile = (data) => {
        const fileContents = Buffer.from(("" + data).split(",")[1], 'base64')
        console.log(fileContents)
        var date = new Date().toString();
        var filename = "/Doc/" + md5(date) + ".pdf";
        fs.mkdir('/Doc', function () {
            fs.writeFile(filename, fileContents, (err) => {
                if (err) return console.error(err)
                console.log('file saved to ', "new.pdf")
            })
        })
    }

    const convertFileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

  

    return (
        <Create {...props}>
            <SimpleForm>
           
                <NumberInput source="threshold" label="Rasio Marjin" />
                <NumberInput source="min_rasio" label="Min Rasio" />
                <NumberInput source="max_rasio" label="Max Rasio" />
                <DateInput source="tanggal_efektif" />
                <DataTransaksi2></DataTransaksi2>
                <FileInput source="internal_memo" label="Related files (max 500 Kb)" accept="application/pdf" maxSize={500000}>
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
}
