import React from 'react';

import {
    List,
    Datagrid,
    DateInput,
    TopToolbar,
    TextField,
    DateField,
    TextInput,
    useRecordContext,
    FilterButton
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ThousandField from '../field/ThousandField';
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});
const postFilters = [
    <TextInput label="Kode Saham" source="kode_saham" defaultValue="" />,
];

const FullNameFieldB = (props) => {
    const record = useRecordContext(props);


    if (record.approved_buyer > 0) {
        return <span style={{ color: "green" }}>Approved</span>
    }
    else if (record.approval_full == 1) {
        return <span style={{ color: "red" }}>-</span>
    }
    else {
        return <span style={{ color: "red" }}>Waiting for Approval</span>
    }

}
const FullNameField = (props) => {
    const record = useRecordContext(props);


    if (record.approved_dir1 == 1) {
        return <span style={{ color: "green" }}>Approved</span>
    }
    else if (record.approval_full == 1) {
        return <span style={{ color: "red" }}>-</span>
    }
    else {
        return <span style={{ color: "red" }}>Waiting for Approval</span>
    }

}
const FullNameField2 = (props) => {
    const record = useRecordContext(props);

    if (record.approved_dir2 == 1) {
        return <span style={{ color: "green" }}>Approved</span>
    }
    else if (record.approval_full == 1) {
        return <span style={{ color: "red" }}>-</span>
    }
    else {
        return <span style={{ color: "red" }}>Waiting for Approval</span>
    }

}
const FullNameField4 = (props) => {
    const record = useRecordContext(props);


    if (record.approved_dir4 == 1) {
        return <span style={{ color: "green" }}>Approved</span>
    }
    else if (record.approval_full == 1) {
        return <span style={{ color: "red" }}>-</span>
    }
    else {
        return <span style={{ color: "red" }}>Waiting for Approval</span>
    }

}
const FullNameField3 = (props) => {
    const record = useRecordContext(props);

    if (record.approved_dir3 == 1) {
        return <span style={{ color: "green" }}>Approved</span>
    }
    else if (record.approval_full == 1) {
        return <span style={{ color: "red" }}>-</span>
    }
    else {
        return <span style={{ color: "red" }}>Waiting for Approval</span>
    }

}

FullNameField.defaultProps = { label: 'Approval Direksi (I)' };
FullNameField2.defaultProps = { label: 'Approval Direksi (II)' };
FullNameField3.defaultProps = { label: 'Approval Direksi (III)' };
FullNameField4.defaultProps = { label: 'Approval Direksi (IV)' };
FullNameFieldB.defaultProps = { label: 'Approval Principal' };
function ListActions(props) {

    return (
        <TopToolbar>
            <FilterButton></FilterButton>
        </TopToolbar>)
}

export function ApprovalDetailList(props) {
    const classes = useStyles();
    const postFilters = [
        <DateInput label="Tanggal Efektif" source="tanggal_efektif" />,
        <DateInput label="Tanggal Jatuh Tempo" source="tanggal_jatuh_tempo" />,
        <TextInput label="Kode Nasabah" source="kode_nasabah" />,
        <TextInput label="No Surat Konfirmasi" source="no_surat_konfirmasi" />,
        <TextInput label="Sales" source="sales" />
    ];
    return (
        <List {...props} filters={postFilters} filter={{ active: 1 }} sort={{ field: 'approved_dir1', order: 'ASC' }} actions={<ListActions />} bulkActionButtons={false} >
            <Datagrid>

                <TextField source="no_surat_konfirmasi" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_nasabah" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="cabang" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="sales" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_saham" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <ThousandField source="nominal_beli" />
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <DateField source="tanggal_jatuh_tempo" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />

                <FullNameField source="approved_dir1"></FullNameField>
                <FullNameField2 source="approved_dir2"></FullNameField2>
                <FullNameField3 source="approved_dir3"></FullNameField3>
                <FullNameField4 source="approved_dir4"></FullNameField4>
                {/* <FullNameFieldB source="approved_buyer"></FullNameFieldB> */}
            </Datagrid>
        </List>
    )
}