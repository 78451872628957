// in src/App.js
import * as React from "react";
import { Admin, Resource, usePermissions, Authenticated } from 'react-admin';
import { TransactionList } from "./gmra-transaction/TransactionList";
import feathersClient from "./client/feathersClient";
import { restClient, authClient } from 'ra-data-feathers';
import Login from './Login';
import { HargaList } from "./gmra-harga/HargaList";
import { HargaCreate } from "./gmra-harga/HargaCreate";
import { TransactionCreate } from "./gmra-transaction/TransactionCreate";
import { TransactionShow } from "./gmra-transaction/TransactionShow";
import { ApprovalList } from "./gmra-approval/ApprovalList";
import authProvider from "./client/authProvider";
import MyLayout from "./layout/Layout1"
import { DocumentList } from "./gmra-document/DocumentList";
import { MDocumentList } from "./gmra-document/MDocumentList";
import { MTransactionCreate } from "./gmra-transaction/MTransactionCreate";
import { DocumentShow } from "./gmra-document/DocumentShow";
import { RTransactionList } from "./gmra-transaction/RTransactionList";
import { ATransactionList } from "./gmra-transaction/ATransactionList";
import { CreateUploadList } from "./gmra-uploads/CreateUploadList";
import { MTransactionList } from "./gmra-transaction/MTransactionList";
import { HargaEdit } from "./gmra-harga/HargaEdit";
import { DocumentListMature } from "./gmra-document/DocumentListMature";
import { MaturityList } from "./gmra-transaction/MaturityList";
import { ApprovalShow } from "./gmra-approval/ApprovalShow";
import { ApprovalDetailList } from "./gmra-transaction/ApprovalDetailList";
import { MergeList } from "./gmra_print/MergeList";
import { MergeListSMC } from "./gmra_print/MergeListSMC";
import { MMergeList } from "./gmra_print/MMergeList";
import { TransactionLog } from "./gmra-transaction/TransactionLog";
import { ClosePriceList } from "./gmra-close-price/ClosePriceList";
import { UploadList } from "./gmra-uploads/UploadList";
import { RolloverCreate } from "./gmra-transaction/RolloverCreate";
import { ReportList } from "./gmra-report/ReportList";
import { RolloverView } from "./gmra-transaction/RolloverView"
import { ApprovalListRollOver } from "./gmra-transaction/ApprovalListRollOver";
import { RedeemTransaction } from "./gmra-transaction/RedeemTransaction";
import { ThresholdList } from "./gmra-threshold/ThresholdList";
import { ThresholdCreate } from "./gmra-threshold/ThresholdCreate";
import { ThresholdEdit } from "./gmra-threshold/ThresholdEdit";
import { ChangePassword } from "./change-password/changePasswordList";
import { ForgotPasswords } from "./forgot-password/forgotPasswordList";
// Link ICON https://v4.mui.com/components/material-icons/
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RepeatIcon from '@material-ui/icons/Repeat';
import AssessmentIcon from '@material-ui/icons/Assessment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Route } from 'react-router-dom';
import CalendarToday from '@material-ui/icons/CalendarToday'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TimerIcon from '@material-ui/icons/Timer';
import { TransactionListNC } from "./gmra-transaction/TransactionListNC";
import { DocumentListSMC } from "./gmra-document/DocumentListSMC";
import { MarginMonitoring } from "./gmra-monitoring/MarginMonitoring";
import { MarketHolidayList } from "./market-holiday/MarketHolidayList";
import { RegenerateList } from "./gmra-transaction/RegenerateList";
import { TaxReportList } from "./gmra-tax-report/TaxReportList";
import { TaxReportCreate } from "./gmra-tax-report/TaxReportCreate";
//import { TransactionEdit } from "./gmra-transaction/TransactionEdit";
// eslint-disable-next-line no-use-before-define


const restClientOptions = {
  usePatch: true, // Use PATCH instead of PUT for updates
  customQueryOperators: ['$gt', '$gte', '$lt', '$lte', '$ne', '$sort', '$or', '$nin', '$in', '$limit']
};

const App = () => {
  const { permissions } = usePermissions();


  return (

    <div>
      <Admin
        dataProvider={restClient(feathersClient, restClientOptions)}
        authProvider={authProvider}
        loginPage={Login}
        layout={MyLayout}
        customRoutes={[
          <Route exact path="/change-password" component={ChangePassword} />
        ]}
      >
        {permissions => [
          //Director
          permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
            <Resource name="approval" icon={CheckCircleIcon} options={{ label: 'Approval' }} list={ApprovalList} show={ApprovalShow} /> : null,
          permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
            <Resource name="approval-rollover" icon={CheckCircleIcon} options={{ label: 'Approval Rollover' }} list={ApprovalListRollOver} show={ApprovalShow} /> : null,
          permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
            <Resource name="gmra-trans" icon={CollectionsBookmarkIcon} options={{ label: 'Transaction' }} list={RTransactionList} show={TransactionShow} /> : null,
          permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
            <Resource name="gmra-document" icon={FileCopyIcon} options={{ label: 'Document' }} list={DocumentList} show={DocumentShow} /> : null,
          permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
            <Resource name="gmra-report" icon={AssessmentIcon} options={{ label: 'Report' }} list={ReportList} /> : null,
          permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
            <Resource name="gmra-maturity" icon={RepeatIcon} options={{ label: 'Maturity' }} show={RolloverView} /> : null,
          // permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
          //   <Resource name="change-password" options={{ label: 'Change Password' }} list={ChangePassword} /> : null,
          // permissions.split(',').includes('director1') || permissions.split(',').includes('director2') || permissions.split(',').includes('director3') || permissions.split(',').includes('director4') ?
          //   <Resource name="gmra-upload-list" icon={CloudUploadIcon} options={{ label: 'Upload' }} list={UploadList} /> : null,
          //Maker
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-trans" icon={CollectionsBookmarkIcon} options={{ label: 'Transaction' }} list={TransactionList} create={TransactionCreate} show={TransactionShow} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-transaction" icon={ThumbUpIcon} options={{ label: 'Approval List' }} list={ApprovalDetailList} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-print" icon={NoteAddIcon} options={{ label: 'Merge Request' }} list={MergeList} /> : null,
          //Audit
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-document" icon={FileCopyIcon} options={{ label: 'Documents' }} list={DocumentList} show={DocumentShow} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-document-mature" icon={FileCopyIcon} options={{ label: 'Maturity Document' }} list={DocumentListMature} show={DocumentShow} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-maturity" icon={RepeatIcon} options={{ label: 'Process Maturity' }} list={MaturityList} show={RolloverView} create={RolloverCreate} edit={RedeemTransaction} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-report" icon={AssessmentIcon} options={{ label: 'Report' }} list={ReportList} /> : null,
          // permissions.split(',').includes('maker') ?
          //   <Resource name="gmra-price" icon={MonetizationOnIcon} options={{ label: 'Harga' }} edit={HargaEdit} list={HargaList} create={HargaCreate} /> : null,
          // permissions.split(',').includes('maker') ?
          //   <Resource name="gmra-threshold" options={{ label: 'Threshold' }} icon={CollectionsBookmarkIcon} list={ThresholdList} create={ThresholdCreate} edit={ThresholdEdit} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-margin-monitoring" icon={AssessmentIcon} options={{ label: 'Margin Monitoring' }} list={MarginMonitoring} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="gmra-close-price" icon={AttachMoneyIcon} options={{ label: 'Close Price' }} list={ClosePriceList} /> : null,
          permissions.split(',').includes('maker') ?
            <Resource name="market-holiday" icon={CalendarToday} options={{ label: 'Market Holiday' }} list={MarketHolidayList} /> : null,

          // permissions.split(',').includes('maker') ?
          //   <Resource name="change-password" options={{ label: 'Change Password' }} list={ChangePassword} /> : null,

          //Reviewer
          permissions.split(',').includes('reviewer') ?
            <Resource name="gmra-trans" icon={CollectionsBookmarkIcon} options={{ label: 'Transaction' }} list={RTransactionList} show={TransactionShow} /> : null,
          permissions.split(',').includes('reviewer') ?
            <Resource name="gmra-document" icon={FileCopyIcon} options={{ label: 'Document' }} list={DocumentList} show={DocumentShow} /> : null,
          permissions.split(',').includes('reviewer') ?
            <Resource name="gmra-log" icon={TimerIcon} list={TransactionLog} options={{ label: 'Log' }} /> : null,
          permissions.split(',').includes('reviewer') ?
            <Resource name="gmra-close-price" icon={AttachMoneyIcon} options={{ label: 'Close Price' }} list={ClosePriceList} /> : null,
          permissions.split(',').includes('reviewer') ?
            <Resource name="gmra-maturity" show={RolloverView} /> : null,

          // permissions.split(',').includes('reviewer') ?
          //   <Resource name="change-password" options={{ label: 'Change Password' }} list={ChangePassword} /> : null,

          //Administrator
          permissions.split(',').includes('administrator') ?
            <Resource name="gmra-trans" icon={CollectionsBookmarkIcon} options={{ label: 'Transaction' }} list={ATransactionList} show={TransactionShow} /> : null,
          permissions.split(',').includes('administrator') ?
            <Resource name="gmra-price" icon={MonetizationOnIcon} options={{ label: 'Harga' }} edit={HargaEdit} list={HargaList} create={HargaCreate} /> : null,
          permissions.split(',').includes('administrator') ?
            <Resource name="gmra-threshold" icon={CollectionsBookmarkIcon} options={{ label: 'Threshold' }} list={ThresholdList} create={ThresholdCreate} edit={ThresholdEdit} /> : null,
          permissions.split(',').includes('administrator') ?
            <Resource name="gmra-close-price" icon={AttachMoneyIcon} options={{ label: 'Close Price' }} list={ClosePriceList} /> : null,
          permissions.split(',').includes('administrator') ?
            <Resource name="gmra-margin-monitoring" icon={AssessmentIcon} options={{ label: 'Margin Monitoring' }} list={MarginMonitoring} /> : null,

          // permissions.split(',').includes('administrator') ?
          //   <Resource name="change-password" options={{ label: 'Change Password' }} list={ChangePassword} /> : null,

          //Agent
          permissions.split(',').includes('agent') ?
            <Resource name="gmra-trans" options={{ label: 'Transaction' }} create={MTransactionCreate} list={MTransactionList} show={TransactionShow} /> : null,
          permissions.split(',').includes('agent') ?
            <Resource name="gmra-document" icon={FileCopyIcon} options={{ label: 'Document' }} list={MDocumentList} show={DocumentShow} /> : null,
          permissions.split(',').includes('agent') ?
            <Resource name="gmra-print" icon={NoteAddIcon} options={{ label: 'Merge Request' }} list={MMergeList} /> : null,
          permissions.split(',').includes('agent') ?
            <Resource name="gmra-upload-list" icon={CloudUploadIcon} options={{ label: 'Upload' }} list={UploadList} edit={CreateUploadList} /> : null,

          // permissions.split(',').includes('agent') ?
          //   <Resource name="change-password" options={{ label: 'Change Password' }} list={ChangePassword} /> : null,

          // permissions.split(',').includes('buyer') ?
          //   <Resource name="approval-rollover" icon={CheckCircleIcon} options={{ label: 'Approval Rollover' }} list={ApprovalListRollOver} show={ApprovalShow} /> : null,
          // permissions.split(',').includes('buyer') ?
          //   <Resource name="approval" icon={CheckCircleIcon} options={{ label: 'Approval' }} list={ApprovalList} show={ApprovalShow} /> : null,
          permissions.split(',').includes('buyer') ?
            <Resource name="gmra-trans" icon={CollectionsBookmarkIcon} options={{ label: 'Transaction' }} list={TransactionListNC} show={TransactionShow} /> : null,
          permissions.split(',').includes('buyer') ?
            <Resource name="gmra-print" icon={NoteAddIcon} options={{ label: 'Merge Request' }} list={MergeListSMC} /> : null,
          permissions.split(',').includes('buyer') ?
            <Resource name="gmra-document" icon={FileCopyIcon} options={{ label: 'Document' }} list={DocumentListSMC} show={DocumentShow} /> : null,
          // permissions.split(',').includes('buyer') ?
          //   <Resource name="gmra-maturity" show={RolloverView} /> : null,

          // permissions.split(',').includes('buyer') ?
          //   <Resource name="change-password" options={{ label: 'Change Password' }} list={ChangePassword} /> : null,

          permissions.split(',').includes('super') ?
            <Resource name="gmra-trans" icon={CollectionsBookmarkIcon} options={{ label: 'Transaction' }} list={RegenerateList} create={TransactionCreate} show={TransactionShow} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-transaction" icon={ThumbUpIcon} options={{ label: 'Approval List' }} list={ApprovalDetailList} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-print" icon={NoteAddIcon} options={{ label: 'Merge Request' }} list={MergeList} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-document" icon={FileCopyIcon} options={{ label: 'Document' }} list={DocumentList} show={DocumentShow} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-maturity" icon={RepeatIcon} options={{ label: 'Process Maturity' }} edit={RedeemTransaction} list={MaturityList} create={RolloverCreate} show={RolloverView} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-report" icon={AssessmentIcon} options={{ label: 'Report' }} list={ReportList} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-price" icon={MonetizationOnIcon} options={{ label: 'Harga' }} edit={HargaEdit} list={HargaList} create={HargaCreate} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-threshold" options={{ label: 'Threshold' }} icon={CollectionsBookmarkIcon} list={ThresholdList} create={ThresholdCreate} edit={ThresholdEdit} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-margin-monitoring" icon={AssessmentIcon} options={{ label: 'Margin Monitoring' }} list={MarginMonitoring} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-close-price" icon={AttachMoneyIcon} options={{ label: 'Close Price' }} list={ClosePriceList} /> : null,
          permissions.split(',').includes('super') ?
            <Resource name="gmra-tax-report" icon={AttachMoneyIcon} options={{ label: 'Tax Report' }} list={TaxReportList} create={TaxReportCreate} /> : null,

          // permissions.split(',').includes('super') ?
          //   <Resource name="gmra-upload-list" icon={CloudUploadIcon} options={{ label: 'Upload' }} list={UploadList} edit={CreateUploadList} /> : null,
          // permissions.split(',').includes('super') ?
          //   <Resource name="change-password" options={{ label: 'Change Password' }} list={ChangePassword} /> : null,
          // permissions.split(',').includes('') ?
          //   <Resource name="forgot-password" options={{ label: 'Forgot Password' }} list={ForgotPasswords} /> : null,
        ]}


      </Admin>
    </div>
  )
};
export default App;
