import React, { useState } from 'react';
import {
    List,
    Datagrid,
    CreateButton,
    TopToolbar,
    TextField,
    DateField,
    TextInput,
    FilterButton,
    useRecordContext,
    DateInput
} from 'react-admin';

import ThousandField from '../field/ThousandField';
import { makeStyles } from '@material-ui/core/styles';

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});

const Tanggal = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    const date = record[source].split("T")[0];
    const y = date.split("-")[0];
    const d = date.split("-")[2];
    const m = date.split("-")[1];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const dates = [d, months[m - 1], y].join(" ");
    return dates;
}
const postFilters = [
    <DateInput label="Tanggal Efektif" source="Date" />,
    <TextInput label="Kode Saham" source="StockName" />
]

export function ClosePriceList(props) {
    const classes = useStyles();
    return (
        <List {...props} sort={{ field: 'Date', order: 'DESC' }} bulkActionButtons={false} filters={postFilters}pagination={false} >
            <Datagrid rowClick="edit">
                <Tanggal source="Date" textAlign="center" label="Tanggal" headerClassName={classes.text} link={false} cellClassName={classes.cellPad} className={classes.text}></Tanggal>
                <TextField source="StockName" label="Code" sortable={false} />
                <ThousandField source="ClosePrice" sortable={false} />
                <ThousandField source="ARA" label="ARA" sortable={false} />
                <ThousandField source="ARB" label="ARB" sortable={false} />
            </Datagrid>
        </List>
    )
}