import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
const TipeTransaksi = (props) => {
    const { source } = props;
    const record = useRecordContext(props);

    var myArr = record[source];
    if (myArr) {
        myArr = myArr.replace('0', 'New Subs').replace('1', 'Redemption').replace('2', 'Rollover Principal').replace('3', 'Rollover Principa + Interest')
    }
    return (<div>{myArr}</div>)
}

TipeTransaksi.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.number
};

export default TipeTransaksi;