import React, { Fragment, useState, useContext } from 'react';
import {
    Button,
    useRefresh

} from 'react-admin';
import Buttons from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataProviderContext } from 'react-admin';


const MMergeButton = () => {
    const [open, setOpen] = useState(false);

    const [docType, setType] = useState(0)
    const [date, setDate] = useState();
    const refresh = useRefresh();
    const dataProvider = useContext(DataProviderContext);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const mergeDoc = () => mergeDocPress();

    function mergeDocPress() {
        if (docType == 0) {
            alert("Please select a type of document to merged")
        }
        else {
          
            dataProvider.create('gmra-print', {
                data: {
                    tanggal_efektif: date,
                    document_generated: 0,
                    document_type: docType,
                    officeNID:localStorage.getItem('oid')
                }
            })
        }
        refresh();
        setOpen(false);

    }

    function getDoc(value) {
        setDate(value)
    }

    function handleRadio(src) {
        setType(src)
    }


    return (
        <Fragment>
            <Button label="Merge" onClick={handleClick} />
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Insert document date you want to generate"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <input
                            type="date"
                            onChange={(value) => {
                                console.log(value.target.value);
                                getDoc(value.target.value);
                            }}
                        /><br></br>
                        <br></br>
                        <input type="radio" name="type" values="9" onChange={() => handleRadio(9)}></input><label> &nbsp;All</label> <br></br>
                        <input type="radio" name="type" values="1" onChange={() => handleRadio(1)}></input><label> &nbsp;GMRA </label> <br></br>
                        <input type="radio" name="type" values="2" onChange={() => handleRadio(2)}></input><label> &nbsp;Instruksi Blokir Saham </label> <br></br>
                        <input type="radio" name="type" values="3" onChange={() => handleRadio(3)}></input><label> &nbsp;Surat Konfirmasi Saham </label> <br></br>
                        <input type="radio" name="type" values="4" onChange={() => handleRadio(4)}></input><label> &nbsp;Surat Konfirmasi Saham AMB </label> <br></br>
                        <input type="radio" name="type" values="5" onChange={() => handleRadio(5)}></input><label> &nbsp;Surat Instruksi </label> <br></br>
                        <input type="radio" name="type" values="6" onChange={() => handleRadio(6)}></input><label> &nbsp;Sell Instruction AMB </label> <br></br>
                        <input type="radio" name="type" values="7" onChange={() => handleRadio(7)}></input><label> &nbsp;Surat Cabut Blokir </label> <br></br>
                        <input type="radio" name="type" values="8" onChange={() => handleRadio(8)}></input><label> &nbsp;Surat Pernyataan </label> <br></br>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={handleDialogClose} color="primary">
                        Cancel
                    </Buttons>
                    <Buttons onClick={mergeDoc} color="primary" autoFocus>
                        Confirm
                    </Buttons>
                </DialogActions>
            </Dialog>

        </Fragment>
    );
}

export default MMergeButton;