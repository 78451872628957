import React from 'react';

import {
    List,
    Datagrid,
    CreateButton,
    TopToolbar,
    TextField,
    NumberField,
    DateField,
    TextInput,
    EditButton,
    FilterButton,
    BooleanField,
    BooleanInput
} from 'react-admin';

import AttachmenttField from '../field/AttachmentField';

const postFilters = [
    <TextInput label="Kode Saham" source="kode_saham" defaultValue="" />,
];

function ListActions(props) {

    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton />
        </TopToolbar>)
}

export function HargaList(props) {
    return (
        <List {...props} title="Harga" filters={postFilters} actions={<ListActions />}>
            <Datagrid >
                <TextField source="kode_saham" />
                <NumberField source="harga_beli" options={{ style: 'currency', currency: 'IDR' }} />
                <NumberField source="tingkat_bunga6" label="Tingkat Bunga (6 bulan) %" />
                <NumberField source="tingkat_bunga12" label="Tingkat Bunga (12 bulan) %" />
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
                <AttachmenttField source="baseFile" label="Attachment" />
                <BooleanField source="active" />
                <EditButton basePath="/gmra-price" />
            </Datagrid>
        </List>
    )
}