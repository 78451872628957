import React, { useState } from 'react';

import { Fragment } from 'react';
import {
    List,
    Confirm,
    useUpdateMany,
    useRefresh, DateInput,
    useNotify,
    useUnselectAll,
    Datagrid,
    Button,
    TopToolbar,
    useRecordContext,
    TextField,
    DateField,
    TextInput,
    FilterButton
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ThousandField from '../field/ThousandField';
// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    textbtn:{
        fontSize: 23,
        padding: 10,
        color:'#000000',
        
        width:100
    },
    cellPad:
    {
        padding: 0
    },
    cellPadb:
    {
        padding: 20
    }
});



export function ApprovalList({ permissions, ...props }) {
    // const [modalIsOpen, showModal] = useState(false);
    // const [selectedItems, setSelection] = useState([]);
    // useEffect(() => {
    //     console.log(selectedItems)
    // }, [selectedItems])
    const unselectAll = useUnselectAll();
    var per = "" + permissions;
    const postFilters =
        [

            // per.split(',').includes('director1') ? <TextInput label="Approval" source="approved_dir1" defaultValue="0" /> : <TextInput label="Approval" source="approved_dir2" defaultValue="0" />,
            <DateInput label="Tanggal Efektif" source="tanggal_efektif" />,
            <DateInput label="Tanggal Jatuh Tempo" source="tanggal_jatuh_tempo" />,
            <TextInput label="Kode Nasabah" source="kode_nasabah" />,
            <TextInput label="No Surat Konfirmasi" source="no_surat_konfirmasi" />,
            <TextInput label="Sales" source="sales" />
        ];

    const classes = useStyles();
    const PostBulkActionButtons = ({ selectedIds }) => {
        const [open, setOpen] = useState(false);
        const refresh = useRefresh();
        const notify = useNotify();
        const unselectAll = useUnselectAll();
        var data;
        //console.log(new Date())
        var per = "" + permissions
        if (per.includes('director1')) {
            data = { approved_dir1: 1, approved_dir1_date: addHoursToDate(new Date(), 7) }
        } else if (per.includes('director2')) {
            data = { approved_dir2: 1, approved_dir2_date: addHoursToDate(new Date(), 7) }
        }
        else if (per.includes('director3')) {
            data = { approved_dir3: 1, approved_dir3_date: addHoursToDate(new Date(), 7) }
        }
        else if (per.includes('director4')) {
            data = { approved_dir4: 1, approved_dir4_date: addHoursToDate(new Date(), 7) }
        } else if (per.includes('buyer')) {
            data = { approved_buyer: localStorage.getItem('uid'), approved_buyer_date: addHoursToDate(new Date(), 7) }
        }

        const [updateMany, { loading }] = useUpdateMany(
            'gmra-trans',
            selectedIds,
            data,
            {
                onSuccess: () => {
                    unselectAll(props.resource)
                    refresh();
                    notify('Posts updated');

                },
                onFailure: error => notify('Error: posts not updated', 'warning'),
            }
        );



        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);

        const handleConfirm = () => {
            updateMany();
            setOpen(false);
        };

        return (
            <Fragment>
                <Button label="Approve" onClick={handleClick}>
                </Button>
                <Confirm
                    isOpen={open}
                    loading={loading}
                    title="Document Approval"
                    content="Are you sure you want to approve this transaction?"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </Fragment>
        );
    };

    function addHoursToDate(date, hours) {
        return new Date(new Date(date).setHours(date.getHours() + hours));
    }

    const ApproveButton = (props) => {
        const [open, setOpen] = useState(false);
        const record = useRecordContext(props);
        const unselectAll = useUnselectAll();
        const refresh = useRefresh();
        const notify = useNotify();

        var data;
        console.log("DATENOW" + addHoursToDate(new Date(), 7))
        var per = "" + permissions
        if (per.includes('director1')) {
            data = { approved_dir1: 1, approved_dir1_date: addHoursToDate(new Date(), 7) }
        } else if (per.includes('director2')) {
            data = { approved_dir2: 1, approved_dir2_date: addHoursToDate(new Date(), 7) }
        } else if (per.includes('director3')) {
            data = { approved_dir3: 1, approved_dir3_date: addHoursToDate(new Date(), 7) }
        } else if (per.includes('director4')) {
            data = { approved_dir4: 1, approved_dir4_date: addHoursToDate(new Date(), 7) }
        } else if (per.includes('buyer')) {
            data = { approved_buyer: localStorage.getItem('uid'), approved_buyer_date: addHoursToDate(new Date(), 7) }
        }

        const handleConfirm = () => {
            updateMany();
            setOpen(false);
        };
        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);

        const [updateMany, { loading }] = useUpdateMany(
            'gmra-trans',
            [record.id],
            data,
            {
                onSuccess: () => {
                    refresh();
                    notify('Posts updated');
                    unselectAll(props.resource)
                },
                onFailure: error => notify('Error: posts not updated', 'warning'),
            }
        );

        return (<Fragment  >
            <Button label="Approve" onClick={handleClick}>
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title="Document Approval"
                content="Are you sure you want to approve this transaction?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>)
    }

    const ShowDocument = (props) => {
        const record = useRecordContext(props);
        var link = "";
        if (record.tipe_transaksi == 0) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 1) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 2) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 3) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }

        return (<a href={link} >{record.no_surat_konfirmasi}</a>)
    }

    const FullNameField = (props) => {
        const record = useRecordContext(props);


        if (record.approved_dir1 == 1) {
            return <span style={{ color: "green" }}>Approved</span>
        }
        else {
            return <span style={{ color: "red" }}>Waiting for Approval</span>
        }

    }
    const FullNameField2 = (props) => {
        const record = useRecordContext(props);

        if (record.approved_dir2 == 1) {
            return <span style={{ color: "green" }}>Approved</span>
        }
        else {
            return <span style={{ color: "red" }}>Waiting for Approval</span>
        }

    }

    FullNameField.defaultProps = { label: 'Approval Direksi1' };
    FullNameField2.defaultProps = { label: 'Approval Direksi2' };
    const FullNameField3 = (props) => {
        const record = useRecordContext(props);


        if (record.approved_dir3 == 1) {
            return <span style={{ color: "green" }}>Approved</span>
        }
        else {
            return <span style={{ color: "red" }}>Waiting for Approval</span>
        }

    }
    const FullNameField4 = (props) => {
        const record = useRecordContext(props);

        if (record.approved_dir4 == 1) {
            return <span style={{ color: "green" }}>Approved</span>
        }
        else {
            return <span style={{ color: "red" }}>Waiting for Approval</span>
        }

    }

    FullNameField.defaultProps = { label: 'Approval Direksi1' };
    FullNameField2.defaultProps = { label: 'Approval Direksi2' };
    FullNameField3.defaultProps = { label: 'Approval Direksi3' };
    FullNameField4.defaultProps = { label: 'Approval Direksi4' };
    var filter;

    if (per.split(',').includes('director1')) {
        filter = { approved_dir1: 0, active: 1, approval_full: 0 ,rollover:0}
    }
    else if (per.split(',').includes('director2')) {
        filter = { approved_dir2: 0, active: 1, approval_full: 0 ,rollover:0}
    }
    else if (per.split(',').includes('director3')) {
        filter = { approved_dir3: 0, active: 1, approval_full: 0 ,rollover:0}
    }
    else if (per.split(',').includes('director4')) {
        filter = { approved_dir4: 0, active: 1, approval_full: 0 ,rollover:0}
    }
    else if (per.split(',').includes('buyer')) {
        filter = { approved_buyer: 0, active: 1, approval_full:1, kode_buyer:localStorage.getItem('kode_buyer')}
    }


    function ListActions(props) {

        function handleClick(event) {
            // Here, we invoke the callback with the new value
            props.onClick(event.target.value);
        }
        return (
            <TopToolbar>
                <FilterButton />
            </TopToolbar>)
    }
    return (

        <List {...props} bulkActionButtons={<PostBulkActionButtons />} filter={filter} filters={postFilters}>
            <Datagrid >
                <ShowDocument label="Nomor Surat Konfirmasi" cellClassName={classes.cellPad} textAlign="center" headerClassName={classes.text} />
                <TextField source="kode_nasabah" cellClassName={classes.cellPad} textAlign="center" className={classes.text} headerClassName={classes.text} />
                <TextField source="cabang" cellClassName={classes.cellPad} textAlign="center" className={classes.text} headerClassName={classes.text} />
                <TextField source="sales" cellClassName={classes.cellPad} textAlign="center" className={classes.text} headerClassName={classes.text} />
                <TextField source="kode_saham" cellClassName={classes.cellPad} textAlign="center" className={classes.text} headerClassName={classes.text} />
                <ThousandField source="nominal_beli" />
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'short', day: 'numeric' }} label="Tanggal Efektif" cellClassName={classes.cellPad} textAlign="center" className={classes.text} headerClassName={classes.text} />
                <DateField source="tanggal_jatuh_tempo" options={{ year: 'numeric', month: 'short', day: 'numeric' }} label="Tanggal Jatuh Tempo" cellClassName={classes.cellPad} textAlign="center" className={classes.text} headerClassName={classes.text} />
                <ApproveButton></ApproveButton>
            </Datagrid>
        </List>
    )
}