import React, { useState, useEffect, useContext } from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, FileField, DateInput, FileInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import { DataProviderContext } from 'react-admin';
var fs = require('browserify-fs');

var md5 = require("md5");

export const CreateUploadList = (props) => {
    const dataProvider = useContext(DataProviderContext);
    const prosol = (raw) => {


    }

    const DataTransaksi2 = props => {
        const { values } = useFormState();
        values.baseFile = []
        var data = ''
        values.no_surat = values.no_surat_konfirmasi
        var x = 0
        if (typeof values.internal_memo !== 'undefined') {

            var n = values.internal_memo[x].rawFile
            Promise.resolve(values.internal_memo.map((n) => convertFileToBase64(n.rawFile).then((res) => {
                values.baseFile[x] = res
                x++;
            })));

        }
        console.log(values)
        return null
    }
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const savefile = (data) => {
        const fileContents = Buffer.from(("" + data).split(",")[1], 'base64')
        console.log(fileContents)
        var date = new Date().toString();
        var filename = "/Doc/" + md5(date) + ".pdf";
        fs.mkdir('/Doc', function () {
            fs.writeFile(filename, fileContents, (err) => {
                if (err) return console.error(err)
                console.log('file saved to ', "new.pdf")
            })
        })
    }

    const convertFileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });



    return (
        <Edit undoable={false} {...props}>
            <SimpleForm>
                <TextInput source="no_surat_konfirmasi" disabled />
                <TextInput source="kode_nasabah" disabled />
                <TextInput source="cabang" disabled />
                <DataTransaksi2></DataTransaksi2>
                <TextInput source="sales" disabled />
                <TextInput source="kode_saham" defaultValue='SMMA' disabled />
                <DateInput source="tanggal_efektif" disabled />
                <DateInput source="tanggal_jatuh_tempo" disabled />
                <FileInput source="internal_memo" label="Related files (max 500 Kb)" accept="application/pdf" maxSize={500000} multiple={true}>
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Edit>
    );
}
