import React, { Fragment, useState, useContext, useEffect } from 'react';
import {
    Button,
    useRefresh
} from 'react-admin';
import Buttons from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataProviderContext } from 'react-admin';
import ReactDropdown, { Dropdown } from 'react-dropdown'



const MergeButtonSMC = () => {
    const [open, setOpen] = useState(false);
    const [transaksi, setTransaksi] = useState(1)
    const [docType, setType] = useState(0)
    const [date, setDate] = useState();
    const [isDisable, setIsDisable] = useState(false);
    const [check, setCheck] = useState(false);
    const [idfrom, setFrom] = useState(0);
    const [idto, setTo] = useState(0);
    const [idcollection, setIDCollection] = useState([]);
    const refresh = useRefresh();
    const dataProvider = useContext(DataProviderContext);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const mergeDoc = () => mergeDocPress();
    useEffect(() => { getIDPrint() }, [date, docType])
    function getIDPrint() {

        dataProvider.getList('gmra-print-id?tanggal_efektif=' + date + '', { limit: 1 })
            .then(({ data }) => {
                var id = [];
                var docid = data[0].id;
                for (var x = 0; x < docid.length; x++) {
                    try {
                        console.log(docid[x].id)
                        id.push(docid[x].id);
                    }
                    catch (e) {

                    }
                }

                setIDCollection(id);
            })

    }

    function mergeDocPress() {
        var redeem = 0;
        var rollover = 0;
        var interest = 0;
        var margin = 0;
        if (docType == 0) {
            alert("Please select a type of document to merged")
        }
        else {
            if (transaksi == 1) { //New Sub
                redeem = 0;
                rollover = 0;
                interest = 0;
                margin = 0;
            }
            else if (transaksi == 2) { //Rollover
                redeem = 0;
                rollover = 1;
                interest = 0;
                margin = 0;
            }
            else if (transaksi == 3) { //Rollover + Interest
                redeem = 0;
                rollover = 0;
                interest = 1;
                margin = 0;
            }
            else if (transaksi == 4) { //Redeem
                redeem = 1;
                rollover = 0;
                interest = 0;
                margin = 0;
            }
            else if (transaksi == 5) {//Rasio Margin
                redeem = 0;
                rollover = 0;
                interest = 0;
                margin = 1;
            }
            if (check) {

                if (idfrom == 0 || idto == 0) {
                    alert("Please enter From field and To Field")

                }
                else {
                    var loop = "";
                    for (var x = idfrom.value; x <= idto.value; x++) {
                        if (x == idto.value) {
                            loop += x;
                        }
                        else {
                            loop += x + ",";
                        }
                    }

                    dataProvider.create('gmra-print', {
                        data: {
                            tanggal_efektif: date,
                            document_generated: 0,
                            document_type: docType,
                            printid: loop,
                            buyer: 1,
                            redeem: redeem,
                            rollover: rollover,
                            interest: interest,
                            margin: margin,
                            kode_buyer: localStorage.getItem('kode_buyer')
                        }
                    })
                }
            }
            else {
                dataProvider.create('gmra-print', {
                    data: {
                        tanggal_efektif: date,
                        document_generated: 0,
                        document_type: docType,
                        flag: 0,
                        buyer: 1,
                        redeem: redeem,
                        rollover: rollover,
                        interest: interest,
                        margin: margin,
                        kode_buyer: localStorage.getItem('kode_buyer')
                    }
                })
            }
        }
        refresh();
        setOpen(false);

    }
    function getDoc(value) {
        setDate(value)
    }
    function handleRadio(src) {
        setType(src)
        // alert(src);
    }
    async function checkHolidayOrWeekend(date) {
        const resp = await dataProvider.getList(`holiday?from=${date}&to=${date}`, { limit: 1 });
        const data = resp[0];

        const selectedDate = new Date(date);
        const isWeekend = (selectedDate.getDay() === 6) || (selectedDate.getDay() === 0);

        if (data.Date.length > 0 || isWeekend) {
            setIsDisable(true);
            alert('Tanggal Efektif tidak dapat diset pada hari libur');
            return
        }
        setIsDisable(false);
    }

    const handleChange = (e) => {
        console.log(e)
        setTransaksi(e.target.value)
    }

    const handleCheck = (e) => {
        console.log(e)
        setCheck(e.target.value);
    }

    const DocumentChoice = () => {

        console.log(transaksi)



        if (transaksi == 1) { // New Sub
            return (<><input type="radio" name="type" values="9" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
                <input type="radio" name="type" values="1" onChange={() => handleRadio(1)}></input><label> &nbsp;GMRA </label> <br></br>
                <input type="radio" name="type" values="2" onChange={() => handleRadio(2)}></input><label> &nbsp;Instruksi Blokir Saham </label> <br></br>
                <input type="radio" name="type" values="3" onChange={() => handleRadio(3)}></input><label> &nbsp;Surat Konfirmasi Saham </label> <br></br>
                <input type="radio" name="type" values="4" onChange={() => handleRadio(4)}></input><label> &nbsp;Surat Konfirmasi Saham Seller </label> <br></br>
                <input type="radio" name="type" values="7" onChange={() => handleRadio(7)}></input><label> &nbsp;Surat Cabut Blokir </label> <br></br>
                <input type="radio" name="type" values="8" onChange={() => handleRadio(8)}></input><label> &nbsp;Surat Pernyataan </label> <br></br>
                {/* <input type="radio" name="type" values="15" onChange={() => handleRadio(15)}></input><label> &nbsp;Surat Kuasa Repo</label> <br></br>
                <input type="radio" name="type" values="10" onChange={() => handleRadio(10)}></input><label> &nbsp;Surat Instruksi Beli Company DVP-RVP</label> <br></br>
                <input type="radio" name="type" values="6" onChange={() => handleRadio(6)}></input><label> &nbsp;Surat Instruksi Jual Company DVP-RVP </label> <br></br>
            </>) */}
                <input type="radio" name="type" values="15" onChange={() => handleRadio(15)}></input><label> &nbsp;Surat Kuasa Repo</label> <br></br></>)
        }
        else if (transaksi == 2) { //Rollover
            return (<><input type="radio" name="type" values="9" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
                <input type="radio" name="type" values="9" onChange={() => handleRadio(9)}></input><label> &nbsp;Surat Instruksi Jual Customer DVP-RVP </label> <br></br>
                <input type="radio" name="type" values="10" onChange={() => handleRadio(10)}></input><label> &nbsp;Surat Instruksi Beli Company DVP-RVP</label> <br></br>
                <input type="radio" name="type" values="5" onChange={() => handleRadio(5)}></input><label> &nbsp;Surat Instruksi Beli Customer DVP-RVP </label> <br></br>
                <input type="radio" name="type" values="6" onChange={() => handleRadio(6)}></input><label> &nbsp;Surat Instruksi Jual Company DVP-RVP </label> <br></br>
                <input type="radio" name="type" values="11" onChange={() => handleRadio(11)}></input><label> &nbsp;Surat Instruksi Beli Customer FOP </label> <br></br>
                <input type="radio" name="type" values="14" onChange={() => handleRadio(14)}></input><label> &nbsp;Surat Instruksi Jual Company FOP </label> <br></br>
                <input type="radio" name="type" values="12" onChange={() => handleRadio(12)}></input><label> &nbsp;Surat Instruksi Jual Customer FOP </label> <br></br>
                <input type="radio" name="type" values="13" onChange={() => handleRadio(13)}></input><label> &nbsp;Surat Instruksi Beli Company FOP</label> <br></br>            <input type="radio" name="type" values="2" onChange={() => handleRadio(2)}></input><label> &nbsp;Instruksi Blokir Saham </label> <br></br>
                <input type="radio" name="type" values="3" onChange={() => handleRadio(3)}></input><label> &nbsp;Surat Konfirmasi Saham </label> <br></br>
                <input type="radio" name="type" values="7" onChange={() => handleRadio(7)}></input><label> &nbsp;Surat Cabut Blokir </label> <br></br>
                <input type="radio" name="type" values="8" onChange={() => handleRadio(8)}></input><label> &nbsp;Surat Pernyataan </label> <br></br></>)

        }
        else if (transaksi == 3) {//Rollover+Interest
            return (<><input type="radio" name="type" values="9" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
                <input type="radio" name="type" values="2" onChange={() => handleRadio(2)}></input><label> &nbsp;Instruksi Blokir Saham </label> <br></br>
                <input type="radio" name="type" values="7" onChange={() => handleRadio(7)}></input><label> &nbsp;Surat Cabut Blokir </label> <br></br>

                <input type="radio" name="type" values="11" onChange={() => handleRadio(11)}></input><label> &nbsp;Surat Instruksi Beli Customer FOP </label> <br></br>
                <input type="radio" name="type" values="14" onChange={() => handleRadio(14)}></input><label> &nbsp;Surat Instruksi Jual Company FOP </label> <br></br>
                <input type="radio" name="type" values="12" onChange={() => handleRadio(12)}></input><label> &nbsp;Surat Instruksi Jual Customer FOP </label> <br></br>
                <input type="radio" name="type" values="13" onChange={() => handleRadio(13)}></input><label> &nbsp;Surat Instruksi Beli Company FOP</label> <br></br>
                <input type="radio" name="type" values="3" onChange={() => handleRadio(3)}></input><label> &nbsp;Surat Konfirmasi Saham </label> <br></br>
                <input type="radio" name="type" values="8" onChange={() => handleRadio(8)}></input><label> &nbsp;Surat Pernyataan </label> <br></br></>)
        }
        else if (transaksi == 4) {//Redeem
            return (<><input type="radio" name="type" values="9" onChange={() => handleRadio(97)}></input><label> &nbsp;All</label> <br></br>
                <input type="radio" name="type" values="9" onChange={() => handleRadio(9)}></input><label> &nbsp;Surat Instruksi Jual Customer DVP-RVP </label> <br></br>
                <input type="radio" name="type" values="10" onChange={() => handleRadio(10)}></input><label> &nbsp;Surat Instruksi Beli Company DVP-RVP</label> <br></br>
            </>)
        }
        else if (transaksi == 5) {//MArgin
            return (<><input type="radio" name="type" values="98" onChange={() => handleRadio(98)}></input><label> &nbsp;All</label> <br></br>
                <input type="radio" name="type" values="99" onChange={() => handleRadio(99)}></input><label> &nbsp;Surat Instruksi Top Up</label> <br></br>
                <input type="radio" name="type" values="100" onChange={() => handleRadio(100)}></input><label> &nbsp;Surat Instruksi Deduct</label> <br></br></>)
        }

    }
    const handleOnChange = () => {
        setCheck(!check);
    }



    const handleOnChangeTo = (value) => {
        setTo(value)
    }
    const handleOnChangeFrom = (value) => {
        setFrom(value)
    }

    const PrintOrder = () => {

        if (check) {
            return (<>
                FROM: <ReactDropdown options={idcollection} value={idfrom} onChange={handleOnChangeFrom}></ReactDropdown>
                TO: <ReactDropdown options={idcollection} value={idto} onChange={handleOnChangeTo}></ReactDropdown>
            </>)
        }
        else {
            return (<></>)
        }
    }

    return (
        <Fragment>
            <Button label="Merge" onClick={handleClick} />
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Insert document date you want to generate"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tanggal Efektif :
                        <input
                            type="date"
                            onBlur={async (e) => {
                                const selectedDate = e.target.value;
                                await checkHolidayOrWeekend(selectedDate);
                                getDoc(e.target.value);
                            }}
                        /><br></br>
                        <br />
                        Tipe Transaksi:
                        <select onChange={(e) => handleChange(e)} >
                            <option value={1}>New</option>
                            <option value={2}>Rollover Principal</option>
                            <option value={3}>Rollover Principal + Interest</option>
                            <option value={4}>Redemption</option>
                            <option value={5}>Margin Ratio</option>


                        </select>

                        <br></br>

                        <input
                            type="checkbox"
                            id="topping"
                            name="topping"
                            value="Paneer"
                            checked={check}
                            onChange={handleOnChange}
                        /> Print By Order<br />


                    </DialogContentText>

                    <DialogContentText>
                        <DocumentChoice></DocumentChoice>

                    </DialogContentText>
                    <DialogContentText>
                        <PrintOrder></PrintOrder>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={handleDialogClose} color="primary">
                        Cancel
                    </Buttons>
                    <Buttons disabled={isDisable} onClick={mergeDoc} color="primary" autoFocus>
                        Confirm
                    </Buttons>
                </DialogActions>
            </Dialog>

        </Fragment>
    );
}

export default MergeButtonSMC;