import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';


const SuratInstruksi = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    console.log(record)
    const myArr = record[source]



    const myComp = () => {
        if (myArr !== "") {
            return (<a href={"" + myArr}>Surat Instruksi</a>
            )
        }
        else {
            return (<div></div>)
        }
    }

    return myComp();
}

SuratInstruksi.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.string.isRequired,
};

export default SuratInstruksi;