import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLogin, useNotify, Notification, defaultTheme, required } from 'react-admin';
import { Link } from '@material-ui/core';
import "./Login.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material'

import CryptoJS from 'crypto-js'


const Login = () => {
    const [username, setUsername] = useState('');
    const [epassword, setePassword] = useState('');
    const [password, setPassword] = useState('');
    const [strategy, setStrategy] = useState('mybo');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const login = useLogin();

    const notify = useNotify();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [form, appendForm] = useState({
        username: "",
        email: ""
    })

    const handleChange = (e) => {
        appendForm({
            ...form,
            [e.target.name]: e.target.type === "file" ? e.target.files : e.target.value,
        });
    }


    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        //if (window.location.origin == 'http://localhost:3000' || window.location.origin == 'http://172.16.115.49:3000') {
          if (window.location.origin == 'https://gmra.siminvest.co.id') {
        } else {
            setStrategy('local')
        }
    }, [])

    useEffect(() => {
        setPassword(CryptoJS.AES.encrypt(epassword, '4g3nts3cr3t').toString());
    }, [epassword])

    const forgotPassword = async () => {
        // var request
        // request = new Request('http://172.17.26.150:3030/forgotPassword', {
        //     method: 'POST',
        //     body: JSON.stringify({ username }),
        //     headers: new Headers({ 'Content-Type': 'application/json' }),
        // });

        try {
            const token = localStorage.getItem('feathers-jwt');
            setOpen(false)

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({ username: form.username, email: form.email })
            };
            const resp = await fetch('http://172.16.115.49:3030/gmra-change-password', requestOptions);
            const data = await resp.json();
            console.log(data)
            if (data.code !== 201) {
                alert(data.message)
                notify(data.message, { type: 'error' });
            } else {
                alert('Success send email')
                notify('Your change password is successfull', { type: 'success' });
                setOpen(false)
            }

        } catch (err) {
            console.error(err);
            notify(`Error: ${JSON.stringify(err)}`, { type: 'error' });

        }
    }

    const submit = e => {

        e.preventDefault();
        //setPassword(CryptoJS.AES.encrypt(password,'4g3nts3cr3t').toString());

        login({ strategy, username, password })
            .catch((error) => {
                console.log("LOGIN", error)
                alert('Invalid email or password')
                notify('Invalid email or password')
            }
            ).then(() => window.location.reload());

        //console.log(login);
    };
   // if (window.location.origin == 'http://localhost:3000' || window.location.origin == 'http://172.16.115.49:3000') {
        if (window.location.origin == 'https://gmra.siminvest.co.id') {
        return (

            <div className="row">
                <div className="col-lg-6 bgbackground">

                </div>
                <div className="col-lg-6 column fullh" >
                    <div className="logincontainer" >
                        <img src="./img/logo.png" height="80px" style={{ marginTop: 100 }} className="loginlogo" />
                        <div className="headTitle"><h2><span className="titleBlue">GMRA</span>  Document</h2></div>
                        <div>
                            {/* <input type="radio" id="html" name="fav_language" value="mybo" onChange={e => setStrategy(e.target.value)} checked="checked" />
                            <label for="html">HO</label><br />
                            <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
                            <label for="css">Cabang</label><br></br> */}
                            {/* <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
                        <label for="css">Buyer</label><br></br> */}
                        </div>

                        <div className="loginField row">

                            <form onSubmit={submit}>
                                <div className="form-group formrow">
                                    <label ><b>Username</b></label>
                                    <div className="input-group" id="show_hide_password">
                                        <input
                                            name="username"
                                            type="text"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                        />


                                    </div>
                                </div>
                                <div className="form-group formrow">
                                    <label><b>Kata Sandi</b></label>
                                    <div className="input-group" id="show_hide_password" >
                                        <input
                                            name="epassword"
                                            type="password"
                                            value={epassword}
                                            onChange={e => setePassword(e.target.value)}
                                        />

                                    </div>
                                </div>
                                <div className="row button-space">
                                    <div class="col-sm-6"><input type="submit" className="btn btn-primary btnLogin" value="Login" /></div>
                                </div>
                            </form>
                            {/* <div>
                                <p>Forgot password ? Klik <button style={{ border: "none", backgroundColor: "white", fontWeight: 900, textDecoration: "underline", color: "blue" }} onClick={() => handleOpen()}>Here</button></p>
                            </div> */}
                            <div className="col-sm-2"><Link href={`https://facebook.com/`}>

                            </Link></div>
                        </div>
                        <div id="footer"> © 2021 PT Sinarmas Sekuritas | IT Development All Right Reserved</div>
                        <div>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Input Your Username and Email
                                    </Typography>
                                    <Box sx={{ pr: 7 }}>
                                        <TextField
                                            name="username"
                                            type="text"
                                            label="Username"
                                            variant="standard"
                                            fullWidth
                                            validate={[required()]}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <TextField
                                            name="email"
                                            type="text"
                                            label="Email"
                                            variant="standard"
                                            fullWidth
                                            validate={[required()]}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <div style={{ marginTop: "30px" }}>
                                            <Button variant="contained" type="button" onClick={() => forgotPassword()}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Box>
                                </Box>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
    //     else if (window.location.origin == 'https://gmra.nirmalataruna.co.id') {
    //         // else if (window.location.origin == 'http://localhost:3002' ) {
    //         return (

    //             <div className="row">
    //                 <div className="col-lg-6 bgbackground2">

    //                 </div>
    //                 <div className="col-lg-6 column fullh" >
    //                     <div className="logincontainer" >
    //                         <img src="./img/logo3.png" height="150px" style={{ marginTop: 100 }} className="loginlogo" />

    //                         <div className="headTitle"><h2><span className="titleRed">GMRA</span>  Document</h2></div>
    //                         <div>
    //                             {/* <input type="radio" id="html" name="fav_language" value="mybo" onChange={e => setStrategy(e.target.value)} checked="checked" />
    //                             <label for="html">HO</label><br />
    //                             <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
    //                             <label for="css">Cabang</label><br></br>
    //                             <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
    //                         <label for="css">Buyer</label><br></br> */}
    //                         </div>

    //                         <div className="loginField row">

    //                             <form onSubmit={submit}>
    //                                 <div className="form-group formrow">
    //                                     <label ><b>Username</b></label>
    //                                     <div className="input-group" id="show_hide_password">
    //                                         <input
    //                                             name="username"
    //                                             type="text"
    //                                             value={username}
    //                                             onChange={e => setUsername(e.target.value)}
    //                                         />


    //                                     </div>
    //                                 </div>
    //                                 <div className="form-group formrow">
    //                                     <label><b>Kata Sandi</b></label>
    //                                     <div className="input-group" id="show_hide_password" >
    //                                         <input
    //                                             name="epassword"
    //                                             type="password"
    //                                             value={epassword}
    //                                             onChange={e => setePassword(e.target.value)}
    //                                         />

    //                                     </div>
    //                                 </div>
    //                                 <div className="row button-space">
    //                                     <div class="col-sm-6"><input type="submit" className="btn btn-primary btnLogin" value="Login" /></div>
    //                                 </div>
    //                             </form>
    //                             {/* <div style={{ marginTop: "30px" }}>
    //                                 <p>Forgot password ? Klik <button style={{ border: "none", backgroundColor: "white", fontWeight: 900, textDecoration: "underline", color: "blue" }} onClick={() => handleOpen()}>Here</button></p>
    //                             </div> */}
    //                             <div className="col-sm-2"><Link href={`https://facebook.com/`}>

    //                             </Link></div>
    //                         </div>
    //                         <div id="footer"> © 2021 PT Sinarmas Sekuritas | IT Development All Right Reserved</div>
    //                         <div>
    //                             <Modal
    //                                 open={open}
    //                                 onClose={handleClose}
    //                                 aria-labelledby="modal-modal-title"
    //                                 aria-describedby="modal-modal-description"
    //                             >
    //                                 <Box sx={style}>
    //                                     <Typography id="modal-modal-title" variant="h6" component="h2">
    //                                         Input Your Username and Email
    //                                     </Typography>
    //                                     <Box sx={{ pr: 7 }}>
    //                                         <TextField
    //                                             name="username"
    //                                             type="text"
    //                                             label="Username"
    //                                             variant="standard"
    //                                             fullWidth
    //                                             validate={[required()]}
    //                                             onChange={(e) => handleChange(e)}
    //                                         />
    //                                         <TextField
    //                                             name="email"
    //                                             type="text"
    //                                             label="Email"
    //                                             variant="standard"
    //                                             fullWidth
    //                                             validate={[required()]}
    //                                             onChange={(e) => handleChange(e)}
    //                                         />
    //                                         <div style={{ marginTop: "30px" }}>
    //                                             <Button variant="contained" type="button" onClick={() => forgotPassword()}>
    //                                                 Submit
    //                                             </Button>
    //                                         </div>
    //                                     </Box>
    //                                 </Box>
    //                             </Modal>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //         );
    //     }
    //    else if (window.location.origin == 'https://gmra.shintautama.co.id') {
    //    //else if (window.location.origin == 'http://172.17.26.51:3002' || window.location.origin == 'http://localhost:3002') {
    //         return (

    //             <div className="row">
    //                 <div className="col-lg-6 bgbackgroundshinta">

    //                 </div>
    //                 <div className="col-lg-6 column fullh" >
    //                     <div className="logincontainer" >
    //                         <img src="./img/logoshinta.png" height="150px" style={{ marginTop: 100 }} className="loginlogo" />

    //                         <div className="headTitle"><h2><span className="titleRed">GMRA</span>  Document</h2></div>
    //                         <div>
    //                             {/* <input type="radio" id="html" name="fav_language" value="mybo" onChange={e => setStrategy(e.target.value)} checked="checked" />
    //                                  <label for="html">HO</label><br />
    //                                  <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
    //                                  <label for="css">Cabang</label><br></br>
    //                                  <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
    //                              <label for="css">Buyer</label><br></br> */}
    //                         </div>

    //                         <div className="loginField row">

    //                             <form onSubmit={submit}>
    //                                 <div className="form-group formrow">
    //                                     <label ><b>Username</b></label>
    //                                     <div className="input-group" id="show_hide_password">
    //                                         <input
    //                                             name="username"
    //                                             type="text"
    //                                             value={username}
    //                                             onChange={e => setUsername(e.target.value)}
    //                                         />


    //                                     </div>
    //                                 </div>
    //                                 <div className="form-group formrow">
    //                                     <label><b>Kata Sandi</b></label>
    //                                     <div className="input-group" id="show_hide_password" >
    //                                         <input
    //                                             name="epassword"
    //                                             type="password"
    //                                             value={epassword}
    //                                             onChange={e => setePassword(e.target.value)}
    //                                         />

    //                                     </div>
    //                                 </div>
    //                                 <div className="row button-space">
    //                                     <div class="col-sm-6"><input type="submit" className="btn btn-primary btnLogin" value="Login" /></div>
    //                                 </div>
    //                             </form>
    //                             {/* <div style={{ marginTop: "30px" }}>
    //                                      <p>Forgot password ? Klik <button style={{ border: "none", backgroundColor: "white", fontWeight: 900, textDecoration: "underline", color: "blue" }} onClick={() => handleOpen()}>Here</button></p>
    //                                  </div> */}
    //                             <div className="col-sm-2"><Link href={`https://facebook.com/`}>

    //                             </Link></div>
    //                         </div>
    //                         <div id="footer"> © 2021 PT Sinarmas Sekuritas | IT Development All Right Reserved</div>
    //                         <div>
    //                             <Modal
    //                                 open={open}
    //                                 onClose={handleClose}
    //                                 aria-labelledby="modal-modal-title"
    //                                 aria-describedby="modal-modal-description"
    //                             >
    //                                 <Box sx={style}>
    //                                     <Typography id="modal-modal-title" variant="h6" component="h2">
    //                                         Input Your Username and Email
    //                                     </Typography>
    //                                     <Box sx={{ pr: 7 }}>
    //                                         <TextField
    //                                             name="username"
    //                                             type="text"
    //                                             label="Username"
    //                                             variant="standard"
    //                                             fullWidth
    //                                             validate={[required()]}
    //                                             onChange={(e) => handleChange(e)}
    //                                         />
    //                                         <TextField
    //                                             name="email"
    //                                             type="text"
    //                                             label="Email"
    //                                             variant="standard"
    //                                             fullWidth
    //                                             validate={[required()]}
    //                                             onChange={(e) => handleChange(e)}
    //                                         />
    //                                         <div style={{ marginTop: "30px" }}>
    //                                             <Button variant="contained" type="button" onClick={() => forgotPassword()}>
    //                                                 Submit
    //                                             </Button>
    //                                         </div>
    //                                     </Box>
    //                                 </Box>
    //                             </Modal>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //         );
    //     }
    //     else if (window.location.origin == 'https://gmra.sinarmascakrawala.co.id') {
    //         return (

    //             <div className="row">
    //                 <div className="col-lg-6 bgbackground">

    //                 </div>
    //                 <div className="col-lg-6 column fullh" >
    //                     <div className="logincontainer" >
    //                         <img src="./img/logo2.png" height="50px" style={{ marginTop: 100 }} className="loginlogo" />

    //                         <div className="headTitle"><h2><span className="titleRed">GMRA</span>  Document</h2></div>
    //                         <div>
    //                             {/* <input type="radio" id="html" name="fav_language" value="mybo" onChange={e => setStrategy(e.target.value)} checked="checked" />
    //                             <label for="html">HO</label><br />
    //                             <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
    //                             <label for="css">Cabang</label><br></br>
    //                             <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
    //                         <label for="css">Buyer</label><br></br> */}
    //                         </div>

    //                         <div className="loginField row">

    //                             <form onSubmit={submit}>
    //                                 <div className="form-group formrow">
    //                                     <label ><b>Username</b></label>
    //                                     <div className="input-group" id="show_hide_password">
    //                                         <input
    //                                             name="username"
    //                                             type="text"
    //                                             value={username}
    //                                             onChange={e => setUsername(e.target.value)}
    //                                         />


    //                                     </div>
    //                                 </div>
    //                                 <div className="form-group formrow">
    //                                     <label><b>Kata Sandi</b></label>
    //                                     <div className="input-group" id="show_hide_password" >
    //                                         <input
    //                                             name="epassword"
    //                                             type="password"
    //                                             value={epassword}
    //                                             onChange={e => setePassword(e.target.value)}
    //                                         />

    //                                     </div>
    //                                 </div>
    //                                 <div className="row button-space">
    //                                     <div class="col-sm-6"><input type="submit" className="btn btn-primary btnLogin" value="Login" /></div>
    //                                 </div>
    //                             </form>
    //                             {/* <div style={{ marginTop: "30px" }}>
    //                                 <p>Forgot password ? Klik <button style={{ border: "none", backgroundColor: "white", fontWeight: 900, textDecoration: "underline", color: "blue" }} onClick={() => handleOpen()}>Here</button></p>
    //                             </div> */}
    //                             <div className="col-sm-2"><Link href={`https://facebook.com/`}>

    //                             </Link></div>
    //                         </div>
    //                         <div id="footer"> © 2021 PT Sinarmas Sekuritas | IT Development All Right Reserved</div>
    //                         <div>
    //                             <Modal
    //                                 open={open}
    //                                 onClose={handleClose}
    //                                 aria-labelledby="modal-modal-title"
    //                                 aria-describedby="modal-modal-description"
    //                             >
    //                                 <Box sx={style}>
    //                                     <Typography id="modal-modal-title" variant="h6" component="h2">
    //                                         Input Your Username and Email
    //                                     </Typography>
    //                                     <Box sx={{ pr: 7 }}>
    //                                         <TextField
    //                                             name="username"
    //                                             type="text"
    //                                             label="Username"
    //                                             variant="standard"
    //                                             fullWidth
    //                                             validate={[required()]}
    //                                             onChange={(e) => handleChange(e)}
    //                                         />
    //                                         <TextField
    //                                             name="email"
    //                                             type="text"
    //                                             label="Email"
    //                                             variant="standard"
    //                                             fullWidth
    //                                             validate={[required()]}
    //                                             onChange={(e) => handleChange(e)}
    //                                         />
    //                                         <div style={{ marginTop: "30px" }}>
    //                                             <Button variant="contained" type="button" onClick={() => forgotPassword()}>
    //                                                 Submit
    //                                             </Button>
    //                                         </div>
    //                                     </Box>
    //                                 </Box>
    //                             </Modal>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //         );
    //     }
    else {
        return (

            <div className="row">
                {/* <div className="col-lg-6 bgbackgroundshinta">

                </div>
                <div className="col-lg-6 column fullh" >
                    <div className="logincontainer" >
                        <img src="./img/logosmc.png" height="80px" style={{ marginTop: 100 }} className="loginlogo" />
                        <div className="headTitle"><h2><span className="titleBlue">GMRA</span>  Document</h2></div>
                        <div>
                            {/* <input type="radio" id="html" name="fav_language" value="mybo" onChange={e => setStrategy(e.target.value)} checked="checked" />
                            <label for="html">HO</label><br />
                            <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
                            <label for="css">Cabang</label><br></br>
                            <input type="radio" id="css" name="fav_language" value="local" onChange={e => setStrategy(e.target.value)} />
                        <label for="css">Buyer</label><br></br> */}
                {/* </div>

                        <div className="loginField row">

                            <form onSubmit={submit}>
                                <div className="form-group formrow">
                                    <label ><b>Username</b></label>
                                    <div className="input-group" id="show_hide_password">
                                        <input
                                            name="username"
                                            type="text"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                        />


                                    </div>
                                </div>
                                <div className="form-group formrow">
                                    <label><b>Kata Sandi</b></label>
                                    <div className="input-group" id="show_hide_password" >
                                        <input
                                            name="epassword"
                                            type="password"
                                            value={epassword}
                                            onChange={e => setePassword(e.target.value)}
                                        />

                                    </div>
                                </div>
                                <div className="row button-space">
                                    <div class="col-sm-6"><input type="submit" className="btn btn-primary btnLogin" value="Login" /></div>
                                </div>
                            </form>
                            <div style={{ marginTop: "30px" }}>
                                <p>Forgot password ? Klik <button style={{ border: "none", backgroundColor: "white", fontWeight: 900, textDecoration: "underline", color: "blue" }} onClick={() => handleOpen()}>Here</button></p>
                            </div>
                            <div className="col-sm-2"><Link href={`https://facebook.com/`}>

                    
                    </div>
                </div> */}
            </div>

        );
    }
};


export default Login;