import * as XLSX from 'xlsx/xlsx.mjs';

export default (csv, filename) => {
    console.log(csv);
    var ws = XLSX.utils.json_to_sheet(csv);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.writeFile(wb,filename+".xlsx");

};
