import React, { useState } from 'react';
import {
    List,
    Datagrid,
    CreateButton,
    TopToolbar,
    TextField,
    DateField,
    TextInput,
    FilterButton,
    useRecordContext,
    DateInput
} from 'react-admin';
import ImportButton from './ImportButton';
import ThousandField from '../field/ThousandField';
import { makeStyles } from '@material-ui/core/styles';

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property 
const useStyles = makeStyles({
    table: {

    },
    headerCell: {

    },
    text: {
        fontSize: 13,
        padding: 0
    },
    cellPad:
    {
        padding: 0
    }
});
const postFilters = [
    <DateInput label="Tanggal Efektif" source="tanggal_efektif" />,
    <DateInput label="Tanggal Jatuh Tempo" source="tanggal_jatuh_tempo" />,
    <TextInput label="Kode Nasabah" source="kode_nasabah" />,
    <TextInput label="No Surat Konfirmasi" source="no_surat_konfirmasi" />,
    <TextInput label="Sales" source="sales" />
];
const choice = [
    { id: 1, value: "1" },
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 4, value: "4" },
]


function ListActions(props) {

    function handleClick(event) {
        // Here, we invoke the callback with the new value
        props.onClick(event.target.value);
    }
    return (
        <TopToolbar>
            <FilterButton />
           
          

        </TopToolbar>)
}


export function TransactionListNC(props) {
    const [modalIsOpen, showModal] = useState(false);
    const classes = useStyles();

    const TipeTransaksi = (props) => {
        const record = useRecordContext(props);

        if (record.tipe_transaksi == 0) {
            return <span className={classes.text}>New Subcription</span>
        }
        else if (record.tipe_transaksi == 1) {
            return <span className={classes.text}>Redemption</span>
        }
        else if (record.tipe_transaksi == 2) {
            return <span className={classes.text}>Rollover Principal</span>
        }
        else if (record.tipe_transaksi == 3) {
            return <span className={classes.text}>Rollover Principal + Interest</span>
        }

    }
    const ShowDocument = (props) => {
        const record = useRecordContext(props);
        var link = "";
        if (record.tipe_transaksi == 0) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 1) {
            link = "/#/gmra-trans/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 2) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }
        else if (record.tipe_transaksi == 3) {
            link = "/#/gmra-maturity/" + record.id + "/show"
        }

        return (<a href={link} >{record.no_surat_konfirmasi}</a>)
    }

    return (
        <List {...props} filters={postFilters} sort={{ field: 'id', order: 'DESC' }} filter={{ active: 1 ,kode_buyer:localStorage.getItem('kode_buyer')}} actions={<ListActions onClick={() => showModal(true)} />} bulkActionButtons={false} >
            <Datagrid rowClick="edit">
                <TextField source="order_number" label="No." cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <ShowDocument source="no_surat_konfirmasi" label="No Surat Konfirmasi"></ShowDocument>
                {/* <TextField source="transaction_code" label="Transaction Code" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" /> */}
                <TextField source="kode_nasabah" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="cabang" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="sales" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TextField source="kode_saham" cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                {/* <NumberField source="nominal_beli" locales="fr-FR" options={{ style: 'currency', currency: 'IDR' }}/> */}
                <ThousandField source="nominal_beli" />
                <DateField source="tanggal_efektif" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <DateField source="tanggal_jatuh_tempo" options={{ year: 'numeric', month: 'short', day: 'numeric' }} cellClassName={classes.cellPad} headerClassName={classes.text} textAlign="center" />
                <TipeTransaksi label="Tipe Transaksi"></TipeTransaksi>
            </Datagrid>
        </List>
    )
}