import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
    Button,
    useRefresh
} from 'react-admin';
import Buttons from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as XLSX from "xlsx";
import { DataProviderContext } from 'react-admin';
import md5 from 'md5'

const ImportButton = () => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [kode_saham, setKode] = useState([]);
    const refresh = useRefresh();
    const [harga, setHarga] = useState([]);
    const [data, setData] = useState();
    const [bunga6, setBunga6] = useState([]);
    const [bunga12, setBunga12] = useState([]);
    const [nama_saham, setNama] = useState([]);
    const [rb, setRB] = useState(0);
    const [rc, setRC] = useState(0);
    const [bdisable, setDisable] = useState(true);
    const [jumlahDocument, setCountDocument] = useState(0);
    const [nominalRepo, setRepo] = useState();
    const [tenor, setTenor] = useState();
    const [kode_nasabah, setNasabah] = useState();
    const [tglEfektif, setTglEfektif] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [documentList, setDocumentList] = useState();
    const [holidays, setHoliday] = useState([]);
    const dataProvider = useContext(DataProviderContext);
    const [order, setOrder] = useState(0);
    const [salesd, setSales] = useState([]);

    useEffect(() => {
        setDisable(true);
        countDocument();

    }, tglEfektif)
    const handleClick = () => { countDocument(); setOpen(true) };
    const handleDialogClose = () => setOpen(false);
    const processDataExcel = () => processData();
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    Date.prototype.addMonth = function (month) {
        var date = new Date(this.valueOf());
        date.setMonth(date.getMonth() + month);
        return date;
    }

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    function checkGoodFund(clientID) {
        var net = 0;
        dataProvider.getList('gmra-client?cid=' + clientID, { limit: 1 }).then(({ data }) => {
            try {
                //  console.log(data)

                net = data[0].Cash + data[0].Sell - data[0].Buy;
                return net

            }
            catch (e) {
                console.log(e)
            }
        });
    }

    function processData() {

        var noorder = order;
        var rccode = rc;
        var rbcode = rb;
        var x = items.length;
        var lembar_saham = 0;
        var nominal_beli = 0;
        var nominal_bayar = 0;
        var nominal_repo = 0
        var tanggal_jatuh_tempo = 0;
        var tingkat_bunga = 0;
        var hari_bunga = 365;
        var harga_penjualan_kembali = 0;
        var nilai_investasi_bersih = 0;
        var from = '';
        var fee = 0.001;
        var to = '';
        var fromdate = '';
        var working_days = 0
        var holiday;
        var pricing_rate_g = 0;
        var pricing_rate_n = 0;
        var harga_buyback = 0;
        var net_interest = 0;
        var tenor = 0;
        var kode_nasabah = "";
        var holiday = [];
        var kode_buyer = "";
        var todate;
        var sales;
        var cabang;
        var tipe_nasabah = 0;
        var date;
        var noSurat = jumlahDocument;
        var transcode;
        var kodeerror = [];

        const totalData = items.length;
        let counter = 0;
        alert('Import request is in the process');
        var datavalid = 1;

        for (let check of items) {
            var tenor = check.Tenor
            var buyer = check.kode_buyer;
            
            var csales = check.Sales;
            console.log("Checking", csales)
            if (salesd.includes(csales)) {

            }
            else {
                datavalid = 0
                kodeerror.push(csales);
            }
            if (tenor == 12 || tenor == 6) {

            }
            else {
                datavalid = 0
                kodeerror.push(check.Kode_Nasabah);
            }

            if (buyer == 'ASIA016R' || buyer == 'PTSI007R' || buyer == 'PTNI004R' || buyer == 'PTSH001R' || buyer == 'PTTI009R') {

            }
            else {
                datavalid = 0
                kodeerror.push(check.Kode_Nasabah);
            }

            var netac = checkGoodFund(check.Kode_Nasabah)
            if (netac < check.Nominal_Repo) {
                datavalid = 0
                kodeerror.push(check.Kode_Nasabah);
            }


        }

        var errmsg = "Terdapat data error pada kode Nasabah berikut:";

        for (var er = 0; er < kodeerror.length; er++) {
            if (er != kodeerror.length - 1) {
                errmsg += kodeerror[er] + ","
            }
            else {
                errmsg += kodeerror[er] + "."
            }
        }

        errmsg += " Proses import dibatalkan.";

        if (datavalid == 1) {
            for (let data of items) {


                noSurat++;
                noorder++;

                nominal_repo = data.Nominal_Repo;
                sales = data.Sales;
                cabang = data.Cabang;
                kode_buyer = data.kode_buyer.toUpperCase();
                kode_nasabah = data.Kode_Nasabah.toUpperCase();

                try {
                    tipe_nasabah = data.Tipe_Nasabah;
                }
                catch (e) {

                }

                var found = 0;
                var hargaloop = 0
                var tb12 = 0;
                var tb6 = 0;

                hargaloop = Math.ceil(harga[data.kode_saham] / 1.6);
                tb12 = bunga12[data.kode_saham]
                tb6 = bunga6[data.kode_saham];

                lembar_saham = Math.floor(nominal_repo / hargaloop);
                nominal_beli = lembar_saham * hargaloop;

                var net_ac = nominal_beli + (nominal_beli * 0.001);
                nominal_bayar = nominal_beli + (nominal_beli * 0.1 / 100)

                tenor = data.Tenor;

                tingkat_bunga = (data.Tenor == 12) ? tb12 : tb6;
                harga_penjualan_kembali = (nominal_beli * (1 + (tingkat_bunga * hari_bunga / 365)))
                fromdate = tglEfektif

                from = new Date(tglEfektif)
                //  console.log("FROM", from)
                var tov;

                var d, m, y, ds;

                if (tenor == 12) {
                     tov = from.addMonth(12)
           
                    if (tov.getFullYear() % 4 == 0 && tov.getMonth() > 1) {
                        tov = tov.addDays(1);
                        console.log("KABISAT")
                    }
                    var libur = true
                    while (libur) {
                        libur = false;
                        if (tov.getDay() == 6 || tov.getDay() == 0) {
                            libur = true;
                        }
                        var libur2 = checkHoliday(tov);
                        if (libur == true || libur2 == true) {
                            libur = true;
                            tov = tov.addDays(1);
                        }
                    }

                    d = (tov.getDate() < 10 ? '0' : '') + tov.getDate();
                    m = ((tov.getMonth() + 1) < 10 ? '0' : '') + (tov.getMonth() + 1);
                    y = tov.getFullYear();
                }
                else {
                    //  tov = from.addDays(-1);
                    tov = from.addMonth(6);
                    console.log("AFTER ADD", tov)
                    var libur = true
                    while (libur) {
                        libur = false;
                        if (tov.getDay() == 6 || tov.getDay() == 0) {
                            libur = true;
                        }
                        var libur2 = checkHoliday(tov);
                        if (libur == true || libur2 == true) {
                            libur = true;
                            tov = tov.addDays(1);
                        }
                    }
                    console.log("AFTER ADD FINAL", tov)
                    var onholiday = false;

                    d = (tov.getDate() < 10 ? '0' : '') + tov.getDate();
                    if (tov.getMonth() == 11) {
                        m = '12'
                    }
                    else {
                        m = ((tov.getMonth() + 1) < 10 ? '0' : '') + ((tov.getMonth() + 1) % 12);
                    }

                    y = tov.getFullYear();

                }
                todate = new Date(y, m - 1, d);
                to = [y, m, d].join('-');
                var Difference_In_Time = todate.getTime() - from.getTime();

                // To calculate the no. of days between two dates
                var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
                //Calculate Working Days
                working_days = Difference_In_Days;
                holiday = holidays;


                harga_buyback = (((nominal_beli * (1 + tingkat_bunga * (working_days / 365))) + (0.001 * nominal_beli)) / (1 - 0.2 / 100)) / lembar_saham;
                harga_buyback = Math.ceil(harga_buyback)
                harga_penjualan_kembali = harga_buyback * lembar_saham;
                nominal_bayar = nominal_bayar.toFixed(0);
                nilai_investasi_bersih = harga_penjualan_kembali * (1 - (0.2 / 100));
                harga_penjualan_kembali = Math.round(harga_penjualan_kembali);
                if (nilai_investasi_bersih % 1 == 0.5) {
                    nilai_investasi_bersih += 0.1
                }
                nilai_investasi_bersih = Math.round(nilai_investasi_bersih);

                pricing_rate_g = Math.round(((((harga_penjualan_kembali / nominal_beli) - 1) * 365 / working_days)) * 10000) / 10000;
                pricing_rate_n = ((nilai_investasi_bersih / nominal_beli) - 1) * 365 / working_days;

                net_interest = (nominal_beli * tingkat_bunga * working_days / 365) + (nominal_bayar - nominal_beli);
                var today = new Date();
                var todaystring = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

                var from = new Date(fromdate);

                var yeardoc = from.getFullYear();
                var monthdoc = from.getMonth();
                var tomo = ("" + (from.getMonth() + 1)).padStart(2, 0)
                var monthromawi = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"]
                var settlement = from.addDays(1);

                while (checkHoliday(settlement)) {
                    settlement = settlement.addDays(1);
                    //   console.log("HOLIDAY MEET")
                }
                if (settlement.getDay() == 6) {

                    settlement = settlement.addDays(2)
                }
                else if (settlement.getDay() == 0) {
                    settlement = settlement.addDays(1)
                }
                while (checkHoliday(settlement)) {
                    settlement = settlement.addDays(1);
                    //   console.log("HOLIDAY MEET")
                }
                if (settlement.getDay() == 6) {

                    settlement = settlement.addDays(2)
                }
                else if (settlement.getDay() == 0) {
                    settlement = settlement.addDays(1)
                }

                const dayset = `${settlement.getDate()}`.padStart(2, '0');
                const monthset = `${settlement.getMonth() + 1}`.padStart(2, '0');
                const yearset = settlement.getFullYear();

                var tostext = yearset + "-" + monthset + "-" + dayset;
                var noDocument = noSurat.toString().padStart(4, '0') + "/SMS-GMRA/" + monthromawi[monthdoc] + "/" + yeardoc;
                var username = localStorage.getItem('username')
                var hash = md5(new Date().toString() + noDocument);

                var createdate = new Date().toString();
                var feeBuyback = Math.ceil(Math.abs(nilai_investasi_bersih - harga_penjualan_kembali));

                dataProvider.create('gmra-trans', {
                    data: {
                        "kode_nasabah": kode_nasabah,
                        "nominal_repo": nominal_repo,
                        "kode_saham": data.kode_saham,
                        "harga_beli": hargaloop,
                        "jumlah": lembar_saham,
                        "nominal_beli": nominal_beli,
                        "total_beli": nominal_bayar,
                        "fee_beli": fee * 100,
                        "net_ac": net_ac,
                        "tanggal_efektif": fromdate,
                        "tenor": tenor,
                        "penyelesaian_transaksi": "T+1",
                        "tanggal_settlement": tostext,
                        "tanggal_jatuh_tempo": to,
                        "tingkat_bunga": tingkat_bunga,
                        "jumlah_hari_bunga": working_days,
                        "harga_buyback": harga_buyback,
                        "harga_penjualan_kembali": harga_penjualan_kembali,
                        "fee_buyback": feeBuyback,
                        "nilai_investasi_bersih": nilai_investasi_bersih,
                        "pricing_rate_gross": pricing_rate_g,
                        "pricing_rate_net": pricing_rate_n,
                        "kode_buyer": kode_buyer,
                        "sales": sales,
                        "cabang": cabang,
                        "free_of_payment": "-",
                        "no_surat_konfirmasi": noDocument,
                        "security_hash": hash,
                        "tipe_nasabah": tipe_nasabah,
                        "tipe_transaksi": 0,
                        "tanggal_pembuatan": todaystring,
                        "CreatedBy": username,
                        "CreatedDate": todaystring,
                        "active": 1,
                        "order_number": noorder,
                        "nama_saham": nama_saham[data.kode_saham],
                        "KSEISingleID": ""
                    }
                }).then(response => {
                    console.log(response);
                    counter++;
                    if (counter === items.length - 1) {
                        alert(`Import request ${totalData} data is successfull`);
                    }
                }).catch(err => {
                    console.log(err);
                    data -= 1;
                    alert(JSON.stringify(err));

                    counter++;
                    if (counter === items.length - 1) {
                        alert(`Import request ${totalData} data is successfull`);
                    }
                })
            }
        }
        else {
            alert(errmsg);
        }

        setCountDocument(noSurat);
        setOpen(false);
        refresh();
    }

    function postData(jsondata) {

        JSON.stringify(jsondata)
        dataProvider.create('gmra-trans', {
            data: {
                jsondata
            }
        }).then(response => { setOpen(false); });
    }

    const countDocument = () => {
        var doc = [];
        var te = new Date(tglEfektif);
        var todaymonth = te.getMonth() + 1;
        var todayyear = te.getFullYear();
        dataProvider.getOne('gmra-trans-number?month=' + todaymonth + '&year=' + todayyear, { id: 1 }).then(response => {
         
            console.log("COUNT", response.data[0].nomor);
            setCountDocument(response.data[0].nomor);
            setOrder(response.data[0].total);
            setRB(response.data[0].rb);
            setRC(response.data[0].rc)

            var nomordok = parseInt(response.data[0].nomor) + 1;
            try {
                setDocumentList(response)

            } catch (e) {
                alert("Mohon isi Tanggal Efektif terlebih dahulu.")
                console.log(e);
            }
            setDisable(false);
     
        }).catch(error=>{
            console.log("ERROR",error)
         
        });

    }
    const getAgent = () => {
        dataProvider.getManyReference('sales-verif', { limit: 10 })
            .then((response) => {
                //console.log("RES", response);
                setSales(response);
            })
            .catch(error => {
                console.log(error)
            });
    }

    function checkHoliday(date) {

        const day = `${date.getDate()}`.padStart(2, '0');
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const year = date.getFullYear();
        //  console.log("HOLIDAY", holidays);
        var datum = year + "-" + (month) + "-" + day;
        //console.log("FORMATED DATE", datum);
        return holidays.includes(datum)
    }

    function formatDate(date) {
        var d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        //console.log([year, month, day].join('-'));
        return [year, month, day].join('-');
    }

    let workingDaysBetweenDates = (d0, d1, holiday) => {
        /* Two working days and an sunday (not working day) */
        var hd = holiday;
        // console.log("COUNTING");
        //  console.log(hd);
        var startDate = parseDate(d0);
        var endDate = parseDate(d1);

        // Validate input
        if (endDate <= startDate) {
            return 0;
        }

        // Calculate days between dates
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        startDate.setHours(0, 0, 0, 1);  // Start just after midnight
        endDate.setHours(23, 59, 59, 999);  // End just before midnight
        var diff = endDate - startDate;  // Milliseconds between datetime objects    
        var days = Math.ceil(diff / millisecondsPerDay);

        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days -= weeks * 2;

        // Handle special cases
        var startDay = startDate.getDay();
        var endDay = endDate.getDay();

        // Remove weekend not previously removed.   
        if (startDay - endDay > 1) {
            days -= 2;
        }
        // Remove start day if span starts on Sunday but ends before Saturday
        if (startDay == 0 && endDay != 6) {
            days--;
        }
        // Remove end day if span ends on Saturday but starts after Sunday
        if (endDay == 6 && startDay != 0) {
            days--;
        }
        /* Here is the code */
        hd.forEach(day => {
            if ((day >= d0) && (day <= d1)) {
                /* If it is not saturday (6) or sunday (0), substract it */
                if ((parseDate(day).getDay() % 6) != 0) {
                    days--;
                }
            }
        });
        return days;
    }

    function parseDate(input) {
        // Transform date from text to date
        var parts = input.match(/(\d+)/g);
        // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }




    useEffect(() => {
        getAgent();
        countDocument();
        var date;
        var holiday;
        var from = new Date()

        var d = (from.getDate() < 10 ? '0' : '') + from.getDate();

        var m = ((from.getMonth() + 1) < 10 ? '0' : '') + (from.getMonth() + 1);

        var y = from.getFullYear() + 2;
        var todate = new Date(y, m, d);
        var to = [y, m, d].join('-');
        dataProvider.getList('holiday?from=' + formatDate(from) + '&to=' + to + '', { limit: 1000 })
            .then(({ data }) => {
                date = [];
                holiday = data[0].Date;
                for (var x = 0; x < holiday.length; x++) {
                    date.push(holiday[x].Date.split("T")[0]);
                }

                setHoliday(date);
            });


    }, []);

    useEffect(() => {

        dataProvider.getManyReference('gmra-harga?active=1', {
            limit: 1
        })
            .then(({ data }) => {
                console.log("HARGA", data);
                setData(data);
                var arrHarga = [];
                var arrBunga6 = [];
                var arrBunga12 = [];
                var arrNamaSaham = [];

                for (var y = 0; y < data.length; y++) {
                    arrHarga[data[y].kode_saham] = data[y].harga_beli
                    arrBunga6[data[y].kode_saham] = data[y].tingkat_bunga6
                    arrBunga12[data[y].kode_saham] = data[y].tingkat_bunga12
                    arrNamaSaham[data[y].kode_saham] = data[y].nama_saham
                }
                // console.log(data[data.length - 1].harga_beli)
                setNama(arrNamaSaham);
                setHarga(arrHarga);
                setBunga6(arrBunga6);
                setBunga12(arrBunga12);

                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [items, kode_saham]);

    const handleConfirm = () => {

        setOpen(false);
    };


    const readExcel = (file) => {

        const promise = new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;

                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws, { raw: false });
                    // console.log(data);
                    resolve(data);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };
            }
            catch (e) {
                console.log(e);
            }
        });

        promise.then((d) => {
            console.log(d);
            setItems(d);
        });

    };

    return (
        <Fragment>
            <Button label="Import" onClick={handleClick} />
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Do you want to import excel data?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <label>Tanggal Efektif &nbsp;</label>
                        <input
                            type="date"

                            onChange={(value) => {
                                console.log(value.target.value);
                                setTglEfektif(value.target.value);
                            }}
                        /><br />
                        {/* <label>Kode Saham &nbsp;</label>
                        <input type="text" onBlur={(value) => {
                            console.log(value.target.value);
                            setKode(value.target.value);
                        }}></input><br /> */}
                        <input
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file.name.split('.')[1] == 'xlsx' || file.name.split('.')[1] == 'xls') { readExcel(file); } else {
                                    alert("File Format Error")
                                    e.target.value = null;
                                }

                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Buttons onClick={handleDialogClose} color="primary">
                        Cancel
                    </Buttons>
                    <Buttons onClick={processDataExcel} di color="primary" disabled={bdisable} autoFocus>
                        Confirm
                    </Buttons>
                </DialogActions>
            </Dialog>

        </Fragment>
    );
}

export default ImportButton;