import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';


const progressBar = (flag) => {
    const progress = ((100 / 3) * (flag + 1)).toFixed(2);
    return (
        <div className="w3-light-grey">
            <div className="w3-container w3-green w3-center" style={{ width: `${progress}%` }}>{progress}%</div>
        </div>
    )
};
const MergeDocField = (props) => {
    const { source } = props;
    console.log(source);
    const record = useRecordContext(props);
    console.log(record);
    const myArr = record[source];
    if (myArr != '') {
        return (
            <div>
                <a href={myArr} style={{ fontSize: 12 }}>
                    {record.flag == 5 ? 'Dokumen tidak ditemukan' : !myArr ? progressBar(record.flag) : 'Download'}
                </a>
            </div>
        )
    }
    return (<div>Processing...</div>)
}

MergeDocField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.string.isRequired,
};

export default MergeDocField;