import downloadXLSX from './downloadXLSX';
import DownloadXLSXNoHeader from './DownloadXLSXNoHeader';
import jsonExport from 'jsonexport/dist';
import {downloadCSV} from "react-admin"
import { normalizedMonth, status, dateToString } from "../utils/helper";
import { format } from 'path';

const DownloadExcel = async (data, state, display) => {
    console.log("DATA",data)
    let fields = [];
    var formattransaksi = 0;
    var list_nasabah = 0;
    var matchreport = 0;
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    switch (display) {
        case status.MONITORING_DISPLAY.LIST_NASABAH:
            fields = [
                'No', 'Kode Principal', 'Kode Nasabah', 'Nama Nasabah', 'SRE Number', 'Contract Number', 'Tenor', 'Tanggal Efektif', 'Tanggal Jatuh Tempo', 'Lembar Saham', 'Nominal Beli', 'Rasio Margin', 'Opsi', 'Lembar TopUP/Deduct', 'Total Saham', 'Market Value','Market Value/Total Saham'
            ];
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, ];
            // }
            break;
        case status.MONITORING_DISPLAY.FORMAT_TRANSAKSI:
            fields = [];
            // if (state === status.REPORT_STATUS.MATURED) {
            //     fields = [...fields, ];
            // }
            break;
        case status.MONITORING_DISPLAY.MATCH_REPORT:
            fields = [
                'Trade ID', 'Client ID', 'Market', 'B/S', 'Stock', 'Price', 'Lot', 'Volume', 'Gross Amount','No Surat Konfirmasi'
            ];
            break;
        default:
            break;
    }

    // console.log('data: ', data);
    const dataForExport = data.map((item, i) => {
        let d = {};

        switch (display) {
            case status.MONITORING_DISPLAY.LIST_NASABAH:
                formattransaksi = 0;
                list_nasabah = 1;
                matchreport = 0;
                d['No'] = item.id;
                d['Kode Principal'] = item.kode_buyer;
                d['Kode Nasabah'] = item.ClientID;
                d['Nama Nasabah'] = item.ClientName;
                d['SRE Number'] = item.KSEISubAccountNo || '-';
                d['Contract Number'] = item.no_surat_konfirmasi;
                d['Tenor'] = item.tenor;
                d['Tanggal Efektif'] = new Date(item.tanggal_efektif);
                d['Tanggal Jatuh Tempo'] = new Date(item.tanggal_jatuh_tempo);
              //  d['Nominal Beli'] = item.nominal_beli *1;
                d['Lembar Saham'] = item.jumlah*1;
                d['Nominal Beli'] = item.nominal_beli *1;
                d['Rasio Margin'] = (item.rasio_marjin*100).toFixed(2)+" %";
                d['Opsi'] = item.Action;
                d['Lembar TopUP/Deduct'] = item.jumlah_saham_disesuaikan;
                d['Total Saham'] = item.total_saham;
                d['Market Value'] = item.market_value;
                d['Market Value/Total Saham']=item.market_value/item.total_saham;
                d['Margin After']=Math.round((item.market_value/item.nominal_beli)*10)*10 +"%";

                return d;
            case status.MONITORING_DISPLAY.FORMAT_TRANSAKSI:
                formattransaksi = 1;
                list_nasabah = 0;
                matchreport = 0;
                d[''] = `${item.BS}` + ',' + item.kode_saham + ',' + item.harga_beli + ',' + Math.abs(item.jumlah_saham_disesuaikan) + ',' + item.ClientID + ',' + item.kode_buyer


                return d;
            case status.MONITORING_DISPLAY.MATCH_REPORT:
                formattransaksi = 0;
                matchreport = 1;
                list_nasabah = 0;
                d['Trade ID'] = item.tradeId;
                d['Client ID'] = item.clientId;
                d['Market'] = item.market;
                d['B/S'] = item.bs;
                d['Stock'] = item.stock;
                d['Price'] = item.price;
                d['Lot'] = item.lot;
                d['Volume'] = item.volume;
                d['Gross Amount'] = item.gross_amount;
                d['No Surat Konfirmasi'] = item.no_surat_konfirmasi
                d['Monitoring Date']=new Date(item.monitoring_date)

                return d;
            default:
                break;
        }
    });

    await jsonExport(dataForExport, {
        headers: fields
    }, (err, csv) => {
        //var pex = [];
        console.log(csv);
        var fileName = '';
        if (formattransaksi == 1) {
            fileName = "Format Transaksi "
            var AMB=[];
            var SMC=[];
            var NT=[]
            var SMCcsv="";
            var AMBcsv="";
            var NTcsv="";
            for (var i = 0; i < dataForExport.length; i++) {

                console.log(dataForExport[i][""]);
                var act = dataForExport[i][""].split(",")[0];
                var opp = 0;
                if (act == 1) {
                    opp = 0
                }
                else {
                    opp = 1
                }
                var buyer = dataForExport[i][""].split(",")[5];
                if (act==1 || act==2){

                // pex.push({"":"" + opp + "," + dataForExport[i][""].split(",")[1] + "," + dataForExport[i][""].split(",")[2] + "," + dataForExport[i][""].split(",")[3] + ","+buyer}) ;
                if (buyer == "PTSI007R") {
                    SMC.push({"":"" + dataForExport[i][""].split(",")[0] + "," + dataForExport[i][""].split(",")[1] + "," + dataForExport[i][""].split(",")[2] + "," + dataForExport[i][""].split(",")[3] +"," + dataForExport[i][""].split(",")[4] });
                SMCcsv+="" + dataForExport[i][""].split(",")[0] + "," + dataForExport[i][""].split(",")[1] + "," + dataForExport[i][""].split(",")[2] + "," + dataForExport[i][""].split(",")[3] +"," + dataForExport[i][""].split(",")[4]+"\n";
                }
                else if (buyer=="ASIA016R"){
                    AMB.push({"":"" + dataForExport[i][""].split(",")[0] + "," + dataForExport[i][""].split(",")[1] + "," + dataForExport[i][""].split(",")[2] + "," + dataForExport[i][""].split(",")[3]+"," + dataForExport[i][""].split(",")[4] });
                AMBcsv+="" + dataForExport[i][""].split(",")[0] + "," + dataForExport[i][""].split(",")[1] + "," + dataForExport[i][""].split(",")[2] + "," + dataForExport[i][""].split(",")[3]+"," + dataForExport[i][""].split(",")[4]+"\n";
                } else if (buyer=="PTNI004R"){
                    NT.push({"":"" + dataForExport[i][""].split(",")[0] + "," + dataForExport[i][""].split(",")[1] + "," + dataForExport[i][""].split(",")[2] + "," + dataForExport[i][""].split(",")[3]+"," + dataForExport[i][""].split(",")[4] });
                NTcsv+="" + dataForExport[i][""].split(",")[0] + "," + dataForExport[i][""].split(",")[1] + "," + dataForExport[i][""].split(",")[2] + "," + dataForExport[i][""].split(",")[3]+"," + dataForExport[i][""].split(",")[4]+"\n";
                
                }
            }
            }
           // DownloadXLSXNoHeader(SMC, fileName + "SMC");
            //DownloadXLSXNoHeader(AMB, fileName + "AMB");
           
           downloadCSV(SMCcsv,fileName+"SMC");
           downloadCSV(AMBcsv,fileName+"AMB");
           downloadCSV(NTcsv,fileName+"NT")
            // var inc = 1;
            //  for(var i=0; i<pex.length;i++)
            //  {
            //  dataForExport.splice(i+inc,0,pex[i]);
            //   inc++;
            //  }

        }
        if(list_nasabah==1){
            downloadXLSX(dataForExport, 'List Nasabah');
        }

        if(matchreport==1){
            downloadXLSX(dataForExport, 'Match Report');
        }

       // // download as 'posts.csv` file
    });
};

export { DownloadExcel };