import React from 'react';

import {
    List,
    Datagrid,
    DateInput,
    TopToolbar,
    DateField,
    FilterButton, useRecordContext
} from 'react-admin';

import MergeDocField from '../field/MergeDocField';
import MMergeButton from './MMergeButton';
// const Tanggal = (props) => {
//     const { source } = props;
//     const record = useRecordContext(props);
//     console.log(record);
//     const date = record[source].split("T")[0];
//     const y = date.split("-")[0];
//     const d = date.split("-")[2];
//     const m = date.split("-")[1];
//     const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
//     const dates = [d, months[m - 1], y].join(" ");

//     return dates;

// }
const TipeDoc = (props) => {
    const record = useRecordContext(props);
    console.log(record)
    if (record.document_type === 1) {
        return <span   >GMRA</span>
    }
    else if (record.document_type === 2) {
        return <span   >Instruksi Blokir Saham</span>
    }
    else if (record.document_type === 3) {
        return <span   >Surat Konfirmasi Saham</span>
    }
    else if (record.document_type === 4) {
        return <span   >Surat Konfirmasi Saham AMB</span>
    }
    else if (record.document_type === 5) {
        return <span   >Surat Instruksi</span>
    }
    else if (record.document_type === 6) {
        return <span   >Sell Intruction AMB</span>
    }
    else if (record.document_type === 7) {
        return <span   >Surat Pencabutan Blokir</span>
    }
    else if (record.document_type === 8) {
        return <span   >Surat Pernyataan</span>
    }
    else if (record.document_type === 9) {
        return <span   >All</span>
    }
    else if (record.document_type === 0) {
        return <span   >Undefined</span>
    }


}
const postFilters = [
    <DateInput label="Tanggal Dokumen" source="tanggal_pembuatan" />,
];

function ListActions(props) {

    return (
        <TopToolbar>
            <FilterButton />
            <MMergeButton />
        </TopToolbar>)
}

export function MMergeList(props) {
    return (
        <List {...props} title="Merge" sort={{ field: 'tanggal_efektif', order: 'DESC' }} filter={{ officeNID: localStorage.getItem('oid') }} filters={postFilters} actions={<ListActions />}>
            <Datagrid>
                <DateField source="tanggal_efektif" label="Tanggal Efektif" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
                <MergeDocField source="document" label="Document Link"></MergeDocField>
                <TipeDoc source="document_type" label="Tipe Dokumen"></TipeDoc>
            </Datagrid>
        </List>
    )
}