import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
const PositiveField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    
    const myArr = record[source];
    const thousandFormat=numberWithCommas(Math.abs(myArr))
    return (<div>{thousandFormat}</div>)
}

PositiveField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.element,
    source: PropTypes.number
};

export default PositiveField;